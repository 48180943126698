import { DocumentTextIcon, EyeIcon, PlayIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

type Props = {
  image?: string;
  type?: string;
  tag: string;
  viewCount: number;
  likeCount: number;
  key?: string;
  tab?: string;
  authorName?: string;
  authorAvatar?: string;
};

const HashtagItem = ({
  image = "/images/default_header_profile.png",
  authorAvatar = "/images/placeholder/person.png",
  authorName = "",
  ...props
}: Props) => (
  <NavLink
    to={`/tags/${props.tag}/${props.type === "video" ? "watch" : "feed"}`}
    className="group relative aspect-[4/5] lg:rounded-lg"
  >
    <img src={image} className="mx-auto size-full object-cover lg:rounded-lg" />
    {props.type === "video" ? (
      <PlayIcon className="absolute right-1 top-1 size-5" />
    ) : (
      <DocumentTextIcon className="absolute right-1 top-1 size-5" />
    )}
    <div className="absolute top-0 size-full group-hover:visible group-hover:bg-black/30 max-lg:bg-black/30 lg:invisible lg:rounded-lg">
      <div className="absolute top-1/2 w-full -translate-y-1/2 px-4">
        <div className="flex flex-row items-center justify-center gap-1 font-semibold text-white">
          <HeartIcon className="size-5" />
          <div>{props.likeCount}</div>
        </div>
        <div className="flex flex-row items-center justify-center gap-1 text-white">
          <EyeIcon className="flip size-5 -scale-x-100 text-white" />
          <div>{props.viewCount}</div>
        </div>
        <div className="flex flex-row items-center justify-center gap-1 text-white">
          <div className="truncate text-center">#{props.tag}</div>
        </div>
        <div className="flex flex-row items-center justify-center gap-1 text-white">
          <img src={authorAvatar} className="size-4 rounded-full" />
          <div className="truncate text-center">{authorName}</div>
        </div>
      </div>
    </div>
  </NavLink>
);

export default HashtagItem;

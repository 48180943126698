import { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  isActive?: boolean;
  stayVisible?: boolean;
  children?: ReactNode;
  value?: string;
  onClick?: () => void;
};

const Tab = ({ isActive, children, value, stayVisible = false, onClick }: Props) => {
  return (
    <div css={[!stayVisible && !isActive && tw`hidden`]} onClick={() => onClick && onClick()}>
      {children}
    </div>
  );
};

export default Tab;

import { TrashIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Alert from "@src/components/elements/Alert";
import { removeItem, updateCount } from "@src/state/cart/cartSlice";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import { Cart } from "@src/utils/cart";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

type Props = {
  item: Cart;
  key: number;
};

const SidebarCartItem = ({ item }: Props) => {
  const dispatch = useDispatch();
  const popupModal = usePopupModal();
  const queryClient = useQueryClient();
  const { cartContent } = useAppSelector(state => state.cart);
  const [count, setCount] = useState(item.quantity);
  const { t } = useTranslation();

  const CartErrorPopup = (message: string) => {
    popupModal.open(
      <Alert
        buttons={Alert.ButtonVariants.OK}
        onClose={popupModal.close}
        message={message}
        title="Cart Error"
      />,
    );
  };

  const increaseCount = useMutation([`increaseCount-${item.product_id}`], {
    mutationFn: async () => {
      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            quantity: cart.quantity + 1,
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(
        updateCount({
          product_id: item.product_id,
          quantity: item.quantity + 1,
        }),
      );
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422) CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500) CartErrorPopup("Internal Server Error.");
    },
  });

  const decreaseCount = useMutation([`decreaseCount-${item.product_id}`], {
    mutationFn: async () => {
      if (item.quantity === 1) {
        CartErrorPopup("The quantity cannot be less than zero.");
        removeCart.mutate();
        throw new Error();
      }

      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            quantity: cart.quantity - 1,
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(
        updateCount({
          product_id: item.product_id,
          quantity: item.quantity - 1,
        }),
      );
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422) CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500) CartErrorPopup("Internal Server Error.");
    },
  });

  const removeCart = useMutation([`removeCart-${item.product_id}`], {
    mutationFn: async () => {
      await api.delete(`/api/v1/carts/${item.product_id}`);
    },
    onSuccess: () => {
      dispatch(removeItem(item.product_id));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 500) CartErrorPopup("Internal Server Error.");
    },
  });

  const setQuantity = useMutation([`setCount-${item.product_id}`], {
    mutationFn: async (newQuantity: number) => {
      if (newQuantity < 0) {
        CartErrorPopup("The quantity cannot be less than zero.");
        throw new Error("The quantity cannot be less than zero.");
      }

      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            count,
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(updateCount({ product_id: item.product_id, quantity: item.quantity }));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422) CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500) CartErrorPopup("Internal Server Error.");
    },
  });

  function handleItemQuantity(event: React.ChangeEvent<HTMLSelectElement>) {
    const newQuantity = parseInt(event.target.value, 10);
    setCount(newQuantity);
  }

  function generateNumberList(count = 0) {
    const list: Array<{
      name: string;
      value: string;
    }> = [];
    for (let i = 1; i < count; i++) {
      const count = {
        name: String(i),
        value: String(i),
      };
      list.push(count);
    }
    list.push({
      name: String(count) + "+",
      value: String(count),
    });
    return list;
  }

  return (
    <>
      <div className="border-b border-gray-300 p-2">
        <NavLink to={"/marketplace/" + item.product_id} className="pb-1 hover:bg-gray-100">
          <img
            className="aspect-square w-full rounded object-cover outline-none dark:border-transparent dark:bg-gray-300"
            src="/placeholder.png"
          />
          <div className="text-center text-sm font-semibold">
            {(parseFloat(`${item.price_gross}`) / 100).toFixed(2)}€
          </div>
        </NavLink>
        <div className="flex flex-row items-center justify-between">
          <div className="rounded-xl py-0 text-left text-xs">
            <select
              className="h-[20px] w-[60px] rounded-xl border border-gray-300 px-4 py-0 text-left text-xs"
              value={count}
              onChange={handleItemQuantity}
            >
              {generateNumberList(10).map(count => {
                return (
                  <option key={count.name} value={count.value}>
                    {count.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex h-[20px] w-[30px] cursor-pointer items-center justify-center rounded-xl border border-gray-300 p-1 py-0 text-xs">
            <TrashIcon className="size-4" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarCartItem;

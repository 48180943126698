import api from "@src/api/api";
import LinkPreview from "@src/components/elements/LinkPreview";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";
import { useQuery } from "@tanstack/react-query";

type Props = {
  link: string;
  onChange: (linkPreviewId?: string) => void;
  onDelete?: () => void;
  small?: boolean;
};

const LegacyLinkPreview = ({ link, small, onChange, onDelete }: Props) => {
  const linkPreview = useQuery<LinkPreviewType, Error>(
    [`link-preview-${link}`],
    async () => {
      const { data } = await api.get(`/api/v1/link-preview?url=${link}`);
      return data.data;
    },
    {
      onSuccess: data => onChange(data.id),
    },
  );

  if (!linkPreview.isSuccess)
    return (
      <div className="h-[374px] w-full animate-pulse rounded-2xl border border-gray-400 bg-gray-300"></div>
    );

  return <LinkPreview link={linkPreview.data} small={small} onDelete={onDelete} />;
};

export default LegacyLinkPreview;

import api from "@src/api/api";
import UserWidgetPicture from "@src/components/elements/UserWidgetPicture";
import Button from "@src/components/elements/input/Button";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { User } from "@src/utils/user";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

type Props = { user: User; mode?: string; key?: string };

const ContactBirthdayItem = ({ user }: Props) => {
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const { user: userData } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/chats/create/${user.id}`);
      return res.data;
    },
    onSuccess: data => {
      navigate("/messenger", { state: { chatId: data.data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex w-full flex-col gap-2 rounded-lg border border-gray-300 pb-2 text-sm">
      <div className="relative">
        <UserWidgetPicture user={user} showName={true} follow={false} verified={true} />
        <div className="absolute right-0 top-0 pt-2">
          <ContactContextMenu userId={user.id} type={user.type} />
        </div>
      </div>

      <div
        className="flex flex-row justify-between px-2 text-xs text-gray-600"
        style={{ marginTop: "-25px" }}
      >
        <NavLink to={"/profile/" + user.id + "/likes"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.contacts.tabs.likes")}</div>
            <div>{user.like_count}</div>
          </div>
        </NavLink>
        <NavLink to={"/profile/" + user.id + "/follows"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.contacts.tabs.followers")}</div>
            <div>{user.follower_count}</div>
          </div>
        </NavLink>
        <NavLink to={"/profile/" + user.id + "/following"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.contacts.tabs.following")}</div>
            <div>{user.following_count}</div>
          </div>
        </NavLink>
      </div>
      <div className="px-2">
        <Button
          onClick={() => (userData ? createChat.mutate() : loginModal.open())}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <div className="inline-flex items-center gap-1">
            {t("components.shared.congratulate")}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ContactBirthdayItem;

import { TFunction } from "i18next";

export type FormElement = {
  name?: string;
  value?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  items?: FormElement[];
  link?: string;
  itemStyle?: string;
  formats?: string;
  multiple?: boolean;
};

type AssignmentCreateConfigType = {
  [category: string]: Array<FormElement | FormElement[]>;
};

const AssignmentCreateConfig = (t: TFunction): AssignmentCreateConfigType => {
  const categories = {
    name: "category",
    label: t("main.sell.created.categories"),
    type: "selectTab",
    items: [
      { name: t("components.assignments.category.service"), value: "service" },
      { name: t("components.assignments.category.vehicle"), value: "vehicle" },
      { name: t("components.assignments.category.household"), value: "household" },
      { name: t("components.assignments.category.property"), value: "property" },
      { name: t("components.assignments.category.cloths"), value: "cloths" },
      { name: t("components.assignments.category.groceries"), value: "groceries" },
      { name: t("components.assignments.category.technology"), value: "technology" },
      { name: t("components.assignments.category.animals"), value: "animals" },
      { name: t("components.assignments.category.collect"), value: "collect" },
    ],
  };

  const type = {
    name: "type",
    label: "",
    type: "radio",
    items: [
      { name: t("components.assignments.type.offer"), value: "0" },
      { name: t("components.assignments.type.search"), value: "1" },
    ],
  };

  const workerCount = {
    name: "workerCount",
    label: t("components.assignments.workerCount"),
    type: "input",
  };

  const pricetype = {
    name: "pricetype",
    label: "Preistyp",
    type: "select",
    items: [
      {
        name: t("components.assignments.pricetype.pricetype"),
        value: "undefined",
      },
      { name: t("components.assignments.pricetype.hourly"), value: "hourly" },
      { name: t("components.assignments.pricetype.daily"), value: "daily" },
      { name: t("components.assignments.pricetype.fixed"), value: "fixed" },
      { name: t("components.assignments.pricetype.base"), value: "base" },
      { name: t("components.assignments.pricetype.gift"), value: "gift" },
      { name: t("components.assignments.pricetype.exchange"), value: "swap" },
    ],
  };

  const compensation = {
    name: "compensation",
    label: t("components.assignments.compensation"),
    type: "input",
  };

  const costsSupplements = {
    name: "costsSupplements",
    label: t("components.assignments.costsSupplements.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.costsSupplements.overtime"),
        value: "overtime",
      },
      {
        name: t("components.assignments.costsSupplements.saturday"),
        value: "saturday",
      },
      {
        name: t("components.assignments.costsSupplements.sunday"),
        value: "sunday",
      },
      {
        name: t("components.assignments.costsSupplements.holiday"),
        value: "holiday",
      },
      {
        name: t("components.assignments.costsSupplements.night"),
        value: "night",
      },
      {
        name: t("components.assignments.costsSupplements.51hours"),
        value: "51hours",
      },
    ],
  };

  const vat = {
    name: "vat",
    label: t("main.sell.created.vat"),
    type: "radio",
    items: [
      { name: "19%", value: 19 },
      { name: "7%", value: 7 },
      { name: t("components.assignments.vat.undefined"), value: 0 },
    ],
  };

  const shippingservices = {
    name: "shippingservices",
    label: t("main.sell.created.shippingServiceProvider"),
    type: "select",
    items: [
      { name: t("components.assignments.shippingservices.dhl"), value: "DLH" },
      {
        name: t("components.assignments.shippingservices.dhlExpress"),
        value: "DLH Express",
      },
      { name: t("components.assignments.shippingservices.dpd"), value: "DPD" },
      { name: t("components.assignments.shippingservices.ups"), value: "UPS" },
      { name: t("components.assignments.shippingservices.gls"), value: "GLS" },
      {
        name: t("components.assignments.shippingservices.fedex"),
        value: "Fedex",
      },
      {
        name: t("components.assignments.shippingservices.dp"),
        value: "Deutsche Post",
      },
    ],
  };

  const title = {
    name: "title",
    label: "Titel",
    type: "input",
  };

  const titleArea = {
    name: "productTitle",
    label: t("main.sell.created.productTitle"),
    type: "textarea",
  };

  const sector = {
    name: "sector",
    label: t("components.assignments.sector"),
    type: "input",
  };

  const description = {
    name: "description",
    label: t("components.assignments.description"),
    type: "textarea",
  };

  const publish = {
    name: "publish",
    label: t("components.assignments.publish.label"),
    type: "select",
    items: [
      { name: t("components.assignments.publish.notPublic"), value: "0" },
      { name: t("components.assignments.publish.public"), value: "1" },
    ],
  };

  const empty = {
    name: "",
    label: "",
    type: "empty",
  };

  const keywords = {
    name: "keywords",
    label: t("main.sell.created.tagsKeywords"),
    type: "tags",
  };

  const keywordsArea = {
    name: "keywords",
    label: t("main.sell.created.tagsKeywords"),
    type: "textarea",
  };

  const quantity = {
    name: "quantity",
    label: t("main.sell.created.quantityAvailable"),
    type: "input",
  };

  const nettoprice = {
    name: "nettoprice",
    label: t("main.sell.created.netPrice"),
    type: "input",
  };

  const bruttoprice = {
    name: "bruttoprice",
    label: t("main.sell.created.grossPrice"),
    type: "input",
  };

  const price = {
    name: "price",
    label: t("main.sell.created.price"),
    type: "input",
  };

  const offerprice = {
    name: "offerprice",
    label: t("main.sell.created.priceOffer"),
    type: "input",
  };

  const barcode = {
    name: "barcode",
    label: t("main.sell.created.barcode"),
    type: "input",
  };

  const hscode = {
    name: "hscode",
    label: t("main.sell.created.hsCode"),
    type: "input",
  };

  const reference = {
    name: "reference",
    label: t("main.sell.created.referenceNumber"),
    type: "input",
  };

  const trackingid = {
    name: "trackingid",
    label: t("main.sell.created.trackingNumber"),
    type: "input",
  };

  const trackingurl = {
    name: "trackingurl",
    label: t("main.sell.created.trackingUrl"),
    type: "input",
  };

  const deliverydate = {
    name: "deliverydate",
    label: t("main.sell.created.expectedDelivery"),
    type: "date",
  };

  const agb = {
    name: "agb",
    label: t("main.sell.created.termsCondition"),
    type: "checkbox",
  };

  const hsntsn = {
    name: "hsntsn",
    label: "HSN / TSN",
    type: "input",
  };

  const cartypes = {
    name: "cartypes",
    label: "",
    type: "select",
    items: [
      { name: t("components.assignments.cartypes.trailer"), value: "Anhänger" },
      { name: t("components.assignments.cartypes.boat"), value: "Boote" },
      { name: t("components.assignments.cartypes.bicycle"), value: "Fahrräder" },
      { name: t("components.assignments.cartypes.car"), value: "Kraftfahrzeuge" },
      { name: t("components.assignments.cartypes.lkw"), value: "LKW" },
      { name: t("components.assignments.cartypes.scooter"), value: "Moped" },
      {
        name: t("components.assignments.cartypes.motorcycle"),
        value: "Motorrad",
      },
      { name: t("components.assignments.cartypes.oldtimer"), value: "Oldtimer" },
      { name: t("components.assignments.cartypes.camper"), value: "Wohnmobil" },
    ],
  };

  const condition = {
    name: "condition",
    type: "radio",
    items: [
      { name: t("components.assignments.condition.new"), value: "new" },
      { name: t("components.assignments.condition.used"), value: "used" },
      {
        name: t("components.assignments.condition.refurbished"),
        value: "refurbished",
      },
      { name: t("components.assignments.condition.defect"), value: "defect" },
    ],
  };

  const contractStart = {
    name: "contractStart",
    label: t("components.createJob.from"),
    type: "date",
  };

  const contractEnd = {
    name: "contractEnd",
    label: t("components.createJob.till"),
    type: "date",
  };

  const onRecall = {
    name: "contractRecall",
    label: t("components.assignments.revocation"),
    type: "checkbox",
  };

  const country = {
    name: "country",
    label: t("main.navLinks.jobsPublished.country"),
    type: "input",
  };

  const zipCode = {
    name: "zipCode",
    label: t("components.createJob.zip"),
    type: "input",
  };

  const city = {
    name: "city",
    label: t("main.profileViewTabs.editProfile.form.city"),
    type: "input",
  };

  const effectiveRadius = {
    name: "effectiveRadius",
    label: t("components.assignments.operating"),
    type: "input",
  };

  const tüv = {
    name: "tüv",
    label: "TÜV",
    type: "date",
  };

  const lastinspection = {
    name: "lastinspection",
    label: t("components.assignments.lastInspection"),
    type: "date",
  };

  const mileage = {
    name: "mileage",
    label: t("components.assignments.mileage"),
    type: "input",
  };

  const builddate = {
    name: "builddate",
    label: t("components.assignments.buildyear"),
    type: "date",
  };

  const firstapproval = {
    name: "firstapproval",
    label: t("components.assignments.initRegistration"),
    type: "date",
  };

  const fueltypes = {
    name: "fueltypes",
    label: t("components.assignments.fuel.label"),
    type: "select",
    items: [
      { name: t("components.assignments.fuel.petrol"), value: "Benzin" },
      { name: t("components.assignments.fuel.diesel"), value: "Diesel" },
      { name: t("components.assignments.fuel.earthgas"), value: "CNG" },
      { name: t("components.assignments.fuel.cargas"), value: "LPG" },
      { name: t("components.assignments.fuel.electric"), value: "Elektro" },
      { name: t("components.assignments.fuel.hybrid"), value: "Hybrid" },
      { name: t("components.assignments.fuel.mildHybrid"), value: "Mild-Hybrid" },
      {
        name: t("components.assignments.fuel.plugInHybrid"),
        value: "Plug-In-Hybrid",
      },
      { name: t("components.assignments.fuel.others"), value: "Sonstiges" },
    ],
  };

  const co2km = {
    name: "co2km",
    label: "CO2-Emission (g/km)",
    placeholder: "CO2-Emission (g/km)",
    type: "input",
  };

  const pollutantclass = {
    name: "pollutantclass",
    label: t("components.assignments.emissionClass"),
    placeholder: t("components.assignments.emissionClass"),
    type: "select",
    items: [
      { name: "Euro 00", value: "Euro 00" },
      { name: "Euro 0", value: "Euro 0" },
      { name: "Euro 1", value: "Euro 1" },
      { name: "Euro 2", value: "Euro 2" },
      { name: "Euro 3", value: "Euro 3" },
      { name: "Euro 4", value: "Euro 4" },
      { name: "Euro 5", value: "Euro 5" },
      { name: "Euro 6", value: "Euro 6" },
    ],
  };

  const co2efficiency = {
    name: "co2efficiency",
    label: t("components.assignments.co2Efficiency"),
    placeholder: t("components.assignments.emissionClass"),
    type: "select",
    items: [
      { name: "A+", value: "A+" },
      { name: "A", value: "A" },
      { name: "B", value: "B" },
      { name: "C", value: "C" },
      { name: "D", value: "D" },
      { name: "E", value: "E" },
      { name: "F", value: "F" },
      { name: "G", value: "G" },
    ],
  };

  const finedustcertificate = {
    name: "finedustcertificate",
    label: "Feinstaubplakette",
    type: "checkboxgroup",
    items: [
      { label: "Grün" },
      { label: "Gelb" },
      { label: "Rot" },
      { label: "Blau" },
      { label: "Keine" },
    ],
  };

  const drive = {
    name: "drive",
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.drive.circuit") },
      { label: t("components.assignments.drive.semiAutomatic") },
      { label: t("components.assignments.drive.automatic") },
      { label: t("components.assignments.drive.others") },
    ],
  };

  const power = {
    name: "power",
    label: t("components.assignments.power"),
    placeholder: t("components.assignments.power"),
    type: "input",
  };

  const gears = {
    name: "gears",
    label: t("components.assignments.gears"),
    placeholder: t("components.assignments.gears"),
    type: "input",
  };

  const emptyweight = {
    name: "emptyweight",
    label: t("components.assignments.emptyweight"),
    placeholder: t("components.assignments.emptyweight"),
    type: "input",
  };

  const cardesign = {
    name: "cardesign",
    label: t("components.assignments.build.label"),
    placeholder: t("components.assignments.build.label"),
    type: "select",
    items: [
      { name: t("components.assignments.build.combi"), value: "Kombi" },
      {
        name: t("components.assignments.build.cabrio"),
        value: "Cabrio-Roadster",
      },
      { name: t("components.assignments.build.van"), value: "Van" },
      { name: t("components.assignments.build.minivan"), value: "Kleinbus" },
      { name: t("components.assignments.build.smallCar"), value: "Kleinwagen" },
      { name: t("components.assignments.build.limousine"), value: "Limousine" },
      { name: t("components.assignments.build.coupe"), value: "Coupe" },
      { name: t("components.assignments.build.suv"), value: "SUV" },
      { name: t("components.assignments.build.offroad"), value: "Geländewagen" },
    ],
  };

  const color = {
    name: "color",
    label: t("components.assignments.color"),
    placeholder: t("components.assignments.color"),
    type: "input",
  };

  const carexterior = {
    name: "carexterior",
    label: t("components.assignments.carexterior.label"),
    placeholder: t("components.assignments.carexterior.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.carexterior.allwheel"),
        value: "Allradantrieb",
      },
      {
        name: t("components.assignments.carexterior.heatedRearWindow"),
        value: "Beheizbare-Heckscheibe",
      },
      {
        name: t("components.assignments.carexterior.heatedRearMirrors"),
        value: "Beheizbare-Außenspiegel",
      },
      {
        name: t("components.assignments.carexterior.electricTailgate"),
        value: "Elektrische-Heckklappe",
      },
      {
        name: t("components.assignments.carexterior.electricRearMirrors"),
        value: "Elektrischer-Außenspiegel",
      },
      {
        name: t("components.assignments.carexterior.allyearTires"),
        value: "Ganzjahresreifen",
      },
      {
        name: t("components.assignments.carexterior.winterTires"),
        value: "Winterreifen",
      },
      {
        name: t("components.assignments.carexterior.summerTires"),
        value: "Sommerreifen",
      },
      {
        name: t("components.assignments.carexterior.alloyWheels"),
        value: "Alufelgen",
      },
      {
        name: t("components.assignments.carexterior.steelWheels"),
        value: "Stahlfelgen",
      },
      {
        name: t("components.assignments.carexterior.taintedWindows"),
        value: "Getönte-Scheiben",
      },
      {
        name: t("components.assignments.carexterior.corneringLights"),
        value: "Kurvenfahrlicht-Abbiegelicht",
      },
      {
        name: t("components.assignments.carexterior.daytimeRunningLights"),
        value: "Tagfahrlicht",
      },
      {
        name: t("components.assignments.carexterior.ambientLighting"),
        value: "Umgebungsbeleuchtung",
      },
      {
        name: t("components.assignments.carexterior.discBreak"),
        value: "Scheibenbremse",
      },
      {
        name: t("components.assignments.carexterior.metallicPaint"),
        value: "Metallic-Lackierung",
      },
    ],
  };

  const carinterior = {
    name: "carinterior",
    label: t("components.assignments.carinterior.label"),
    placeholder: t("components.assignments.carinterior.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.carinterior.12vSocket"),
        value: "12V-Steckdose",
      },
      {
        name: t("components.assignments.carinterior.ambientLighting"),
        value: "Ambientebeleuchtung",
      },
      {
        name: t("components.assignments.carinterior.androitCar"),
        value: "Android-Auto",
      },
      {
        name: t("components.assignments.carinterior.appleCar"),
        value: "AppleCarPlay",
      },
      {
        name: t("components.assignments.carinterior.bluetooth"),
        value: "Bluetooth",
      },
      {
        name: t("components.assignments.carinterior.onboardComputer"),
        value: "Bordcomputer",
      },
      {
        name: t("components.assignments.carinterior.hifi"),
        value: "HiFi-Soundsystem",
      },
      {
        name: t("components.assignments.carinterior.cdPlayer"),
        value: "CD-Player-Wechsler",
      },
      {
        name: t("components.assignments.carinterior.digitalCockpit"),
        value: "Digitales-Cockpit",
      },
      {
        name: t("components.assignments.carinterior.coloredDisplay"),
        value: "Farbdisplay",
      },
      {
        name: t("components.assignments.carinterior.headUpDisplay"),
        value: "Head-Up-Display",
      },
      {
        name: t("components.assignments.carinterior.touchscreenDisplay"),
        value: "Touchscreen Display",
      },
      {
        name: t("components.assignments.carinterior.heatedSeats"),
        value: "Beheizbare Sitze",
      },
      {
        name: t("components.assignments.carinterior.electricalWindows"),
        value: "Elektrische Fensterheber",
      },
      {
        name: t("components.assignments.carinterior.electroStarter"),
        value: "Elektrostarter",
      },
      {
        name: t("components.assignments.carinterior.handsfree"),
        value: "Freisprecheinrichtung",
      },
      {
        name: t("components.assignments.carinterior.luggageLighting"),
        value: "Gepäckraumbeleuchtung",
      },
      {
        name: t("components.assignments.carinterior.glassRoof"),
        value: "Glaspanoramadach",
      },
      {
        name: t("components.assignments.carinterior.cruiseControl"),
        value: "Tempomat",
      },
      {
        name: t("components.assignments.carinterior.powerSteering"),
        value: "Servolenkung",
      },
      {
        name: t("components.assignments.carinterior.navigation"),
        value: "Navigationssystem",
      },
      {
        name: t("components.assignments.carinterior.airconditioner"),
        value: "Klimaanlage",
      },
      {
        name: t("components.assignments.carinterior.autoClimateControl"),
        value: "Klimaautomatik",
      },
      {
        name: t("components.assignments.carinterior.multifunctionSteeringWheel"),
        value: "Multifunktionslenkrad",
      },
    ],
  };

  const rentoptions = {
    name: "rentoptions",
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.rentoption.forRent") },
      { label: t("components.assignments.rentoption.forSale") },
    ],
  };

  const rentstateoptions = {
    name: "rentstateoptions",
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.rentstate.rented") },
      { label: t("components.assignments.rentstate.unrented") },
      { label: t("components.assignments.rentstate.notSpecified") },
    ],
  };

  const cardoorquantity = {
    name: "cardoorquantity",
    label: t("components.assignments.cardoorquantity"),
    placeholder: t("components.assignments.cardoorquantity"),
    type: "input",
  };

  const carseatquantity = {
    name: "carseatquantity",
    label: t("components.assignments.carseatquantity"),
    placeholder: t("components.assignments.carseatquantity"),
    type: "input",
  };

  const realestatetypes = {
    name: "realestatetypes",
    type: "select",
    items: [
      {
        name: t("components.assignments.realestatetypes.houses"),
        value: "Häuser",
      },
      {
        name: t("components.assignments.realestatetypes.flats"),
        value: "Wohnungen",
      },
      {
        name: t("components.assignments.realestatetypes.plots"),
        value: "Grundstücke",
      },
      {
        name: t("components.assignments.realestatetypes.commercial"),
        value: "Gewerbeimmobilien",
      },
      {
        name: t("components.assignments.realestatetypes.others"),
        value: "Sonstige-Immobilien",
      },
      {
        name: t("components.assignments.realestatetypes.abroad"),
        value: "Immobilien-im-Ausland",
      },
      {
        name: t("components.assignments.realestatetypes.newTenant"),
        value: "Nachmieter",
      },
      {
        name: t("components.assignments.realestatetypes.sublease"),
        value: "WGs-und-Untermiete",
      },
    ],
  };

  const avaiabledate = {
    name: "avaiabledate",
    label: t("components.assignments.availabledate"),
    placeholder: t("components.assignments.availabledate"),
    type: "date",
  };

  const realestatesize = {
    name: "realestatesize",
    label: t("components.assignments.realestatesize"),
    placeholder: t("components.assignments.realestatesize"),
    type: "input",
  };

  const roomcount = {
    name: "roomcount",
    label: t("components.assignments.roomamount"),
    placeholder: t("components.assignments.roomamount"),
    type: "input",
  };

  const realestatebuildphase = {
    name: "realestatebuildphase",
    itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
    placeholder: "Bauphase",
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.realestatebuildphase.planing") },
      { label: t("components.assignments.realestatebuildphase.construction") },
      { label: t("components.assignments.realestatebuildphase.done") },
      { label: t("components.assignments.realestatebuildphase.others") },
    ],
  };

  const realestatecondition = {
    name: "realestatecondition",
    itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
    placeholder: t("components.assignments.realestatecondition.label"),
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.realestatecondition.old") },
      { label: t("components.assignments.realestatecondition.new") },
      { label: t("components.assignments.realestatecondition.modernized") },
      { label: t("components.assignments.realestatecondition.renovated") },
      { label: t("components.assignments.realestatecondition.needOfRenovation") },
    ],
  };

  const buildyear = {
    name: "buildyear",
    label: t("components.assignments.buildyear"),
    placeholder: t("components.assignments.buildyear"),
    type: "date",
  };

  const realestateupgradetype = {
    name: "realestateupgradetype",
    label: t("components.assignments.upgradetype"),
    placeholder: t("components.assignments.upgradetype"),
    type: "input",
  };

  const realestateequipmenttype = {
    name: "realestateequipmenttype",
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.equipmenttype.normal") },
      { label: t("components.assignments.equipmenttype.lifted") },
      { label: t("components.assignments.equipmenttype.luxury") },
    ],
  };

  const realestateequipment = {
    name: "realestateequipment",
    label: t("components.assignments.realestatefeatures.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.realestatefeatures.wcwindow"),
        value: "Bad mit Fenster",
      },
      {
        name: t("components.assignments.realestatefeatures.wcbath"),
        value: "Bad mit Wanne",
      },
      {
        name: t("components.assignments.realestatefeatures.wcshower"),
        value: "Bad mit Dusche",
      },
      {
        name: t("components.assignments.realestatefeatures.balcony"),
        value: "Balkon-Terasse",
      },
      {
        name: t("components.assignments.realestatefeatures.barrierfree"),
        value: "Barrierefrei",
      },
      {
        name: t("components.assignments.realestatefeatures.seniorproof"),
        value: "Senioren-geeignet",
      },
      {
        name: t("components.assignments.realestatefeatures.garage"),
        value: "Garage-Stellplatz",
      },
      {
        name: t("components.assignments.realestatefeatures.garden"),
        value: "Gartennutzung-Gartenzugang",
      },
      {
        name: t("components.assignments.realestatefeatures.cellar"),
        value: "Keller",
      },
      {
        name: t("components.assignments.realestatefeatures.furnished"),
        value: "Möbiliert-Teilmöbiliert",
      },
      {
        name: t("components.assignments.realestatefeatures.equipedKitchen"),
        value: "Einbauküche",
      },
      {
        name: t("components.assignments.realestatefeatures.guesttoilet"),
        value: "Gäste-WC",
      },
      {
        name: t("components.assignments.realestatefeatures.underfloorheating"),
        value: "Fußbodenheizung",
      },
      {
        name: t("components.assignments.realestatefeatures.sauna"),
        value: "Sauna",
      },
      {
        name: t("components.assignments.realestatefeatures.pool"),
        value: "Pool",
      },
      {
        name: t("components.assignments.realestatefeatures.fireplace"),
        value: "Kamin",
      },
    ],
  };

  const realestatehousetype = {
    name: "realestatehousetype",
    label: t("components.assignments.realestatehousetype.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.realestatehousetype.farm"),
        value: "Bauernhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.bungalow"),
        value: "Bungalow",
      },
      {
        name: t("components.assignments.realestatehousetype.castle"),
        value: "Burg-Schloss",
      },
      {
        name: t("components.assignments.realestatehousetype.semiDetached"),
        value: "Doppelhaushälfte",
      },
      {
        name: t("components.assignments.realestatehousetype.doublehouse"),
        value: "Doppelhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.singleFamily"),
        value: "Einfamilienhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.doubleFamily"),
        value: "Zweifamilienhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.multifamily"),
        value: "Mehrfamilienhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.finca"),
        value: "Finca",
      },
      {
        name: t("components.assignments.realestatehousetype.mansion"),
        value: "Herrenhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.rowendhouse"),
        value: "Reihenendhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.rowhouse"),
        value: "Reihenmittelhaus",
      },
      {
        name: t("components.assignments.realestatehousetype.townhouse"),
        value: "Stadthaus",
      },
      {
        name: t("components.assignments.realestatehousetype.villa"),
        value: "Villa",
      },
    ],
  };

  const realestatefloortype = {
    name: "realestatefloortype",
    label: t("components.assignments.apartmenttype.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.apartmenttype.apartment"),
        value: "Apartment",
      },
      {
        name: t("components.assignments.apartmenttype.flat"),
        value: "Etagenwohnung",
      },
      {
        name: t("components.assignments.apartmenttype.grannyflat"),
        value: "Einliegerwohnung",
      },
      {
        name: t("components.assignments.apartmenttype.terrace"),
        value: "Terassenwohnung",
      },
      { name: t("components.assignments.apartmenttype.loft"), value: "Loft" },
      {
        name: t("components.assignments.apartmenttype.duplex"),
        value: "Maisonette",
      },
      {
        name: t("components.assignments.apartmenttype.penthouse"),
        value: "Penthouse",
      },
      {
        name: t("components.assignments.apartmenttype.basement"),
        value: "Souterrain",
      },
      {
        name: t("components.assignments.apartmenttype.attic"),
        value: "Dachgeschoss",
      },
      {
        name: t("components.assignments.apartmenttype.groundfloor"),
        value: "Erdgeschoss",
      },
    ],
  };

  const rentnetto = {
    name: "rentnetto",
    label: t("components.assignments.netrentcold"),
    placeholder: t("components.assignments.netrentcold"),
    type: "input",
  };

  const rentwarm = {
    name: "rentwarm",
    label: t("components.assignments.netrentwarm"),
    placeholder: t("components.assignments.netrentwarm"),
    type: "input",
  };

  const realestatedeposit = {
    name: "realestatedeposit",
    label: t("components.assignments.deposit"),
    placeholder: t("components.assignments.deposit"),
    type: "input",
  };

  const realestatepartdeposit = {
    name: "realestatepartdeposit",
    itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
    label: t("components.assignments.partdeposit.label"),
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.partdeposit.before") },
      { label: t("components.assignments.partdeposit.rates") },
      { label: t("components.assignments.partdeposit.bailbond") },
      { label: t("components.assignments.partdeposit.transfer") },
      { label: t("components.assignments.partdeposit.arrangement") },
    ],
  };

  const realestateheatingcosts = {
    name: "realestateheatingcosts",
    label: t("components.assignments.heatingCosts.label"),
    type: "checkboxgroup",
    items: [
      { label: t("components.assignments.heatingCosts.included") },
      { label: t("components.assignments.heatingCosts.separately") },
      { label: t("components.assignments.heatingCosts.others") },
    ],
  };

  const realestatefueltype = {
    name: "realestatefueltype",
    label: t("components.assignments.energysource.label"),
    type: "select",
    items: [
      { name: t("components.assignments.energysource.oil"), value: "Öl" },
      { name: t("components.assignments.energysource.gas"), value: "Gas" },
      { name: t("components.assignments.energysource.coal"), value: "Kohle" },
      {
        name: t("components.assignments.energysource.electricity"),
        value: "Strom",
      },
      {
        name: t("components.assignments.energysource.districtHeating"),
        value: "Fernwärme",
      },
      {
        name: t("components.assignments.energysource.hydrogen"),
        value: "Wasserstoff",
      },
      {
        name: t("components.assignments.energysource.renewableEnergy"),
        value: "Erneuerbare Energie",
      },
    ],
  };

  const realestateheatingtype = {
    name: "realestateheatingtype",
    label: t("components.assignments.heatingtype.label"),
    type: "select",
    items: [
      {
        name: t("components.assignments.heatingtype.heatpump"),
        value: "Wärmepumpe",
      },
      {
        name: t("components.assignments.heatingtype.pellet"),
        value: "Pelletheizung",
      },
      {
        name: t("components.assignments.heatingtype.solar"),
        value: "Solarthermie",
      },
      {
        name: t("components.assignments.heatingtype.gasheating"),
        value: "Gasheizung",
      },
      { name: t("components.assignments.heatingtype.bhwk"), value: "BHKW" },
      {
        name: t("components.assignments.heatingtype.fuelcell"),
        value: "Brennstoffzelle",
      },
      {
        name: t("components.assignments.energysource.districtHeating"),
        value: "Fernwärme",
      },
      {
        name: t("components.assignments.heatingtype.oilheating"),
        value: "Ölheizung",
      },
    ],
  };

  const heatingcosts = {
    name: "heatingcosts",
    label: t("components.assignments.heatingcosts"),
    placeholder: t("components.assignments.heatingcosts"),
    type: "input",
  };

  const kwhm3usage = {
    name: "kwhm3usage",
    label: t("components.assignments.kwhm3usage"),
    placeholder: t("components.assignments.kwhm3usage"),
    type: "input",
  };

  const realestateenergyefficiency = {
    name: "realestateenergyefficiency",
    label: t("components.assignments.realestateenergyefficiency"),
    placeholder: t("components.assignments.realestateenergyefficiency"),
    type: "select",
    items: [
      { name: "A+", value: "A+" },
      { name: "A", value: "A" },
      { name: "B", value: "B" },
      { name: "C", value: "C" },
      { name: "D", value: "D" },
      { name: "E", value: "E" },
      { name: "F", value: "F" },
      { name: "G", value: "G" },
      { name: "H", value: "H" },
    ],
  };

  const realestateenergycertificate = {
    name: "realestateenergycertificate",
    itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
    label: t("components.assignments.energyefficiencycertificate.label"),
    type: "checkboxgroup",
    items: [
      {
        label: t("components.assignments.energyefficiencycertificate.available"),
      },
      {
        label: t("components.assignments.energyefficiencycertificate.requested"),
      },
      { label: t("components.assignments.energyefficiencycertificate.set") },
      {
        label: t("components.assignments.energyefficiencycertificate.consumptionCertificate"),
      },
      {
        label: t("components.assignments.energyefficiencycertificate.certificateOfNeed"),
      },
      { label: t("components.assignments.other") },
    ],
  };

  const fileUpload = {
    name: "files",
    itemStyle: "flex flex-wrap gap-x-6 gap-y-0",
    label: t("components.assignments.fileUpload"),
    type: "filesUpload",
  };

  return {
    standard: [
      { label: t("components.assignments.generalData"), type: "label" },
      type,
      pricetype,
      { label: "Produktdaten", type: "label" },
      titleArea,
      description,
      keywordsArea,
      categories,
      quantity,
      [price, offerprice],
      vat,
      [barcode, hscode],
      [shippingservices, reference],
      [trackingid, trackingurl],
      deliverydate,
      agb,
      fileUpload,
    ],
    ads: [
      { label: t("components.assignments.generalData"), type: "label" },
      type,
      title,
      [sector, workerCount],
      [contractStart, contractEnd, onRecall],
      [country, zipCode, city, effectiveRadius],
      [pricetype, costsSupplements],
      [compensation, empty, empty],
      {
        label: t("components.assignments.dailyFlatRate"),
        type: "label",
      },
      {
        label: t("components.assignments.hourlyRate"),
        type: "label",
      },
      {
        label: t("components.assignments.fixprice"),
        type: "label",
      },
      {
        label: t("components.assignments.billOfService"),
        type: "label",
      },
      description,
      publish,
    ],
    vehicle: [
      { label: t("components.assignments.cartypes.label"), type: "label" },
      hsntsn,
      cartypes,
      { label: t("components.assignments.condition.label"), type: "label" },
      condition,
      [tüv, lastinspection],
      [mileage, builddate, firstapproval],
      [fueltypes, co2km],
      [pollutantclass, co2efficiency],
      finedustcertificate,
      { label: t("components.assignments.drive.label"), type: "label" },
      drive,
      [power, gears, emptyweight],
      { label: t("components.shared.equipments"), type: "label" },
      [cardesign, color],
      [carexterior, carinterior],
      [cardoorquantity, carseatquantity],
    ],
    property: [
      {
        label: t("main.navLinks.createAd.create.tabs.property"),
        type: "label",
      },
      realestatetypes,
      rentoptions,
      [avaiabledate, realestatesize, roomcount],
      rentstateoptions,
      {
        label: t("main.navLinks.createAd.create.tabs.constructionPhase"),
        type: "label",
      },
      realestatebuildphase,
      {
        label: t("main.navLinks.createAd.create.tabs.objectState"),
        type: "label",
      },
      realestatecondition,
      [buildyear, realestateupgradetype],
      {
        label: t("main.navLinks.createAd.create.tabs.furnishing"),
        type: "label",
      },
      realestateequipmenttype,
      realestateequipment,
      {
        label: t("main.navLinks.createAd.create.tabs.characteristics"),
        type: "label",
      },
      [realestatehousetype, realestatefloortype],
      {
        label: t("main.navLinks.createAd.create.tabs.cost"),
        type: "label",
      },
      [rentnetto, rentwarm, realestatedeposit],
      realestatepartdeposit,
      {
        label: t("main.navLinks.createAd.create.tabs.extraCosts"),
        type: "label",
      },
      realestateheatingcosts,
      [realestatefueltype, realestateheatingtype],
      [heatingcosts, kwhm3usage, realestateenergyefficiency],
      realestateenergycertificate,
    ],
  };
};

export default AssignmentCreateConfig;

import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import TextView from "@src/components/elements/textedit/TextView";
import WatchType from "@src/utils/types/watch/WatchType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";

type Props = {
  post: WatchType;
  key?: string;
};

const SoundsItem = ({ post }: Props) => {
  const queryClient = useQueryClient();

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${post.post_id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${post.post_id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex flex-row justify-between gap-2" key={post.media_id}>
      <NavLink
        to={`/watch?media_id=${post.media_id}&post_id=${post.post_id}`}
        className="aspect-square h-[65px] rounded-xl object-cover"
      >
        <img
          onError={({ currentTarget }) => {
            currentTarget.src = "/placeholder.png";
          }}
          src={post.media_thumbnail}
          className="aspect-square h-[65px] rounded-xl object-cover"
        />
      </NavLink>
      <TextView value={post.post_text} disableTopMargin />
      <button
        onClick={() => {
          post.bookmarked ? remove.mutate() : add.mutate();
        }}
      >
        {post.bookmarked ? (
          <BookmarkIconSolid className="my-auto ml-auto size-5 shrink-0 text-darkblue" />
        ) : (
          <BookmarkIcon className="my-auto ml-auto size-5 shrink-0 text-darkblue" />
        )}
      </button>
    </div>
  );
};

export default SoundsItem;

import api from "@src/api/api";
import CompactPost from "@src/pages/news/posts/CompactPost";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import tw from "twin.macro";

type Response = {
  data: Array<{
    id: string;
    view_count: number;
    like_count: number;
    picture: string;
    watch: boolean;
  }>;
  current_page: number;
  total_pages: number;
};
type Props = {
  limit?: number;
  columnLimit?: number;
};

const News = ({ limit, columnLimit = 5 }: Props) => {
  const posts = useInfiniteQuery<Response>(
    ["posts", "bookmarks", "tab"],
    async ({ pageParam = 0 }) => {
      const res = await api.get("/api/v1/bookmarks?page=" + pageParam);
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      posts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div className="grid grid-flow-row gap-2">
        <div
          css={[
            tw`grid grid-cols-3 gap-3`,
            columnLimit === 3 ? tw`lg:grid-cols-3` : tw`lg:grid-cols-5`,
          ]}
        >
          {posts.data?.pages
            .flatMap(page => page.data)
            .slice(0, limit)
            .map(post => {
              let src = "/placeholder.png";
              if (post.picture) {
                src = post.picture;
                src = src.replace("height=184", "height=600");
                src = src.replace("/tile", "/post");
              }

              return (
                <>
                  <CompactPost
                    id={post.id}
                    type={post.watch ? "video" : "text"}
                    viewCount={post.view_count}
                    likeCount={post.like_count}
                    image={src}
                  />
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default News;

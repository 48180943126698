import api from "@src/api/api";

export type registerRequest = {
  email: string;
  type: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  password: string;
};

async function register(register: registerRequest): Promise<unknown> {
  const res = await api.post("/api/v1/auth/register", register);
  return res.data;
}

export default register;

import DemoProducts from "@src/mocks/MarketplaceProduct.json";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ArrowButton = styled.div`
  &.swiper-button-next::after,
  &.swiper-button-prev::after {
    color: gray;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 15px;
    }
  }

  @media (min-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 20px;
    }
  }
`;

const HalfCircleTitle = () => {
  const demoProducts = DemoProducts.demoProducts;

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={16}
        slidesPerView={2}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        loop={true}
        className="mySwiper"
        breakpoints={{
          768: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
          2560: {
            slidesPerView: 8,
            spaceBetween: 5,
          },
        }}
        onInit={swiper => {
          swiper.navigation.nextEl.classList.add("swiper-button-next");
          swiper.navigation.prevEl.classList.add("swiper-button-prev");
        }}
      >
        {demoProducts.map((product, index) => (
          // eslint-disable-next-line react/jsx-key
          <SwiperSlide>
            <div
              key={product._id}
              className="relative flex w-full shrink-0 items-center justify-center overflow-hidden rounded-full bg-green-200 md:size-[200px]"
            >
              <img
                src={product.image}
                alt=""
                className="size-[150px] rounded-full object-contain"
              />
              <div className="absolute bottom-0 h-[45px] w-[200px] bg-white p-1 text-center text-xs font-semibold">
                {product.category}
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="hidden md:block">
          <ArrowButton className="swiper-button-next"></ArrowButton>
          <ArrowButton className="swiper-button-prev"></ArrowButton>
        </div>
      </Swiper>
    </>
  );
};

export default HalfCircleTitle;

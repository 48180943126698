import api from "@src/api/api";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import { addItem } from "@src/state/cart/cartSlice";
import usePopupModal from "@src/state/modal/usePopupModal";
import { Cart } from "@src/utils/cart";
import { Product } from "@src/utils/product";
import { User } from "@src/utils/user";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  me: User;
  prod: Product;
  size?: number;
};

const AddCartButton = ({ prod, me }: Props) => {
  const dispatch = useDispatch();
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const addCart = useMutation([`addcart-${me.id}-${prod.id}`], {
    mutationFn: async () => {
      const res = await api.post(`/api/v1/carts/${prod.id}`);
      return res.data;
    },
    onSuccess: () => {
      const newItem: Cart = {
        product_id: prod.id,
        title: prod.title,
        description: prod.description,
        price_net: prod.price_net,
        price_gross: prod.price_gross,
        currency: "USD",
        quantity: 1,
      };
      dispatch(addItem(newItem));
    },
    onError: (data: AxiosError) => {
      if (data.response?.status === 422)
        popupModal.open(
          <Alert
            buttons={Alert.ButtonVariants.OK}
            onClose={popupModal.close}
            message={"The request quantity surpasses product stock."}
            title="Add Cart Error"
          />,
        );
    },
  });

  return (
    <Button
      onClick={addCart.mutate}
      size={Button.Sizes.Small}
      variant={Button.Variants.Transparent}
    >
      {t("main.shop.buttons.addToCart")}
    </Button>
  );
};

export default AddCartButton;

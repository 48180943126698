import api from "@src/api/api";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import CreatePostComment from "@src/components/elements/posts/comments/CreatePostComment";
import PostComment from "@src/components/elements/posts/comments/PostComment";
import { useAppSelector } from "@src/state/hooks";
import { CommentType } from "@src/utils/types/CommentType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";
import tw from "twin.macro";

type Props = {
  postId: string;
  viewCount?: number;
  hideCreateComment?: boolean;
};

type Response = {
  data: Array<CommentType>;
  current_page: number;
  total_pages: number;
};

const PostCommentSection = ({ postId, ...props }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const self = !!user;
  const [answer, setAnswer] = useState<CommentType | undefined>();

  const comments = useInfiniteQuery<Response>(
    ["comments", postId],
    async ({ pageParam = 0 }) => {
      const res = await api.get(`/api/v1/posts/${postId}/comments?page=` + pageParam);
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  if (!comments.isSuccess) return <></>;

  return (
    <div
      className="grid grid-cols-1 gap-3 text-left"
      css={[
        self && !props.hideCreateComment && tw`mt-4`,
        comments.data.pages[0] && comments.data.pages[0].data.length > 0 && tw`mt-4`,
      ]}
    >
      {self && !props.hideCreateComment && (
        <CreatePostComment postId={postId} onSuccess={() => setAnswer(undefined)} />
      )}
      {comments.data.pages.map(page =>
        page.data.map(
          comment =>
            !comment.parent_id && (
              <PostComment
                viewCount={props.viewCount}
                comments={page.data}
                level={0}
                postId={postId}
                key={comment.id}
                comment={comment}
              />
            ),
        ),
      )}
      {(comments.hasNextPage || comments.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => comments.fetchNextPage()} />
      )}
    </div>
  );
};

export default PostCommentSection;

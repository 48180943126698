import { CheckIcon, PencilIcon, ShareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  id: string;
  title?: string;
  description?: string;
  city?: string;
  price?: number;
  country?: string;
  p18?: boolean;
  created_at?: string;
  start_at?: string;
  end_at?: string;
  files?: string[];
  showButtons?: boolean;
  minimized?: boolean;
  showStats?: boolean;
  key?: string;
};

const Assignment = ({
  id = "0",
  title = "Kein Titel",
  description = "",
  city = "",
  price = 0,
  country = "",
  p18 = false,
  created_at = "",
  start_at = "",
  end_at = "",
  files = [],
  showButtons = false,
  minimized = false,
  showStats = true,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${
        minimized ? "w-[calc(18%-8px)] max-w-[75px] shrink-0" : "rounded-2xl bg-white p-3 shadow"
      }`}
    >
      <div className="text-sm">
        <NavLink to={"/inserate/" + id}>
          <img src="/placeholder.png" className={`${minimized && "rounded-lg"}`} />
          {!minimized && (
            <Fragment>
              <div className="line-clamp-1 overflow-x-hidden text-ellipsis text-center text-sm">
                {title}
              </div>
              <div className="text-center">{price.toFixed(2)} €</div>
              <div className="flex w-full flex-row justify-between text-ellipsis whitespace-nowrap text-mini text-gray-600">
                <div>{city}</div>
                <div>
                  Ab:{" "}
                  {new Date(start_at).toLocaleString("de-DE", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </div>
              </div>
              {showStats && (
                <div className="flex w-full flex-col gap-1">
                  <div className="flex flex-row justify-between text-xs text-gray-600">
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.followers.title")}</div>
                      <div>---</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.follows.title")}</div>
                      <div>---</div>
                    </div>
                    <div className="flex flex-col items-center gap-x-1">
                      <div>{t("main.likes.title")}</div>
                      <div>---</div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </NavLink>
        {showButtons && !minimized && (
          <div className="flex justify-between">
            <PencilIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <ShareIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <CheckIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-green-600 p-0.5 text-white" />
            <TrashIcon className="heroicon-stroke-w-2.4 size-4 rounded-full bg-red-600 p-0.5 text-white" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Assignment;

import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import Trendsitem from "@src/components/elements/hashtags/Trendsitem";
import { useInfiniteQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { NavLink } from "react-router-dom";

type Hashtag = {
  total_views: number;
  times_used: number;
  first_user_id: string;
  total_like_count: number;
  hashtag: string;
  media_id?: string;
  media_key?: string;
  media_type?: string;
  media_filename?: string;
  media_thumbnail?: string;
  media_data_url?: string;
  author_name: string;
  author_id: string;
  author_avatar: string;
  author_type: string;
};

type Response = {
  data: Array<Hashtag>;
  current_page: number;
  total_pages: number;
};

const fetchHashtags = async (watch: boolean) => {
  const res = await api.get(`/api/v1/hashtags?page=0&watch=${watch}&trending=true`);
  return res.data;
};

const Trends = () => {
  const watchHashtags = useInfiniteQuery<Response>(["hashtags", true], () => fetchHashtags(true));
  const feedHashtags = useInfiniteQuery<Response>(["hashtags", false], () => fetchHashtags(false));

  if (!watchHashtags.isSuccess && !feedHashtags.isSuccess) return <></>;

  return (
    <Panel>
      <div className="grid grid-cols-1 gap-1">
        {watchHashtags.isSuccess && (
          <>
            <h2 className="text-base font-semibold">
              {t("main.watchTabs.trendingVideoTag", {
                count: (watchHashtags.data?.pages[0].data || []).length,
              })}
            </h2>
            <hr />
            <div className="flex flex-col gap-1">
              {watchHashtags.data?.pages.map(page =>
                (page.data || []).map((hashtag, index) => {
                  return (
                    <Trendsitem
                      index={index}
                      tab="watch"
                      key={hashtag.hashtag}
                      tag={hashtag.hashtag}
                      type={hashtag.media_type}
                      viewCount={hashtag.total_views}
                      likeCount={hashtag.total_like_count}
                      authorName={hashtag.author_name}
                      authorAvatar={hashtag.author_avatar}
                    />
                  );
                }),
              )}
            </div>
          </>
        )}

        {feedHashtags.isSuccess && (
          <>
            <h2 className="text-base font-semibold">
              {t("main.watchTabs.trendingPostTag", {
                count: (feedHashtags.data?.pages[0].data || []).length,
              })}
            </h2>
            <hr />
            <div className="flex flex-col gap-1">
              {feedHashtags.isSuccess &&
                feedHashtags.data?.pages.map(page =>
                  (page.data || []).map((hashtag, index) => {
                    return (
                      <Trendsitem
                        index={index}
                        tab="feed"
                        key={hashtag.hashtag}
                        tag={hashtag.hashtag}
                        type={hashtag.media_type}
                        viewCount={hashtag.total_views}
                        likeCount={hashtag.total_like_count}
                        authorName={hashtag.author_name}
                        authorAvatar={hashtag.author_avatar}
                      />
                    );
                  }),
                )}
            </div>
            <NavLink
              to={"entdecken/hashtags/feed"}
              className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
            >
              {t("buttons.showMore")}
            </NavLink>
          </>
        )}
      </div>
    </Panel>
  );
};

export default Trends;

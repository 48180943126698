import api from "@src/api/api";
import MarketplaceShopAllItem from "@src/pages/marketplace/shop/MarketplaceShopAllItem";
import { Product } from "@src/utils/product";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Response = {
  data: Product[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
  showPlaceholder?: boolean;
};

const MarketplaceShopAllContainer = ({ searchQuery, showPlaceholder }: Props) => {
  const products = useInfiniteQuery<Response>(
    ["marketplace-shop_all", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/marketplace/products?page=${pageParam}&q=${searchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (products.isFetchingNextPage || !products.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      products.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {products.data &&
          products.data.pages.map(
            page =>
              page.data &&
              page.data
                .slice(0, 5)
                .map((item: Product) => <MarketplaceShopAllItem key={item.id} product={item} />),
          )}
      </div>
    </>
  );
};

export default MarketplaceShopAllContainer;

import api from "@src/api/api";
import JobsListItem from "@src/pages/jobs/JobsListItem";
import { JobType } from "@src/utils/types/JobType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Response = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Jobs = ({ searchQuery, showPlaceholder }: Props) => {
  const jobs = useInfiniteQuery<Response>(
    ["jobs", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(`/api/v1/jobs?page=${pageParam}&q=${searchQuery}`);
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (jobs.isFetchingNextPage || !jobs.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      jobs.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {jobs.data &&
          jobs.data.pages.map(
            page =>
              page.data &&
              page.data.map((job: JobType) => (
                <JobsListItem key={job.id} job={job} showButtons={false} />
              )),
          )}
      </div>
    </>
  );
};

export default Jobs;

import api from "@src/api/api";
import ContactForYouItem from "@src/pages/contacts/for_you/ContactForYouItem";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Props = {
  type: string;
  userId: string;
  searchQuery: string;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};
const UserLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);

  const list = useInfiniteQuery<Response>(
    [props.type, userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/users/${userId}/${props.type}?page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (list.isFetchingNextPage || !list.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      list.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {list.data &&
        list.data.pages.map(page =>
          page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
        )}
    </>
  );
};

export default UserLikes;

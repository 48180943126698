import AssignmentsSuggestions from "@src/components/elements/sidebar/suggestions/AssignmentsSuggestions";
import JobsSuggestions from "@src/components/elements/sidebar/suggestions/JobsSuggestions";
import MarketplaceSuggestions from "@src/components/elements/sidebar/suggestions/MarketplaceSuggestions";
import NewsfeedSuggestions from "@src/components/elements/sidebar/suggestions/NewsfeedSuggestions";
import WatchSuggestions from "@src/components/elements/sidebar/suggestions/WatchSuggestions";
import { useMemo } from "react";

const SuggestionsContainer = () => {
  const seed = useMemo(() => {
    return Math.floor(Math.random() * 100);
  }, []);

  if (seed < 20) {
    return <NewsfeedSuggestions />;
  } else if (seed < 40) {
    return <JobsSuggestions />;
  } else if (seed < 60) {
    return <MarketplaceSuggestions />;
  } else if (seed < 80) {
    return <AssignmentsSuggestions />;
  } else {
    return <WatchSuggestions />;
  }
};

export default SuggestionsContainer;

import api from "@src/api/api";
import { AxiosResponse } from "axios";

export type ResetPasswordRequest = {
  email: string;
};

async function requestPasswordReset(request: ResetPasswordRequest): Promise<AxiosResponse> {
  return await api.post("/api/v1/auth/reset", request);
}

export default requestPasswordReset;

import api from "@src/api/api";
import PostWidget from "@src/components/elements/PostWidget";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import { Post } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
};

const MyFeedContainer = ({ searchQuery }: Props) => {
  const [lightboxSelection, setLightboxSelection] = useState("");
  const posts = useInfiniteQuery<Response>(
    ["posts", "following_feed", searchQuery],
    async ({ pageParam = 0 }) => {
      const res = await api.get("/api/v1/feed/following?page=" + pageParam + "&q=" + searchQuery);
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  if (!posts.isSuccess) return <></>;

  return (
    <>
      {posts.data.pages.map(
        (page, pageIndex) =>
          page &&
          page.data.map((post: Post, index: number) => (
            <div
              className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
              key={post.id}
            >
              <PostWidget
                postId={post.id}
                post={post}
                key={post.id}
                text={post.text}
                author={post.author}
                created_at={post.created_at}
                onSwitch={(id: string) => {
                  setLightboxSelection(id);
                }}
                lightboxSelection={lightboxSelection}
                prev={
                  index == 0 && pageIndex > 0
                    ? posts.data.pages[pageIndex - 1].data[
                        posts.data.pages[pageIndex - 1].data.length - 1
                      ].id
                    : index > 0
                      ? posts.data.pages[pageIndex].data[index - 1].id
                      : ""
                }
                next={
                  pageIndex + 1 < posts.data.pages.length &&
                  index + 1 == posts.data.pages[pageIndex].data.length
                    ? posts.data.pages[pageIndex + 1].data[0].id
                    : index + 1 < posts.data.pages[pageIndex].data.length
                      ? posts.data.pages[pageIndex].data[index + 1].id
                      : ""
                }
              />
            </div>
          )),
      )}

      {(posts.hasNextPage || posts.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => posts.fetchNextPage()} />
      )}
    </>
  );
};

export default MyFeedContainer;

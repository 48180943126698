import {
  ArrowUpOnSquareIcon,
  BellIcon,
  BookmarkIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  EnvelopeIcon,
  FolderIcon,
  GiftIcon,
  HeartIcon,
  NewspaperIcon,
  PencilIcon,
  RectangleGroupIcon,
  ShoppingCartIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUpOnSquareIcon as ArrowUpOnSquareIconSolid,
  BellIcon as BellIconSolid,
  BookmarkIcon as BookmarkIconSolid,
  BriefcaseIcon as BriefcaseIconSolid,
  BuildingStorefrontIcon as BuildingStorefrontIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  EnvelopeIcon as EnvelopeIconSolid,
  FolderIcon as FolderIconSolid,
  GiftIcon as GiftIconSolid,
  HeartIcon as HeartIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  PencilIcon as PencilIconSolid,
  RectangleGroupIcon as RectangleGroupIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  TagIcon as TagIconSolid,
  UserIcon as UserIconSolid,
  UsersIcon as UsersIconSolid,
  VideoCameraIcon as VideoCameraIconSolid,
} from "@heroicons/react/24/solid";
import { TFunction } from "i18next";
import { ReactNode } from "react";

export type ListType = {
  href: string;
  label: string;
  icon: ReactNode;
  solidIcon: ReactNode;
  style?: string;
  notification?: { count: number; minimum: number; link: string; type: string };
  reverse?: boolean;
  children?: Array<ListType>;
  extern?: boolean;
  authRequired?: boolean;
};

const NavSideLinkList = (t: TFunction): Array<ListType> => [
  {
    href: "",
    label: t("main.panel.settings"),
    icon: <Cog6ToothIcon className="size-4" />,
    solidIcon: <Cog6ToothIconSolid className="size-4" />,
    style: "hidden xl:flex",
    authRequired: true,
    children: [
      {
        href: "/verwaltung/profile",
        label: t("main.panel.profileWidgets.editProfile"),
        icon: <PencilIcon className="size-4" />,
        solidIcon: <PencilIconSolid className="size-4" />,
        authRequired: true,
      },
      {
        href: "/verwaltung/account",
        label: t("main.panel.profileWidgets.account"),
        icon: <UserIcon className="size-4" />,
        solidIcon: <UserIconSolid className="size-4" />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/benachrichtigungen",
    label: t("main.navLinks.notifications"),
    icon: <BellIcon className="size-4" />,
    solidIcon: <BellIconSolid className="size-4" />,
    authRequired: true,
  },
  {
    href: "/news",
    label: t("main.navLinks.news"),
    icon: <NewspaperIcon className="size-4" />,
    solidIcon: <NewspaperIconSolid className="size-4" />,
  },
  {
    href: "/watch",
    label: t("main.navLinks.watch"),
    icon: <VideoCameraIcon className="size-4" />,
    solidIcon: <VideoCameraIconSolid className="size-4" />,
  },
  {
    href: "/messenger",
    label: t("main.navLinks.messenger"),
    icon: <EnvelopeIcon className="size-4" />,
    solidIcon: <EnvelopeIconSolid className="size-4" />,
    notification: { count: 0, minimum: 1, link: "", type: "number" },
    authRequired: true,
  },
  {
    href: "/kontakte/für_dich",
    label: t("main.header.links.contacts"),
    icon: <UsersIcon className="size-4" />,
    solidIcon: <UsersIconSolid className="size-4" />,
    notification: {
      count: 0,
      minimum: 1,
      link: "/kontakte/für_dich",
      type: "number",
    },
  },
  {
    href: "/verwaltung/jobs/jobs_erstellen",
    label: t("main.navLinks.jobsPublished.title"),
    icon: <BriefcaseIcon className="size-4" />,
    solidIcon: <BriefcaseIconSolid className="size-4" />,
    notification: { count: 0, minimum: 1, link: "", type: "dot" },
    authRequired: true,
  },
  {
    href: "/inserate/inserate_erstellen",
    label: t("main.navLinks.createAd.title"),
    icon: <TagIcon className="size-4 rotate-180 -scale-y-100" />,
    solidIcon: <TagIconSolid className="size-4 rotate-180 -scale-y-100" />,
    authRequired: true,
  },
  {
    href: "/marketplace/für_dich",
    label: t("main.navLinks.marketplace.title"),
    icon: <BuildingStorefrontIcon className="size-4" />,
    solidIcon: <BuildingStorefrontIconSolid className="size-4" />,
    children: [
      {
        href: "/marketplace/warenkorb",
        label: t("main.navLinks.marketplace.card"),
        icon: <ShoppingCartIcon className="size-4" />,
        solidIcon: <ShoppingCartIconSolid className="size-4" />,
        authRequired: true,
      },
      {
        href: "/marketplace/wunschliste",
        label: t("main.navLinks.marketplace.wishList"),
        icon: <HeartIcon className="size-4" />,
        solidIcon: <HeartIconSolid className="size-4" />,
        authRequired: true,
      },
      {
        href: "/marketplace/kaufübersicht",
        label: t("main.navLinks.marketplace.viewCard"),
        icon: <GiftIcon className="size-4" />,
        solidIcon: <GiftIconSolid className="size-4" />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/verwaltung/mein_shop/übersicht",
    label: t("main.navLinks.myShop.title"),
    icon: <FolderIcon className="size-4" />,
    solidIcon: <FolderIconSolid className="size-4" />,
    authRequired: true,
    children: [
      {
        href: "/verwaltung/mein_shop/verkaufen",
        label: t("main.navLinks.myShop.sell"),
        icon: <PencilIcon className="size-4" />,
        solidIcon: <PencilIconSolid className="size-4" />,
        authRequired: true,
      },
      {
        href: "/verwaltung/mein_shop/vorlagen",
        label: t("main.navLinks.myShop.templates"),
        icon: <ClipboardDocumentListIcon className="size-4" />,
        solidIcon: <ClipboardDocumentListIconSolid className="size-4" />,
        authRequired: true,
      },
      {
        href: "/verwaltung/mein_shop/importieren",
        label: t("main.navLinks.myShop.import"),
        icon: <ArrowUpOnSquareIcon className="size-4" />,
        solidIcon: <ArrowUpOnSquareIconSolid className="size-4" />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/bookmarks",
    label: t("main.navLinks.watchLists"),
    icon: <BookmarkIcon className="size-4" />,
    solidIcon: <BookmarkIconSolid className="size-4" />,
    authRequired: true,
  },
  {
    href: "/entdecken",
    label: t("main.navLinks.discover"),
    icon: <img src="/images/compass.svg" className="size-4" />,
    solidIcon: <img src="/images/compass_solid.svg" className="size-4" />,
  },
  {
    href: "/werbung-erstellen",
    label: t("main.navLinks.createAd.tradefooxAdd"),
    icon: <RectangleGroupIcon className="size-4" />,
    solidIcon: <RectangleGroupIconSolid className="size-4" />,
  },
];

export default NavSideLinkList;

import api from "@src/api/api";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import ContactForYouItem from "@src/pages/contacts/for_you/ContactForYouItem";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
  tab?: string;
};

const ContactForYouContainer = ({ searchQuery, tab }: Props) => {
  const { t } = useTranslation();

  const contacts = useInfiniteQuery<Response>(
    ["contacts-for_you", searchQuery, tab === "all" ? "" : tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=${pageParam}&q=${searchQuery}&type=${
          tab === "all" ? "" : tab
        }`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (contacts.isFetchingNextPage || !contacts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      contacts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <TabController isSubTab defaultTab={tab}>
        <TabHeaders>
          <TabHeader id="all" url="/kontakte/für_dich/alle">
            {t("main.contacts.tabs.all")}
          </TabHeader>
          <TabHeader id="person" url="/kontakte/für_dich/personen">
            {t("main.contacts.tabs.person")}
          </TabHeader>
          <TabHeader id="company" url="/kontakte/für_dich/firmen">
            {t("main.contacts.tabs.company")}
          </TabHeader>
          <TabHeader id="club" url="/kontakte/für_dich/vereine">
            {t("main.contacts.tabs.club")}
          </TabHeader>
        </TabHeaders>

        <TabBodies>
          <TabBody id="all">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {contacts.data &&
                contacts.data.pages.map(page =>
                  page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
                )}
            </div>
          </TabBody>
          <TabBody id="person">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {contacts.data &&
                contacts.data.pages.map(page =>
                  page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
                )}
            </div>
          </TabBody>
          <TabBody id="company">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {contacts.data &&
                contacts.data.pages.map(page =>
                  page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
                )}
            </div>
          </TabBody>
          <TabBody id="club">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {contacts.data &&
                contacts.data.pages.map(page =>
                  page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
                )}
            </div>
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default ContactForYouContainer;

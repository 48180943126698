import * as Sentry from "@sentry/react";
import { useMe } from "@src/api/auth/me";
import CreateAd from "@src/components/elements/advertisement/CreateAd";
import CreatePost from "@src/components/elements/posts/form/CreatePost";
import FullscreenMainWrapper from "@src/pages/FullscreenMainWrapper";
import MainWrapper from "@src/pages/MainWrapper";
import MobileMainWrapper from "@src/pages/MobileMainWrapper";
import AccountDeactivationPage from "@src/pages/account/AccountDeactivationPage";
import AdvertisementContainer from "@src/pages/advertisement/AdvertisementContainer";
import AssignmentsContainer from "@src/pages/assignments/AssignmentsContainer";
import AssignmentsDetails from "@src/pages/assignments/AssignmentsDetails";
import ActivateContainer from "@src/pages/auth/ActivateContainer";
import ChangePasswordContainer from "@src/pages/auth/ChangePasswordContainer";
import LoginContainer from "@src/pages/auth/LoginContainer";
import LogoutContainer from "@src/pages/auth/LogoutContainer";
import RegisterContainer from "@src/pages/auth/RegisterContainer";
import RequestPasswordResetContainer from "@src/pages/auth/RequestPasswordResetContainer";
import ResetPasswordContainer from "@src/pages/auth/ResetPasswordContainer";
import SocialAuthSuccessContainer from "@src/pages/auth/SocialAuthSuccessContainer";
import BookmarksContainer from "@src/pages/bookmarks/BookmarksContainer";
import ContactsContainer from "@src/pages/contacts/ContactsContainer";
import DiscoverContainer from "@src/pages/discover/DiscoverContainer";
import GroupDetailsContainer from "@src/pages/groups/GroupDetailsContainer";
import GroupsContainer from "@src/pages/groups/GroupsContainer";
import HashtagContainer from "@src/pages/hashtags/HashtagContainer";
import ActGlobal from "@src/pages/info/ActGlobal";
import CompanyFAQ from "@src/pages/info/CompanyFAQ";
import Copyright from "@src/pages/info/Copyright";
import CreateAccount from "@src/pages/info/CreateAccount";
import Donations from "@src/pages/info/Donations";
import EventCalendar from "@src/pages/info/EventCalendar";
import IndustrialHistory from "@src/pages/info/IndustrialHistory";
import IndustrialHistoryDetails from "@src/pages/info/IndustrialHistoryDetails";
import LeisureMaps from "@src/pages/info/LeisureMaps";
import MemberFAQ from "@src/pages/info/MemberFAQ";
import Newsletter from "@src/pages/info/Newsletter";
import Newspapers from "@src/pages/info/Newspapers";
import Partner from "@src/pages/info/Partner";
import StartupAdvice from "@src/pages/info/StartupAdvice";
import Successor from "@src/pages/info/Successor";
import Support from "@src/pages/info/Support";
import Unsubscripe from "@src/pages/info/Unsubscripe";
import WatchFAQ from "@src/pages/info/WatchFAQ";
import JobReviewsContainer from "@src/pages/jobs/JobReviewsContainer";
import JobsContainer from "@src/pages/jobs/JobsContainer";
import JobsDetails from "@src/pages/jobs/JobsDetails";
import CommunityRulesContainer from "@src/pages/legal/CommunityRulesContainer";
import ConditionsContainer from "@src/pages/legal/ConditionsContainer";
import CookiesContainer from "@src/pages/legal/CookiesContainer";
import CopyRightContainer from "@src/pages/legal/CopyRightContainer";
import GlossaryContainer from "@src/pages/legal/GlossaryContainer";
import HelpContainer from "@src/pages/legal/HelpContainer";
import InfoContainer from "@src/pages/legal/InfoContainer";
import LegalNoticeContainer from "@src/pages/legal/LegalNoticeContainer";
import MarketingContainer from "@src/pages/legal/MarketingContainer";
import MoreContainer from "@src/pages/legal/MoreContainer";
import PrivacyContainer from "@src/pages/legal/PrivacyContainer";
import TermsOfUseContainer from "@src/pages/legal/TermsOfUseContainer";
import MarketplaceContainer from "@src/pages/marketplace/MarketplaceContainer";
import MarketplaceDetails from "@src/pages/marketplace/MarketplaceDetails";
import MarketplaceProductReviews from "@src/pages/marketplace/MarketplaceProductReviews";
import OrderDetails from "@src/pages/marketplace/components/OrderDetails";
import MessengerContainer from "@src/pages/messenger/MessengerContainer";
import NewsContainer from "@src/pages/news/NewsContainer";
import NotificationsContainer from "@src/pages/notifications/NotificationsContainer";
import ProfileContainer from "@src/pages/profile/ProfileContainer";
import PrintContainer from "@src/pages/profile/cv/PrintContainer";
import AccountEditContainer from "@src/pages/profile/edit/AccountEditContainer";
import ProfileEditContainer from "@src/pages/profile/edit/ProfileEditContainer";
import WatchContainer from "@src/pages/watch/WatchContainer";
import WatchMobilePage from "@src/pages/watch/WatchMobilePage";
import CreateWatchContainer from "@src/pages/watch/create/CreateWatchContainer";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePaymentModal from "@src/state/modal/usePaymentModal";
import isApp from "@src/utils/isApp";
import isMobile from "@src/utils/isMobile";
import _ from "lodash";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const Router = () => {
  function isValidUUID(uuid: string | undefined) {
    const regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return regex.test(uuid ?? "");
  }

  const { user, token } = useAppSelector(state => state.user);
  const me = useMe(token);
  const loginModal = useLoginModal();
  const paymentModal = usePaymentModal();
  const location = useLocation();
  const isBookmarks =
    location.pathname.startsWith("/merklisten") || location.pathname.startsWith("/bookmarks");
  const isContacts =
    location.pathname.startsWith("/kontakte") || location.pathname.startsWith("/contacts");
  const isInserate =
    location.pathname.startsWith("/inserate") || location.pathname.startsWith("/inserate");
  const isJobs =
    location.pathname.startsWith("/jobs") || location.pathname.startsWith("/verwaltung/jobs/");
  const isAds = location.pathname.startsWith("/ads") || location.pathname.startsWith("/werbung");
  const isWatch =
    location.pathname.startsWith("/watch") && !location.pathname.startsWith("/watch_faq");
  const isWatchCreate = location.pathname.startsWith("/watch/erstellen");
  const isMarketplace =
    location.pathname.startsWith("/marketplace") ||
    location.pathname.startsWith("/marketplace/") ||
    location.pathname.startsWith("/verwaltung/mein_shop/");
  const isTags = location.pathname.startsWith("/tags");
  const isDiscover = location.pathname.startsWith("/entdecken");
  const isProfile =
    location.pathname.startsWith("/profile") || location.pathname.startsWith("/verwaltung/profile");
  const otherPages =
    location.pathname.startsWith("/info") ||
    location.pathname.startsWith("/help") ||
    location.pathname.startsWith("/marketing") ||
    location.pathname.startsWith("/impressum") ||
    location.pathname.startsWith("/datenschutz") ||
    location.pathname.startsWith("/business") ||
    location.pathname.startsWith("/glossar") ||
    location.pathname.startsWith("/mehr") ||
    location.pathname.startsWith("/industriegeschichte") ||
    location.pathname.startsWith("/amts_gemeindebl%C3%A4tter") ||
    location.pathname.startsWith("/kunst_kultur") ||
    location.pathname.startsWith("/startup_tipps") ||
    location.pathname.startsWith("/marken_patentrecht") ||
    location.pathname.startsWith("/nachfolger") ||
    location.pathname.startsWith("/partner") ||
    location.pathname.startsWith("/agb") ||
    location.pathname.startsWith("/global_agieren") ||
    location.pathname.startsWith("/watch_faq") ||
    location.pathname.startsWith("/firmen_faq") ||
    location.pathname.startsWith("/mitglieder_faq") ||
    location.pathname.startsWith("/spendenaktionen") ||
    location.pathname.startsWith("/newsletter") ||
    location.pathname.startsWith("/messe_kalender") ||
    location.pathname.startsWith("/account_erstellen") ||
    location.pathname.startsWith("/support") ||
    location.pathname.startsWith("/urheberrecht") ||
    location.pathname.startsWith("/cookies") ||
    location.pathname.startsWith("/nutzungsbedingungen") ||
    location.pathname.startsWith("/datenschutzrichtlinie") ||
    location.pathname.startsWith("/community") ||
    location.pathname.startsWith("/social-login-erfolg") ||
    location.pathname.startsWith("/benachrichtigungen") ||
    location.pathname.startsWith("/verwaltung/jobs/jobs_erstellen") ||
    location.pathname.startsWith("/verwaltung/mein_shop/verkaufen") ||
    location.pathname.startsWith("/inserate/inserate_erstellen") ||
    location.pathname.startsWith("/registrierung") ||
    location.pathname.startsWith("/tradefoox-ads");
  const darkHeader = false;
  const darkFooter = isWatch;
  const sidebarButtonVisible = false;
  const useFullscreenWrapper =
    (isMarketplace ||
      isInserate ||
      isJobs ||
      isDiscover ||
      isBookmarks ||
      isWatchCreate ||
      isWatch ||
      isAds ||
      isProfile) &&
    !isMobile;
  const isAccountMenu = location.pathname.startsWith("/verwaltung/account/");
  const isJobsDetails = isJobs && isValidUUID(_.last(location.pathname.split("/")));
  const isInserateDetails = isInserate && isValidUUID(_.last(location.pathname.split("/")));
  const isMarketplaceDetails = isMarketplace && isValidUUID(_.last(location.pathname.split("/")));
  const showCart = isMarketplace;
  const smallSideBar =
    isMarketplace ||
    isInserate ||
    isJobs ||
    isDiscover ||
    isBookmarks ||
    isWatch ||
    isAds ||
    isProfile;
  const borderless =
    (isProfile ||
      isWatch ||
      isMarketplaceDetails ||
      isInserateDetails ||
      isJobsDetails ||
      location.pathname.startsWith("/amts_gemeindebl%C3%A4tter") ||
      location.pathname.startsWith("/kunst_kultur") ||
      location.pathname.startsWith("/nachfolger") ||
      location.pathname.startsWith("/global_agieren") ||
      location.pathname.startsWith("/mitglieder_faq") ||
      location.pathname.startsWith("/account_erstellen") ||
      location.pathname.startsWith("/marken_patentrecht") ||
      location.pathname.startsWith("/industriegeschichte") ||
      location.pathname.startsWith("/info") ||
      location.pathname.startsWith("/startup_tipps") ||
      location.pathname.startsWith("/partner") ||
      location.pathname.startsWith("/firmen_faq") ||
      location.pathname.startsWith("/spendenaktionen") ||
      location.pathname.endsWith("/newsletter") ||
      location.pathname.startsWith("/messe_kalender") ||
      location.pathname.startsWith("/support") ||
      isAds) &&
    isMobile;
  const fullscreen =
    (isProfile ||
      isAccountMenu ||
      isMarketplaceDetails ||
      isInserateDetails ||
      isAds ||
      isJobsDetails ||
      isDiscover ||
      isBookmarks ||
      isContacts ||
      isTags ||
      otherPages) &&
    isMobile;
  const topNavHidden = isWatch && isMobile;
  const showBG =
    location.pathname.includes("erstellen") ||
    location.pathname.includes("create") ||
    location.pathname.includes("verkaufen");

  const routes = (
    <>
      <Route path="/marketplace" index element={<Navigate to="/marketplace/für_dich" />} />
      {isMarketplaceDetails ? (
        <Route path="/marketplace/:id" index element={<MarketplaceDetails />} />
      ) : (
        <Route path="/marketplace/:id" element={<Navigate to="/marketplace/für_dich" replace />} />
      )}
      <Route
        path="/marketplace/product_reviews/:id"
        index
        element={<MarketplaceProductReviews />}
      />
      <Route
        path="/marketplace/für_dich"
        index
        element={<MarketplaceContainer mainFilter="main_marketplace" />}
      />
      <Route
        path="/verwaltung/mein_shop/übersicht"
        index
        element={
          <MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="myShop" />
        }
      />
      <Route
        path="/verwaltung/mein_shop/messenger"
        index
        element={
          <MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="messenger" />
        }
      />
      <Route
        path="/verwaltung/mein_shop/statistik"
        index
        element={<MarketplaceContainer mainFilter="main_my_shop" topTab="statistics" subTab="" />}
      />
      <Route
        path="/verwaltung/mein_shop/verkaufen"
        index
        element={<MarketplaceContainer mainFilter="main_my_shop" topTab="create" subTab="" />}
      />
      <Route
        path="/verwaltung/mein_shop/verkäufe"
        index
        element={<MarketplaceContainer mainFilter="main_my_shop" topTab="sells" subTab="" />}
      />
      <Route
        path="/verwaltung/mein_shop/rechnungen"
        index
        element={<MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="bills" />}
      />
      <Route
        path="/verwaltung/mein_shop/vorlagen"
        index
        element={
          <MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="templates" />
        }
      />
      <Route
        path="/verwaltung/mein_shop/importieren"
        index
        element={
          <MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="import" />
        }
      />
      <Route
        path="/marketplace/rechnungen"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="shop" subTab="bills" />
        }
      />
      <Route path="/marketplace/order_detials/:id" index element={<OrderDetails />} />
      <Route
        path="/marketplace/following"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="following" subTab="" />
        }
      />
      <Route
        path="/marketplace/follower"
        index
        element={<MarketplaceContainer mainFilter="main_marketplace" topTab="follower" subTab="" />}
      />
      <Route
        path="/marketplace/trending"
        index
        element={<MarketplaceContainer mainFilter="main_marketplace" topTab="trending" subTab="" />}
      />
      <Route
        path="/marketplace/wunschliste"
        index
        element={<MarketplaceContainer mainFilter="main_marketplace" topTab="wishList" subTab="" />}
      />
      <Route
        path="/marketplace/kaufübersicht"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="shop" subTab="purchases" />
        }
      />
      <Route
        path="/marketplace/wieder_kaufen"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="shop" subTab="buyAgain" />
        }
      />
      <Route
        path="/marketplace/zahlungsmethode"
        index
        element={
          <MarketplaceContainer
            mainFilter="main_marketplace"
            topTab="shop"
            subTab="paymentMethod"
          />
        }
      />
      <Route
        path="/verwaltung/mein_shop/zahlungsmethode"
        index
        element={
          <MarketplaceContainer mainFilter="main_my_shop" topTab="my_shop" subTab="paymentMethod" />
        }
      />
      <Route
        path="/marketplace/zuletzt_gesehen"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="shop" subTab="lastSeen" />
        }
      />
      <Route
        path="/marketplace/bestellungen"
        index
        element={
          <MarketplaceContainer mainFilter="main_marketplace" topTab="shop" subTab="orders" />
        }
      />
      <Route
        path="/verwaltung/mein_shop/bestellungen"
        index
        element={<MarketplaceContainer mainFilter="main_my_shop" topTab="orders" subTab="" />}
      />
      <Route
        path="/marketplace/warenkorb"
        index
        element={<MarketplaceContainer mainFilter="main_marketplace" topTab="cart" subTab="" />}
      />
      <Route path="/inserate" element={<Navigate to="/inserate/für_dich" replace />} />
      <Route
        path="/inserate/create"
        element={<Navigate to="/verwaltung/inserate/inserate_erstellen" />}
      />
      <Route
        path="/inserate/inserate_erstellen"
        index
        element={<AssignmentsContainer topTab="create" />}
      />
      <Route
        path="/inserate/für_dich"
        index
        element={<AssignmentsContainer topTab="for_you" subTab="all" />}
      />
      <Route
        path="/inserate/verwalten"
        index
        element={<AssignmentsContainer topTab="for_you" subTab="manage" />}
      />
      <Route
        path="/inserate/rechnungen"
        index
        element={<AssignmentsContainer topTab="for_you" subTab="bills" />}
      />
      <Route
        path="/inserate/statistik"
        index
        element={<AssignmentsContainer topTab="for_you" subTab="statistics" />}
      />
      <Route
        path="/inserate/meine_inserate"
        index
        element={<AssignmentsContainer topTab="myAdvertisements" subTab="" />}
      />
      <Route
        path="/inserate/follower"
        index
        element={<AssignmentsContainer topTab="follower" subTab="" />}
      />
      <Route
        path="/inserate/trending"
        index
        element={<AssignmentsContainer topTab="trending" subTab="" />}
      />
      <Route
        path="/inserate/angefragt"
        index
        element={<AssignmentsContainer topTab="for_you" subTab="requested" />}
      />
      {isInserateDetails ? (
        <Route path="/inserate/:id" index element={<AssignmentsDetails />} />
      ) : (
        <Route path="/inserate/:id" element={<Navigate to="/inserate/für_dich" replace />} />
      )}
      <Route path="/jobs" element={<Navigate to="/jobs/für_dich" replace />} />
      <Route path="/jobs/für_dich" index element={<JobsContainer tab="for_you" />} />
      <Route path="/jobs/meine_jobs" element={<Navigate to="/jobs/meine_jobs/übersicht" />} />
      <Route
        path="/jobs/meine_jobs/übersicht"
        index
        element={<JobsContainer tab="my_jobs" subTab="overview" />}
      />
      <Route
        path="/jobs/meine_jobs/nachrichten"
        index
        element={<JobsContainer tab="my_jobs" subTab="news" />}
      />
      <Route
        path="/jobs/meine_jobs/werdegänge"
        index
        element={<JobsContainer tab="my_jobs" subTab="career" />}
      />
      <Route
        path="/jobs/meine_jobs/statistik"
        index
        element={<JobsContainer tab="my_jobs" subTab="statistics" />}
      />
      <Route
        path="/jobs/jobs_erstellen"
        element={<Navigate to="/verwaltung/jobs/jobs_erstellen" replace />}
      />
      <Route
        path="/verwaltung/jobs/jobs_erstellen"
        index
        element={<JobsContainer tab="create" subTab="" />}
      />
      <Route path="/jobs/follower" index element={<JobsContainer tab="follower" subTab="" />} />
      <Route path="/jobs/trending" index element={<JobsContainer tab="trending" subTab="" />} />
      <Route path="/jobs/reviews/:id" index element={<JobReviewsContainer />} />
      {isJobsDetails ? (
        <Route path="/jobs/:id" index element={<JobsDetails />} />
      ) : (
        <Route path="/jobs/:id" element={<Navigate to="/jobs/für_dich" replace />} />
      )}
      <Route path="/entdecken" element={<Navigate to="/entdecken/hashtags/feed" replace />} />
      <Route
        path="/entdecken/hashtags"
        element={<Navigate to="/entdecken/hashtags/feed" replace />}
      />
      <Route
        path="/entdecken/hashtags/feed"
        index
        element={<DiscoverContainer tab="hashtags" subTab="feed" />}
      />
      <Route
        path="/entdecken/hashtags/watch"
        index
        element={<DiscoverContainer tab="hashtags" subTab="watch" />}
      />
      <Route path="/entdecken/watch" index element={<DiscoverContainer tab="watch" />} />
      <Route path="/entdecken/sounds" index element={<DiscoverContainer tab="sounds" />} />
      <Route path="/entdecken/news" index element={<DiscoverContainer tab="news" />} />
      <Route
        path="/entdecken/profile"
        element={<Navigate to="/entdecken/profile/alle" replace />}
      />
      <Route
        path="/entdecken/profile/alle"
        index
        element={<DiscoverContainer tab="profiles" subTab="" />}
      />
      <Route
        path="/entdecken/profile/personen"
        index
        element={<DiscoverContainer tab="profiles" subTab="person" />}
      />
      <Route
        path="/entdecken/profile/firmen"
        index
        element={<DiscoverContainer tab="profiles" subTab="company" />}
      />
      <Route
        path="/entdecken/profile/vereine"
        index
        element={<DiscoverContainer tab="profiles" subTab="club" />}
      />
      <Route
        path="/entdecken/profile/gruppen"
        index
        element={<DiscoverContainer tab="profiles" subTab="group" />}
      />
      <Route path="/entdecken/jobs" index element={<DiscoverContainer tab="jobs" />} />
      <Route path="/entdecken/inserate" index element={<DiscoverContainer tab="assignments" />} />
      <Route path="/entdecken/marketplace" index element={<DiscoverContainer tab="ads" />} />
      <Route path="/bookmarks" element={<Navigate to="/bookmarks/watch" replace />} />
      <Route path="/bookmarks/watch" index element={<BookmarksContainer tab="watch" />} />
      <Route path="/bookmarks/sounds" index element={<BookmarksContainer tab="sounds" />} />
      <Route path="/bookmarks/feed" index element={<BookmarksContainer tab="feed" />} />
      <Route
        path="/bookmarks/inserate"
        index
        element={<BookmarksContainer tab="advertisements" />}
      />
      <Route path="/bookmarks/jobs" index element={<BookmarksContainer tab="jobs" />} />
      <Route
        path="/bookmarks/marketplace"
        index
        element={<BookmarksContainer tab="marketplace" />}
      />
      <Route path="/" element={<Navigate to="/news" />} />
      <Route path="/news" element={<Navigate to="/news/für_dich" />} />
      <Route path="/news/für_dich" index element={<NewsContainer filter="toYou" />} />
      <Route path="/news/mein_feed" index element={<NewsContainer filter="myFeed" />} />
      <Route path="/news/following" index element={<NewsContainer filter="following" />} />
      <Route path="/news/trending" index element={<NewsContainer filter="trending" />} />
      <Route path="/news/erstellen" index element={<CreatePost />} />
      <Route path="/news/create" index element={<Navigate to="/news/erstellen" replace />} />
      <Route path="/Job-Market/Stellenanzeigen" element={<Navigate to="/jobs" replace />} />
      <Route path="/login" index element={<LoginContainer />} />
      <Route path="/logout" index element={<LogoutContainer />} />
      <Route path="/verwaltung/passwort" index element={<RequestPasswordResetContainer />} />
      <Route path="/password_reset" index element={<ResetPasswordContainer />} />
      <Route path="/changepassword" index element={<ChangePasswordContainer />} />
      <Route path="/kontakte" index element={<ContactsContainer tab="for_you" subTab="all" />} />
      <Route
        path="/kontakte/für_dich"
        index
        element={<ContactsContainer tab="toYou" subTab="all" />}
      />
      <Route
        path="/kontakte/für_dich/alle"
        index
        element={<ContactsContainer tab="toYou" subTab="all" />}
      />
      <Route
        path="/kontakte/für_dich/personen"
        index
        element={<ContactsContainer tab="toYou" subTab="person" />}
      />
      <Route
        path="/kontakte/für_dich/firmen"
        index
        element={<ContactsContainer tab="toYou" subTab="company" />}
      />
      <Route
        path="/kontakte/für_dich/vereine"
        index
        element={<ContactsContainer tab="toYou" subTab="club" />}
      />
      <Route
        path="/kontakte/meine_kontakte/übersicht"
        index
        element={<ContactsContainer tab="myContacts" subTab="allMyContacts" />}
      />
      <Route
        path="/kontakte/meine_kontakte/angefragt"
        index
        element={<ContactsContainer tab="myContacts" subTab="requested" />}
      />
      <Route
        path="/kontakte/meine_kontakte/blocked"
        index
        element={<ContactsContainer tab="myContacts" subTab="blocked" />}
      />
      <Route
        path="/kontakte/meine_kontakte/ausstehend"
        index
        element={<ContactsContainer tab="myContacts" subTab="pending" />}
      />
      <Route
        path="/kontakte/meine_kontakte/geburtstage"
        index
        element={<ContactsContainer tab="myContacts" subTab="birthdays" />}
      />
      <Route
        path="/kontakte/meine_kontakte/statistik"
        index
        element={<ContactsContainer tab="myContacts" subTab="statistics" />}
      />
      <Route path="/kontakte/firmen" index element={<ContactsContainer subTab="company" />} />
      <Route
        path="/kontakte/birthdays"
        index
        element={<ContactsContainer tab="meine_kontakte" subTab="birthdays" />}
      />
      <Route path="/kontakte/follower" index element={<ContactsContainer tab="followers" />} />
      <Route path="/kontakte/following" index element={<ContactsContainer tab="following" />} />
      <Route path="/kontakte/trending" index element={<ContactsContainer tab="trending" />} />
      <Route path="/kontakte/:id/follower" element={<Navigate to="/kontakte/follower" replace />} />
      <Route
        path="/kontakte/:id/following"
        element={<Navigate to="/kontakte/following" replace />}
      />
      <Route path="/watch" element={<Navigate to="/watch/für_dich" replace />} />
      {!isApp && <Route path="/watch/für_dich" index element={<WatchContainer tab="toYou" />} />}
      <Route path="/watch/mein_watch" index element={<WatchContainer tab="myWatch" />} />
      <Route path="/watch/video_erstellen" element={<Navigate to="/watch/erstellen" replace />} />
      <Route path="/watch/create" element={<Navigate to="/watch/erstellen" replace />} />
      <Route
        path="/watch/erstellen"
        index
        element={<CreateWatchContainer defaultTab={isMobile ? "edit" : "basics"} />}
      />
      <Route path="/watch/following" index element={<WatchContainer tab="following" />} />
      <Route path="/watch/trending" index element={<WatchContainer tab="trending" />} />
      {!isMobile && <Route path="/messenger" index element={<MessengerContainer />} />}
      <Route path="/profile/edit" element={<Navigate to="/verwaltung/profile" replace />} />
      <Route path="/verwaltung/profile" index element={<ProfileEditContainer />} />
      <Route
        path="/verwaltung/account"
        element={<Navigate to="/verwaltung/account/pushmessages" replace />}
      />
      <Route
        path="/verwaltung/account/pushmessages"
        index
        element={<AccountEditContainer tab="pushmessages" />}
      />
      <Route
        path="/verwaltung/account/passwort"
        index
        element={<AccountEditContainer tab="password" />}
      />
      <Route
        path="/verwaltung/account/email"
        index
        element={<AccountEditContainer tab="email" />}
      />
      <Route
        path="/verwaltung/account/username"
        index
        element={<AccountEditContainer tab="username" />}
      />
      <Route
        path="/verwaltung/account/pushmessages"
        index
        element={<AccountEditContainer tab="pushmessages" />}
      />
      <Route
        path="/verwaltung/account/logout"
        index
        element={<AccountEditContainer tab="logout" />}
      />
      <Route
        path="/verwaltung/account/löschen"
        index
        element={<AccountEditContainer tab="löschen" />}
      />
      <Route path="/verwaltung/lebenslauf" index element={<PrintContainer />} />
      <Route path="/profile/:userId" index element={<ProfileContainer />} />
      <Route path="/profile/:userId/über_uns" element={<ProfileContainer filter="about" />} />
      <Route path="/profile/:userId/über_mich" element={<ProfileContainer filter="about" />} />
      <Route path="/profile/:userId/about" element={<ProfileContainer filter="about" />} />
      <Route path="/profile/:userId/news" element={<ProfileContainer filter="news" />} />
      <Route path="/profile/:userId/feed" element={<ProfileContainer filter="news" />} />
      <Route path="/profile/:userId/watch" element={<ProfileContainer filter="watch" />} />
      <Route path="/profile/:userId/jobs" element={<ProfileContainer filter="jobs" />} />
      <Route path="/profile/:userId/shop" element={<ProfileContainer filter="ads" />} />
      <Route path="/profile/:userId/likes" element={<ProfileContainer filter="likes" />} />
      <Route
        path="/profile/:userId/feed_likes"
        element={<ProfileContainer filter="feed_likes" />}
      />
      <Route
        path="/profile/:userId/watch_likes"
        element={<ProfileContainer filter="watch_likes" />}
      />
      <Route path="/profile/:userId/follows" element={<ProfileContainer filter="follows" />} />
      <Route path="/profile/:userId/following" element={<ProfileContainer filter="following" />} />
      <Route
        path="/profile/:userId/assignments"
        element={<Navigate to="/profile/:userId/inserate" replace />}
      />
      <Route path="/profile/:userId/inserate" element={<ProfileContainer filter="assignments" />} />
      <Route path="/marketplace" element={<Navigate to="/marketplace/für_dich" replace />} />
      <Route
        path="/verwaltung/mein_shop"
        element={<Navigate to="/verwaltung/mein_shop/übersicht" replace />}
      />
      <Route
        path="/marketplace/stats"
        element={<Navigate to="/verwaltung/mein_shop/statistik" replace />}
      />
      <Route
        path="/marketplace/create"
        element={<Navigate to="/verwaltung/mein_shop/verkaufen" replace />}
      />
      <Route
        path="/marketplace/wishlist"
        element={<Navigate to="/marketplace/wunschliste" replace />}
      />
      <Route path="/account/deaktivieren" index element={<AccountDeactivationPage />} />
      <Route path="/tags/:hashtag" index element={<HashtagContainer tab="feed" />} />
      <Route path="/tags/:hashtag/watch" index element={<HashtagContainer tab="watch" />} />
      <Route path="/tags/:hashtag/feed" index element={<HashtagContainer tab="feed" />} />
      <Route path="/impressum" index element={<LegalNoticeContainer />} />
      <Route path="/info" index element={<InfoContainer />} />
      <Route path="/marketing" index element={<MarketingContainer />} />
      <Route path="/agb" index element={<ConditionsContainer />} />
      <Route path="/datenschutzrichtlinie" index element={<PrivacyContainer />} />
      <Route path="/urheberrecht" index element={<CopyRightContainer />} />
      <Route path="/datenschutz" index element={<PrivacyContainer />} />
      <Route path="/cookies" index element={<CookiesContainer />} />
      <Route path="/nutzungsbedingungen" index element={<TermsOfUseContainer />} />
      <Route path="/community" index element={<CommunityRulesContainer />} />
      <Route path="/help" index element={<HelpContainer />} />
      <Route path="/mehr" index element={<MoreContainer />} />
      <Route path="/industriegeschichte" index element={<IndustrialHistory />} />
      <Route path="/industriegeschichte/:id" index element={<IndustrialHistoryDetails />} />
      <Route path="/global_agieren" index element={<ActGlobal />} />
      <Route path="/account_erstellen" index element={<CreateAccount />} />
      <Route path="/startup_tipps" index element={<StartupAdvice />} />
      <Route path="/nachfolger" index element={<Successor />} />
      <Route path="/kunst_kultur" index element={<LeisureMaps />} />
      <Route path="/amts_gemeindeblätter" index element={<Newspapers />} />
      <Route path="/marken_patentrecht" index element={<Copyright />} />
      <Route path="/watch_faq" index element={<WatchFAQ />} />
      <Route path="/mitglieder_faq" index element={<MemberFAQ />} />
      <Route path="/firmen_faq" index element={<CompanyFAQ />} />
      <Route path="/spendenaktionen" index element={<Donations />} />
      <Route path="/newsletter" index element={<Newsletter />} />
      <Route path="/newsletter_abmelden" index element={<Unsubscripe />} />
      <Route path="/messe_kalender" index element={<EventCalendar />} />
      <Route path="/support" index element={<Support />} />
      <Route path="/partner" index element={<Partner />} />
      <Route path="/glossar" index element={<GlossaryContainer />} />
      <Route path="/activate" index element={<ActivateContainer />} />
      <Route path="/gruppen/" element={<Navigate to="/gruppen/für_dich" replace />} />
      <Route
        path="/gruppen/für_dich"
        index
        element={<GroupsContainer tab="toYou" subTab="toYouAll" />}
      />
      <Route
        path="/gruppen/für_dich/alle"
        index
        element={<GroupsContainer tab="toYou" subTab="toYouAll" />}
      />
      <Route
        path="/gruppen/für_dich/angefragt"
        index
        element={<GroupsContainer tab="toYou" subTab="requested" />}
      />
      <Route
        path="/gruppen/für_dich/ausstehend"
        index
        element={<GroupsContainer tab="toYou" subTab="pending" />}
      />
      <Route
        path="/gruppen/für_dich/statistik"
        index
        element={<GroupsContainer tab="toYou" subTab="statistics" />}
      />
      <Route path="/gruppen/meine_gruppen" index element={<GroupsContainer tab="myGroups" />} />
      <Route path="/gruppen/create" element={<Navigate to="/gruppen/erstellen" replace />} />
      <Route path="/gruppen/erstellen" index element={<GroupsContainer tab="create" />} />
      <Route path="/gruppen/erstellen" index element={<GroupsContainer tab="create" />} />
      <Route path="/gruppen/following" index element={<GroupsContainer tab="following" />} />
      <Route path="/gruppen/trending" index element={<GroupsContainer tab="trending" />} />
      <Route path="/gruppen/:userId" index element={<GroupDetailsContainer />} />
      <Route path="/registrierung" index element={<RegisterContainer />} />
      <Route path="/social-login-erfolg" index element={<SocialAuthSuccessContainer />} />
      <Route path="/benachrichtigungen" index element={<NotificationsContainer tab="all" />} />
      <Route path="/benachrichtigungen/alle" index element={<NotificationsContainer tab="all" />} />
      <Route
        path="/benachrichtigungen/follower"
        index
        element={<NotificationsContainer tab="follower" />}
      />
      <Route
        path="/benachrichtigungen/freunde"
        index
        element={<NotificationsContainer tab="friends" />}
      />
      <Route
        path="/benachrichtigungen/antworten"
        index
        element={<NotificationsContainer tab="answers" />}
      />
      <Route
        path="/benachrichtigungen/erwähnungen"
        index
        element={<NotificationsContainer tab="mentions" />}
      />
      <Route
        path="/benachrichtigungen/zitate"
        index
        element={<NotificationsContainer tab="quotes" />}
      />
      <Route
        path="/benachrichtigungen/reposts"
        index
        element={<NotificationsContainer tab="reposts" />}
      />
      <Route
        path="/benachrichtigungen/verifiziert"
        index
        element={<NotificationsContainer tab="verified" />}
      />
      <Route
        path="/benachrichtigungen/artikel"
        index
        element={<NotificationsContainer tab="articles" />}
      />
      <Route
        path="/benachrichtigungen/inserate"
        index
        element={<NotificationsContainer tab="inserate" />}
      />
      <Route
        path="/benachrichtigungen/jobs"
        index
        element={<NotificationsContainer tab="jobs" />}
      />
      <Route path="/Mein-Account/nachrichten" element={<Navigate to="/messenger" replace />} />
      <Route path="/Firmenverzeichnis" element={<Navigate to="/contacts/firmen" replace />} />
      <Route path="/Deine-Auftragsplattform" element={<Navigate to="/inserate" replace />} />
      <Route path="/Online-Market" element={<Navigate to="/marketplace" replace />} />
      <Route path="/Ueber-uns" element={<Navigate to="/info" replace />} />
      <Route path="/Tradefoox-Funktionen-So-geht`s" element={<Navigate to="/help" replace />} />
      <Route path="/Mediadaten-Tradefoox" element={<Navigate to="/marketing" replace />} />
      <Route path="/tradefoox-ads" index element={<AdvertisementContainer />} />
      <Route path="/werbung-erstellen" index element={<CreateAd />} />
    </>
  );

  useEffect(() => {
    if (me.isLoading) return;
    if (user === null) {
      loginModal.open();
    } else {
      loginModal.close();
      if (!user.payment_active && !paymentModal.isOpen) {
        paymentModal.open();
      }
    }
  }, [user, location]);

  return (
    <SentryRoutes>
      {isApp && <Route path="/watch/für_dich" index element={<WatchMobilePage tab="for_you" />} />}

      {isMobile && (
        <Route path={"/messenger"} element={<MobileMainWrapper />}>
          <Route path="" index element={<MessengerContainer />} />
        </Route>
      )}

      {useFullscreenWrapper && (
        <Route
          path="/"
          element={
            <FullscreenMainWrapper
              darkHeader={darkHeader}
              darkFooter={darkFooter}
              showCart={showCart}
              sidebarButtonVisible={sidebarButtonVisible}
              smallSideBar={smallSideBar}
              showBG={showBG}
            />
          }
        >
          {routes}
        </Route>
      )}

      <Route
        path="/"
        element={
          <MainWrapper
            topNavHidden={topNavHidden}
            darkFooter={darkFooter}
            borderless={borderless}
            fullscreen={fullscreen}
          />
        }
      >
        {routes}
        <Route path="*" element={<Navigate to="/news" replace={true} />} />
      </Route>
    </SentryRoutes>
  );
};

export default Router;

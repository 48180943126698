import Panel from "@src/components/box/Panel";
import AdMedia from "@src/components/elements/advertisement/form/AdMedia";
import Preview from "@src/components/elements/advertisement/form/shared/Preview";
import Button from "@src/components/elements/input/Button";
import CalendarPlanner from "@src/components/elements/input/CalendarPlanner";
import Checkbox from "@src/components/elements/input/Checkbox";
import CheckboxGroup from "@src/components/elements/input/CheckboxGroup";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import PostTextEdit from "@src/components/elements/posts/form/shared/PostTextEdit";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import BackButton from "@src/components/nav/BackButton";
import isMobile from "@src/utils/isMobile";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  onSuccess?: () => void;
  onClose?: () => void;
};

const CreateAd = ({ onSuccess, onClose }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [adult, setAdult] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");

  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);

  const [selectedGroup, setSelectedGroup] = useState<string>("video");
  const mediaGroups = [
    {
      name: "video",
      fileFormats: "video/*",
      label: t("main.navLinks.createAd.videoCampaign"),
      buttons: [
        "feed",
        "watch",
        "jobs",
        "assignments",
        "messenger",
        "marketplace",
        "discover",
        "bookmarks",
        "contacts",
      ],
    },
    {
      name: "image/text",
      fileFormats: "image/*,application/pdf",
      label: t("main.navLinks.createAd.textAndImageCampaign"),
      buttons: [
        "feed",
        "watch",
        "jobs",
        "assignments",
        "messenger",
        "marketplace",
        "discover",
        "bookmarks",
        "contacts",
      ],
    },
    {
      name: "image/text/video",
      fileFormats: "image/*,application/pdf,video/*",
      label: t("main.navLinks.createAd.textAndImageAndVideoCampaign"),
      buttons: [
        "feed",
        "watch",
        "jobs",
        "assignments",
        "messenger",
        "marketplace",
        "discover",
        "bookmarks",
        "contacts",
      ],
    },
  ];
  const areaListDefault = [
    {
      checked: false,
      label: t("main.header.links.advertisements"),
      name: "assignments",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.header.links.messenger.title"),
      name: "messenger",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.header.links.marketplace"),
      name: "marketplace",
      fileFormats: "image/*,video/*,application/pdf",
    },
    // why is advertisements in here twice?
    {
      checked: false,
      label: t("main.header.links.advertisements"),
      name: "assignments",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.header.links.feed"),
      name: "feed",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.header.links.watch"),
      name: "watch",
      fileFormats: "video/*",
      title: "Nur Videos",
    },
    {
      checked: false,
      label: t("main.header.links.jobs"),
      name: "jobs",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.navLinks.discover"),
      name: "discover",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.navLinks.watchLists"),
      name: "bookmarks",
      fileFormats: "image/*,video/*,application/pdf",
    },
    {
      checked: false,
      label: t("main.header.links.contacts"),
      name: "contacts",
      fileFormats: "image/*,video/*,application/pdf",
    },
  ];
  const [areaList, setAreaList] = useState<
    Array<{
      checked?: boolean;
      label?: string;
      title?: string;
      name?: string;
      fileFormats?: string;
    }>
  >(areaListDefault);

  const [selectedDevice, setSelectedDevice] = useState<string>("desktop");

  const formatList = [
    { name: "Full size 600x400 px", value: "full", fileLimit: 1 },
    { name: "Half size 300x400 px", value: "half", fileLimit: 1 },
    { name: "Carousel 6x100x200 px", value: "carousel", fileLimit: 6 },
    { name: "Long size 200x400 px", value: "long", fileLimit: 1 },
  ];
  const [selectedFormat, setSelectedFormat] = useState<{
    name: string;
    value: string;
    fileLimit: number;
  }>(formatList[0]);

  const callToActionList = [
    { name: "Mehr Anzeigen", value: "show_more" },
    { name: "Folgen", value: "follow" },
    { name: "Download", value: "download" },
    { name: "Kontaktieren", value: "contact" },
    { name: "Zur Website", value: "website" },
    { name: "Jetzt kaufen", value: "buy" },
    { name: "Zum Shop", value: "shop" },
  ];
  const [selectedCTA, setSelectedCTA] = useState<
    Array<{
      name: string;
      value: string;
    }>
  >([callToActionList[0]]);

  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>();
  const [productLinks, setproductLinks] = useState<Array<string>>([""]);
  const [keywords, setKeywords] = useState("");
  return (
    <Panel mobileBorder={false}>
      <BackButton stepback className={tw`ml-auto`} />

      <div css={[tw` gap-2 `]}>
        <div className="flex items-center gap-5 font-semibold">
          {t("main.navLinks.createAd.createAd")}
        </div>
        <PostUserSelect />
        <Input
          name="title"
          placeholder={t("main.navLinks.createAd.nameNotShown")}
          onChange={e => setTitle(e)}
          value={title}
          clearable
        />
        <div className="grid gap-2 xl:grid-cols-2">
          <Input
            name="start_date"
            label={t("main.navLinks.createAd.startdate")}
            onChange={e => {
              setStartDate(new Date(e));
            }}
            type="datetime-local"
            value={startDate ? moment(startDate).format().substring(0, 16) : ""}
          />
          <Input
            name="end_date"
            label={t("main.navLinks.createAd.endDate")}
            onChange={e => {
              setEndDate(new Date(e));
            }}
            type="datetime-local"
            value={endDate ? moment(endDate).format().substring(0, 16) : ""}
          />
        </div>
        <div className="text-sm font-semibold">
          {t("main.navLinks.createAd.weeklyPlanning")}
          <CalendarPlanner />
        </div>
        <div className="flex flex-row items-center gap-3">
          <Input
            name="budget"
            label={t("main.navLinks.createAd.budged")}
            placeholder="100 €"
            onChange={e => setBudget(e)}
            type="number"
            value={budget}
          />
          <div>
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => {
                console.debug("payment manager popup");
              }}
            >
              {t("main.navLinks.createAd.payment")}
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-row gap-3">
          {mediaGroups.map(group => {
            return (
              <div key={group.label} className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2 text-sm">
                  <input
                    className="size-4 rounded-full border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
                    type="radio"
                    name="mediagroup"
                    value={group.name}
                    checked={selectedGroup === group.name}
                    onChange={e => {
                      setMedia([]);
                      setSelectedGroup(e.target.value);
                      setAreaList(
                        areaListDefault.filter(area => {
                          return group.buttons.includes(area.name ?? "");
                        }),
                      );
                    }}
                  />
                  {group.label}
                </div>
                {selectedGroup === group.name && (
                  <CheckboxGroup
                    name="areas"
                    items={areaList.filter(area => {
                      return group.buttons.includes(area.name ?? "");
                    })}
                    onChange={(e: Array<{ checked?: boolean; label?: string }>) => {
                      setAreaList(e);
                    }}
                    itemStyle="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-2 sm:gap-x-4 md:gap-x-6 lg:gap-x-8 gap-y-0"
                  />
                )}
              </div>
            );
          })}
        </div>
        <Select
          name="format"
          label={t("main.navLinks.createAd.adFormat")}
          items={formatList}
          onChange={e => {
            const newFormat =
              formatList[
                formatList.findIndex(item => {
                  return item.value === e.target.value;
                })
              ];
            setSelectedCTA(Array(newFormat.fileLimit).fill(callToActionList[0]));
            setSelectedFormat(newFormat);
          }}
        />
        <div className="mb-2 flex flex-row gap-8">
          <div className="flex flex-row items-center gap-2 text-sm">
            <input
              type="radio"
              name="device_group"
              className="size-4 rounded-full border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
              value={selectedDevice}
              checked={selectedDevice === "desktop"}
              onChange={() => setSelectedDevice("desktop")}
            />
            {t("main.navLinks.createAd.desktop")}
          </div>
          <div className="flex flex-row items-center gap-2 text-sm">
            <input
              type="radio"
              name="device_group"
              className="size-4 rounded-full border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
              value={selectedDevice}
              checked={selectedDevice === "mobile"}
              onChange={() => setSelectedDevice("mobile")}
            />
            {t("main.navLinks.createAd.mobile")}
          </div>
        </div>
        <Preview format={selectedFormat.value} device={selectedDevice} />
        <AdMedia
          strictLimit
          onChange={data => setMedia(value => [...value, data])}
          media={media}
          onDelete={id => setMedia(data => data.filter(item => item.id !== id))}
          fileLimit={selectedFormat?.fileLimit}
          filetypes={
            mediaGroups[
              mediaGroups.findIndex(group => {
                return group.name === selectedGroup;
              })
            ].fileFormats
          }
        />
        {media.length > 0 && (
          <div className="ml-auto w-fit">
            <Button variant={Button.Variants.Transparent} onClick={() => console.debug("preview")}>
              {t("main.navLinks.createAd.preview")}
            </Button>
          </div>
        )}
        {Array(selectedFormat?.fileLimit ?? 1)
          .fill(0)
          .map((_, index: number) => {
            return (
              <div className="grid grid-cols-[1fr,auto] items-end gap-4" key={index}>
                <Input
                  name="product_link"
                  label={t("main.navLinks.createAd.link")}
                  placeholder="www.example.com"
                  value={productLinks[index]}
                  onChange={e => {
                    const newProductLinks = [...productLinks];
                    newProductLinks[index] = e;
                    setproductLinks(newProductLinks);
                  }}
                  clearable
                />
                <Select
                  name="call_to_action"
                  label={t("main.navLinks.createAd.action")}
                  items={callToActionList}
                  value={selectedCTA[index].value}
                  onChange={e => {
                    const newSelectedCTA = [...selectedCTA];
                    newSelectedCTA[index] =
                      callToActionList[
                        callToActionList.findIndex(item => {
                          return item.value === e.target.value;
                        })
                      ];
                    setSelectedCTA(newSelectedCTA);
                  }}
                />
              </div>
            );
          })}
        <Input
          name="keywords"
          label={t("main.groupsInfoForm.keywords")}
          value={keywords}
          placeholder="Kampagnenkeywords"
          onChange={e => setKeywords(e)}
          clearable
        />
        <Checkbox name="18+" checked={adult} onChange={checked => setAdult(checked)}>
          {t("main.watch.create.adult")}
        </Checkbox>
        <PostTextEdit value={text} onChange={setText} autofocus={false} />
        <div className="flex w-full flex-col justify-end gap-x-4 gap-y-2 lg:flex-row">
          <Button
            variant={Button.Variants.Transparent}
            onClick={() => {
              navigate("/tradefoox-ads");
              onClose && onClose();
            }}
            fitWidth={!isMobile}
          >
            {t("buttons.cancel")}
          </Button>
          <Button variant={Button.Variants.Transparent} fitWidth={!isMobile}>
            {t("buttons.duplicate")}
          </Button>

          <Button
            variant={Button.Variants.Transparent}
            onClick={() => console.debug("save")}
            fitWidth={!isMobile}
          >
            {t("buttons.save")}
          </Button>
          <Button onClick={() => console.debug("publish")} fitWidth={!isMobile}>
            {t("buttons.publish")}
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default CreateAd;

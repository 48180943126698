import { StatusBar, Style } from "@capacitor/status-bar";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { AppContext } from "@src/App";
import api from "@src/api/api";
import NavBarBottom from "@src/components/nav/NavBarBottom";
import WatchMobileItem from "@src/pages/watch/WatchMobileItem";
import { useAppSelector } from "@src/state/hooks";
import WatchType from "@src/utils/types/watch/WatchType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  tab?: string;
};

type Response = {
  data: Array<WatchType>;
  current_page: number;
  total_pages: number;
};

const WatchMobilePage = ({ tab = "for_you" }: Props) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  if (appContext?.setTopNavHidden) appContext.setTopNavHidden(true);
  if (appContext?.setDarkTheme) appContext.setDarkTheme(true);

  const [searchParams] = useSearchParams();
  const [muted, setMuted] = useState(true);
  const [activePost, setActivePost] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useAppSelector(state => state.user);

  StatusBar.setStyle({ style: Style.Dark });

  const posts = useInfiniteQuery<Response>(
    ["watch", "posts", tab, searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      let queryString = "";
      if (searchQuery) queryString = `&q=${searchQuery}`;
      if (user && tab === "my_watch") queryString = `&user_id=${user.id}`;
      if (tab === "following") queryString = "&following=true";
      const { data } = await api.get(`/api/v1/watch?page=${pageParam}&${queryString}`);
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const count = (posts.isSuccess && posts.data.pages.length * 30) || 0;

  if (activePost > count - 5 && posts.hasNextPage) posts.fetchNextPage();

  return (
    <div className="h-dvh w-full bg-black">
      <div
        className="watch-snap-scroll fixed left-0 top-0 mx-auto grid h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] w-screen snap-y snap-mandatory grid-cols-1 overflow-y-scroll bg-black scrollbar-none"
        onScroll={e => {
          if (e.currentTarget.scrollTop === 0 && activePost !== 0) return setActivePost(0);
          setActivePost(Math.round(e.currentTarget.scrollTop / e.currentTarget.clientHeight));
        }}
      >
        {posts.isSuccess &&
          posts.data.pages.map(
            page =>
              page &&
              page.data.map((watch, i) => {
                return (
                  <WatchMobileItem
                    queryKey={["watch", "posts", tab, searchQuery, searchParams.get("featured")]}
                    key={`${watch.post_id}-${watch.media_id}`}
                    data={watch}
                    active={i === activePost}
                    preload={activePost + 3 > i && activePost - 3 < i}
                    autoplay={true}
                    muted={muted}
                    setMuted={(state: boolean) => setMuted(state)}
                  />
                );
              }),
          )}
      </div>
      <NavBarBottom dark={true} />
      <div css={[tw`fixed w-screen left-0 top-[env(safe-area-inset-top)] text-gray-600 px-4`]}>
        <div className="flex justify-between">
          <NavLink to="/news/">
            <ChevronLeftIcon className="size-5 text-gray-400" />
          </NavLink>
          <NavLink to="/watch/mein_watch">
            {({ isActive }) => (
              <span css={[isActive ? tw`border-b-2 text-gray-200` : tw`text-gray-400`]}>
                {t("main.watchTabs.myWatch")}
              </span>
            )}
          </NavLink>
          <NavLink to="/watch/für_dich">
            {({ isActive }) => (
              <span css={[isActive ? tw`border-b-2 text-gray-200` : tw`text-gray-400`]}>
                {t("main.watchTabs.toYou")}
              </span>
            )}
          </NavLink>
          <NavLink to="/watch/following">
            {({ isActive }) => (
              <span css={[isActive ? tw`border-b-2 text-gray-200` : tw`text-gray-400`]}>
                {t("main.watchTabs.following")}
              </span>
            )}
          </NavLink>
          <NavLink to="/watch/trending">
            {({ isActive }) => (
              <span css={[isActive ? tw`border-b-2 text-gray-200` : tw`text-gray-400`]}>
                {t("main.watchTabs.trending")}
              </span>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default WatchMobilePage;

import Assignment from "@src/components/elements/Assignment";

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Assignments = ({ searchQuery, showPlaceholder }: Props) => {
  const assignments: Array<{
    id: string;
    title: string;
    city: string;
    created_at: string;
    start_at: string;
  }> = [
    {
      id: "0",
      title: "Kfz-Mechaniker",
      city: "Chemnitz",
      created_at: new Date(Date.now()).toDateString(),
      start_at: new Date(Math.random() * 123456789000 + Date.now()).toString(),
    },
  ];

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {assignments.map(assignment => (
          <Assignment
            id={assignment.id}
            key={assignment.id}
            title={assignment.title}
            city={assignment.city}
            created_at={assignment.created_at}
            start_at={assignment.start_at}
          />
        ))}
      </div>
    </>
  );
};

export default Assignments;

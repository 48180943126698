import api from "@src/api/api";
import { AxiosResponse } from "axios";

export type PasswordReset = {
  request_id: string;
  password: string;
};

async function resetPassword(request: PasswordReset): Promise<AxiosResponse> {
  return await api.post("/api/v1/auth/password_reset", request);
}

export default resetPassword;

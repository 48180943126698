import login from "@src/api/auth/login";
import Alert from "@src/components/elements/Alert";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import LoginExternal from "@src/components/popup/LoginExternal";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { setToken, setUser } from "@src/state/user/userSlice";
import { trackFormSubmission } from "@src/utils/hubspot";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const LoginPopup = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [tab, setTab] = useState("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginModal = useLoginModal();
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const loginMutation = useMutation(["login"], {
    mutationFn: () => {
      trackFormSubmission("loginForm");
      return login({
        email,
        password,
      });
    },
    onSuccess: loginData => {
      dispatch(setUser(loginData.user));
      dispatch(setToken(loginData.token));
      setTimeout(
        () => navigate(loginData.user.start_page ? "/" + loginData.user.start_page : "/news"),
        200,
      );
      queryClient.invalidateQueries({ queryKey: ["me"] });
      loginModal.close();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message={t("main.alerts.loginError")}
          title={t("main.signIn.login")}
        />,
      );
    },
  });

  return (
    <PopupWrapper onClose={() => loginModal.close()}>
      <div className="w-full md:max-w-[310px]">
        {tab === "login" && (
          <>
            <form
              onSubmit={e => {
                e.preventDefault();
                if (!loginMutation.isLoading) loginMutation.mutate();
              }}
              id="loginForm"
              className="grid grid-flow-row justify-center gap-1 text-center text-black"
            >
              <div className="text-lg font-semibold text-darkblue lg:text-xl lg:font-medium">
                {t("main.signIn.title1")}
                <br /> {t("main.signIn.title2")}
              </div>
              <Input
                name="email"
                label={t("main.signIn.email")}
                value={email}
                placeholder={t("main.signIn.placeholderEmail")}
                type="email"
                onChange={e => setEmail(e)}
              />
              <Input
                name="password"
                label={t("main.signIn.password")}
                value={password}
                placeholder={t("main.signIn.password")}
                type="password"
                onChange={e => setPassword(e)}
              />
              <Button
                submit
                size={Button.Sizes.Large}
                onClick={() => {
                  if (!loginMutation.isLoading) loginMutation.mutate();
                }}
              >
                {t("main.signIn.login")}
              </Button>
              <LoginExternal onClose={() => setTab("login")} />
              <div className="text-sm text-gray-700">{t("main.signIn.description")}</div>
              <Button
                size={Button.Sizes.Large}
                onClick={() => {
                  loginModal.close();
                  navigate("/registrierung");
                }}
                variant={Button.Variants.Cyan}
              >
                {t("main.signIn.createAccount")}
              </Button>
              <AppLink />
              <div className="flex justify-center gap-2">
                <NavLink to="/impressum" className="text-sm">
                  {t("main.signIn.imprint")}
                </NavLink>
                <span className="text-sm">|</span>
                <NavLink to="/datenschutz" className="text-sm">
                  {t("main.signIn.dataProtection")}
                </NavLink>
              </div>
            </form>
          </>
        )}
        {tab === "options" && <LoginExternal onClose={() => setTab("login")} />}
      </div>
    </PopupWrapper>
  );
};

export default LoginPopup;

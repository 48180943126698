import {
  ArrowUturnRightIcon,
  ChatBubbleLeftEllipsisIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import api from "@src/api/api";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = { userId: string; type?: string };
const ContactContextMenu = ({ userId, type }: Props) => {
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/chats/create/${userId}`);
      return res.data;
    },
    onSuccess: data => {
      navigate("/messenger", { state: { chatId: data.data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const block = useMutation([`block-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connect = useMutation([`contacts-request-${userId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${userId}/connect`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + userId],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  const checkUserType = (userType: string) => {
    if (user) {
      return userType === user.type;
    } else {
      return false;
    }
  };

  return (
    <ContextMenu
      items={[
        type === "person" &&
          checkUserType("person") && {
            label: t("buttons.connect"),
            onClick: () => checkAuth() && connect.mutate(),
            icon: ArrowUturnRightIcon,
          },
        {
          label: t("buttons.send"),
          onClick: () => checkAuth() && createChat.mutate(),
          icon: ChatBubbleLeftEllipsisIcon,
        },
        {
          label: t("buttons.block"),
          onClick: () => dispatch(showBlockUserModal(userId)),
          icon: MinusCircleIcon,
        },
      ]}
    />
  );
};

export default ContactContextMenu;

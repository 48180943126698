import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { BookmarkIcon } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import tw from "twin.macro";

type Props = {
  postId: string;
  bookmarked: boolean;
};

const WatchBookmarkButton = ({ postId, bookmarked }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watch", "posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watch", "posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  return (
    <button
      onClick={e => {
        if (user) {
          Haptics.impact({ style: ImpactStyle.Light });
          bookmarked ? remove.mutate() : add.mutate();
        } else {
          loginModal.open();
        }
        e.stopPropagation();
      }}
      css={[
        tw`w-8 h-8`,
        tw`transition-all ease-out duration-200`,
        bookmarked && tw`text-yellow-400`,
      ]}
    >
      {bookmarked ? (
        <BookmarkIcon className={`${actionEffect && "animate-ping-once"}`} />
      ) : (
        <BookmarkIcon
          onClick={() => {
            setActionEffect(true);
          }}
          onAnimationEnd={() => {
            setActionEffect(false);
          }}
        />
      )}
    </button>
  );
};

export default WatchBookmarkButton;

import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import CreateJob from "@src/components/elements/jobs/form/CreateJob";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import JobsHomepage from "@src/pages/jobs/JobsHomepage";
import JobsListItem from "@src/pages/jobs/JobsListItem";
import TrendingJobsHomepage from "@src/pages/jobs/TrendingJobsHomepage";
import { useAppSelector } from "@src/state/hooks";
import { JobType } from "@src/utils/types/JobType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  tab?: string;
  subTab?: string;
};

type Response = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

const JobsContainer = ({ tab = "my_jobs", subTab = "all" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t, i18n } = useTranslation();
  const { user } = useAppSelector(state => state.user);

  const jobs = useInfiniteQuery<Response>(
    ["jobs", searchQuery, tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/jobs?page=${pageParam}&q=${searchQuery}&filter=${tab}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = useCallback(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (jobs.isFetchingNextPage || !jobs.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      jobs.fetchNextPage();
    }
  }, [jobs]);

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const getTabLabel = (root: string, key: string) => {
    if (i18n.exists(`${root}.tabsAlt.${key}`)) return t(`${root}.tabsAlt.${key}`);
    if (i18n.exists(`${root}.tabs.${key}`)) return t(`${root}.tabs.${key}`);
    return "";
  };

  const jobsList: JobType[] = jobs.data?.pages.flatMap(page => page.data) || [];

  return (
    <>
      {tab === "create" && <BackButton stepback />}
      <TabController defaultTab={tab}>
        <TabHeaders>
          <TabHeader id="my_jobs" url="/jobs/meine_jobs">
            {t("main.jobsTabs.tabs.myJobs")}
          </TabHeader>
          <TabHeader id="for_you" url="/jobs/für_dich">
            {t("main.jobsTabs.tabs.toYou")}
          </TabHeader>
          <TabHeader
            id="create"
            url="/verwaltung/jobs/jobs_erstellen"
            isHidden={user?.type !== "company"}
          >
            {t("main.navLinks.createAd.tabs.create")}
          </TabHeader>
          <TabHeader id="follower" url="/jobs/follower">
            {t("main.jobsTabs.tabs.follower")}
          </TabHeader>
          <TabHeader id="trending" url="/jobs/trending">
            {t("main.jobsTabs.tabs.trending")}
          </TabHeader>
        </TabHeaders>

        <TabBodies>
          <TabBody id="my_jobs">
            <Input
              name="jobs-keywords"
              placeholder={t("components.shared.search", {
                tab: getTabLabel("main.jobsTabs", subTab || tab),
              })}
              value={searchQuery}
              onChange={e => setSearchQuery(e)}
              clearable
            />

            <TabController defaultTab={subTab} isSubTab>
              <TabHeaders>
                <TabHeader id="overview" url="/jobs/meine_jobs/übersicht">
                  {t("main.jobsTabs.tabs.overview")}
                </TabHeader>
                <TabHeader id="news" url="/jobs/meine_jobs/nachrichten">
                  {t("main.jobsTabs.tabs.news")}
                </TabHeader>
                <TabHeader id="career" url="/jobs/meine_jobs/werdegänge">
                  {t("main.jobsTabs.tabs.career")}
                </TabHeader>
                <TabHeader id="statistics" url="/jobs/meine_jobs/statistik">
                  {t("main.jobsTabs.tabs.statistics")}
                </TabHeader>
              </TabHeaders>
              <TabBodies>
                <TabBody id="overview">
                  <div className="grid grid-cols-2 gap-3 md:grid-cols-3 xl:grid-cols-5">
                    {jobs.data &&
                      jobs.data.pages.map(
                        page =>
                          page.data &&
                          page.data.map((job: JobType) => (
                            <JobsListItem key={job.id} job={job} showButtons={false} />
                          )),
                      )}
                  </div>
                </TabBody>
                <TabBody id="news">
                  <></>
                </TabBody>
                <TabBody id="career">
                  <></>
                </TabBody>
                <TabBody id="statistics">
                  <></>
                </TabBody>
              </TabBodies>
            </TabController>
          </TabBody>
          <TabBody id="for_you">
            <div className="mb-2 mt-3 grid gap-4 md:grid-cols-2 xl:grid-cols-[1fr,auto]">
              <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex size-7 items-center pl-2 pt-2">
                    <img
                      src={"/images/icons/magnifying-glass.svg"}
                      className="mr-1 mt-1 flex size-[15px]"
                      alt="icon"
                    />
                  </span>
                  <input
                    type="text"
                    className="w-full rounded-2xl border border-gray-300 px-3 py-2 pl-8 pr-4 shadow-sm outline-darkblue placeholder:text-gray-400 focus:border-darkblue focus:ring-darkblue invalid:focus:ring-tf-red-600 sm:text-sm"
                    placeholder={t("main.jobsTabs.jobTitle")}
                  />
                </div>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex size-7 items-center pl-2 pt-2">
                    <img
                      src={"/images/icons/map-pin.svg"}
                      className="mr-1 mt-1 flex size-[15px]"
                      alt="icon"
                    />
                  </span>
                  <input
                    type="text"
                    className="w-full rounded-2xl border border-gray-300 px-3 py-2 pl-8 pr-4 shadow-sm outline-darkblue placeholder:text-gray-400 focus:border-darkblue focus:ring-darkblue invalid:focus:ring-tf-red-600 sm:text-sm
"
                    placeholder={t("main.jobsTabs.location")}
                  />
                </div>
                <div className="flex flex-row gap-2">
                  <input
                    type="checkbox"
                    className="mt-1 size-3 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
                    value="Part time"
                  />
                  <span className="text-sm">{t("main.jobsTabs.partTime")}</span>
                  <input
                    type="checkbox"
                    className="mt-1 size-3 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
                    value="Part time"
                  />
                  <span className="text-sm">{t("main.jobsTabs.fullTime")}</span>
                  <input
                    type="checkbox"
                    className="mt-1 size-3 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
                    value="Part time"
                  />
                  <span className="text-sm">{t("main.jobsTabs.homeOffice")}</span>
                </div>
              </div>

              <div className="-mt-3 w-full md:-mt-0 md:w-48">
                <Button
                  onClick={() => {
                    return;
                  }}
                  size={Button.Sizes.Small}
                  variant={Button.Variants.Primary}
                >
                  {t("main.jobsTabs.jobsFinden")}
                </Button>
              </div>
            </div>
            <JobsHomepage jobList={jobsList} />
          </TabBody>
          <TabBody id="create">
            <CreateJob />
          </TabBody>
          <TabBody id="follower">
            <Input
              name="jobs-keywords"
              placeholder={t("components.shared.search", {
                tab: getTabLabel("main.jobsTabs", subTab || tab),
              })}
              value={searchQuery}
              onChange={e => setSearchQuery(e)}
              clearable
            />
            <TrendingJobsHomepage jobList={jobsList} />
          </TabBody>
          <TabBody id="trending">
            <Input
              name="jobs-keywords"
              placeholder={t("components.shared.search", {
                tab: getTabLabel("main.jobsTabs", subTab || tab),
              })}
              value={searchQuery}
              onChange={e => setSearchQuery(e)}
              clearable
            />
            <TrendingJobsHomepage jobList={jobsList} />
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default JobsContainer;

import api from "@src/api/api";
import MessengerBubble from "@src/pages/messenger/MessengerBubble";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";
import { Media } from "@src/utils/types/Media";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

type Props = {
  chatId: string;
  me: User;
  query: string;
  onForward: (data: { text: string; link: string | undefined; media: Array<Media> }) => void;
  isGroup?: boolean;
};
type Message = {
  id: string;
  text: string;
  author: User;
  created_at: string;
  read: boolean;
  link_preview?: LinkPreviewType;
  media?: Array<Media>;
};
const MessagesContainer = ({ chatId, me, query, onForward, isGroup }: Props) => {
  const messages = useQuery<Message[], Error>(
    ["messages", "messages-" + chatId, query],
    async () => {
      const { data } = await api.get(`/api/v1/chats/${chatId}/messages?q=${query}`);
      return data;
    },
  );

  return (
    <div className="no-scrollbar flex h-full flex-col-reverse gap-3 overflow-auto p-4">
      {messages.isSuccess &&
        messages.data.map(message => (
          <MessengerBubble
            showImage={isGroup}
            isSender={message.author.id === me.id}
            key={message.id}
            author={message.author}
            onForward={() =>
              onForward({
                text: message.text,
                link: message.link_preview?.url,
                media: message.media || [],
              })
            }
            message={{
              id: message.id,
              text: message.text,
              link_preview: message.link_preview,
              read: message.read,
              created_at: moment(message.created_at).format("DD.MM.YYYY, HH:mm"),
              media: message.media,
            }}
          />
        ))}
    </div>
  );
};

export default MessagesContainer;

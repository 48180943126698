import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

type Props = {
  user: User;
  followerId: string;
};

const ContactsFollowButton = ({ user, followerId }: Props) => {
  const queryClient = useQueryClient();
  const { user: userData } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const { t } = useTranslation();

  const follow = useMutation([`follow-${followerId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${followerId}/follow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      alert("Follow failed, please try again.");
    },
  });

  const unfollow = useMutation([`unfollow-${followerId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${followerId}/unfollow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-i_follow"] });
    },
    onError: () => {
      alert("Unfollow failed, please try again.");
    },
  });

  return (
    <>
      {user.followed ? (
        <Button
          onClick={() => (userData ? unfollow.mutate() : loginModal.open())}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <img src="/follow.svg" className="mx-auto h-5 w-4" />
        </Button>
      ) : (
        <Button
          onClick={() => (userData ? follow.mutate() : loginModal.open())}
          size={Button.Sizes.Small}
          variant={Button.Variants.Red}
        >
          {t("main.contacts.tabs.buttonsConsequences")}
        </Button>
      )}
    </>
  );
};

export default ContactsFollowButton;

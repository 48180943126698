import {
  ArrowPathRoundedSquareIcon,
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import getName from "@src/utils/getName";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import tw from "twin.macro";

const GroupDetailsContainer = () => {
  const { userId } = useParams();
  const { t } = useTranslation();

  const user = useQuery<User, Error>([`user-${userId}`], async () => {
    const res = await api.get(`/api/v1/users/${userId}`);
    return res.data.data;
  });

  const [textMinimized, setTextMinimized] = useState(true);
  const sampleText =
    "WCPN (104.9 FM) is a non-commercial educational radio station licensed to Lorain, Ohio, featuring a public radio format as a repeater of Kent–licensed WKSU. Owned by Ideastream Public Media, the station serves the western portion of Greater Cleveland and parts of surrounding Northeast Ohio. By virtue of WKSU, studios are located at Playhouse Square in Downtown Cleveland,[2] while WCPN's transmitter resides in the Cleveland suburb of Avon. In addition to a standard analog transmission, WCPN broadcasts over four HD Radio channels and streams online. Signing on in 1975 under the WZLE call sign, the station originally focused on Lorain County with a full-service format of local news and easy listening music dubbed  mellow gold . A 1983 sale of WZLE to a local non-profit Christian group saw the station switch to Christian radio programming, eventually focusing on contemporary Christian music by the early 1990s. Jacor's purchase of WZLE in 1998 resulted in a format change to contemporary hit radio as the first home of WAKS the following year. A complex seven-station asset swap involving Jacor's successor company Clear Channel, Salem Communications and WCLV (95.5 FM) owner Radio Seaway on July 3, 2001, resulted in 104.9 FM becoming the second home of WCLV and its classical music format. Converted to non-commercial status and donated to Ideastream in 2013, WCLV's programming and call letters moved to 90.3 FM on March 28, 2022; in turn, this station became a full-power satellite of co-managed WKSU, bearing the WCPN calls. ";

  if (!user.isSuccess) return <span>Loading...</span>;

  const tabs = [
    { name: t("components.shared.aboutUs"), key: "about" },
    { name: t("main.navLinks.news"), key: "feed" },
    { name: t("main.navLinks.watch"), key: "watch" },
    { name: t("main.members.title"), key: "members" },
  ];

  return (
    <div className="grid grid-flow-row gap-2">
      <img src="/images/default_header_profile.png" className="h-[300px] w-full object-cover" />
      <div className="no-scrollbar mb-4 mt-2 flex gap-4 overflow-x-auto">
        {tabs.map(tab => {
          return (
            <div
              key={tab.key}
              className="cursor-pointer whitespace-nowrap border-b-2 pb-1 text-sm text-gray-400 hover:border-black hover:text-black"
            >
              {tab.name} (99+)
            </div>
          );
        })}
      </div>

      {/* Über uns Tab */}
      <div className="grid grid-flow-row gap-4">
        {/* Main description */}
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <UserWidget user={user.data} follow={false} online={true} />
            <div className="flex flex-col">
              <div className="inline-flex items-center gap-2">
                <div className="text-lg0 font-semibold">{getName(user.data)}</div>
                <img src="/images/group_verify.svg" className="size-4" />
              </div>
              <div className="text-xs">4 / 5 {t("main.shop.details.stars")}</div>
            </div>
          </div>
          <div className="flex flex-row gap-4 text-xs">
            <div>{t("main.members.title")}: 0</div>
            <div>{t("main.contacts.tabs.likes")}: 0</div>
            <div>{t("main.posts.title")}: 0</div>
            <div>{t("main.header.links.groups.tabs.openGroups")}</div>
          </div>
          <div className="just flex w-full flex-row items-center gap-4 text-xs">
            <div className="flex h-fit flex-row items-center gap-0.5 rounded-lg bg-slate-200 px-1 py-0.5">
              <ArrowPathRoundedSquareIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex h-fit flex-row items-center gap-0.5 rounded-lg bg-slate-200 px-1 py-0.5">
              <HeartIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex h-fit flex-row items-center gap-0.5 rounded-lg bg-slate-200 px-1 py-0.5">
              <EyeIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
              <div className="text-gray-500">xxx</div>
            </div>
            <div className="flex h-fit flex-row items-center gap-0.5 rounded-lg bg-slate-200 px-1 py-0.5">
              <BookmarkIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
              <div className="text-gray-500">xxx</div>
            </div>
            <button
              className="flex h-fit flex-row items-center gap-0.5 rounded-lg bg-slate-200 px-4 py-0.5"
              onClick={() => {
                return;
              }}
            >
              <div className="text-gray-500">{t("components.shared.join")}</div>
            </button>
          </div>

          <div>
            <div className="text-sm font-semibold">
              {t("main.profileViewTabs.aboutMe.content.general")}:
            </div>
            <div css={[tw`text-sm`, textMinimized && tw`line-clamp-5`]}>{sampleText}</div>
            <button
              className="mt-1 flex w-full flex-row items-center justify-center gap-2 border border-gray-500 text-sm"
              onClick={() => setTextMinimized(!textMinimized)}
            >
              {textMinimized ? t("buttons.keepReading") : t("buttons.less")}
              {textMinimized ? (
                <ChevronDownIcon className="size-4" />
              ) : (
                <ChevronUpIcon className="size-4" />
              )}
            </button>
          </div>
        </div>
        <div className="relative flex flex-col gap-2">
          <Panel title="Weitere Infos">
            <div className="grid grid-flow-col text-xs">
              <div>
                <img
                  src="/images/rating.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("main.shop.details.review")}</div>
              </div>

              <div>
                <img
                  src="/images/media_library.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("main.feedTabs.feetCreate.media")}</div>
              </div>

              <div>
                <img
                  src="/images/groups.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("main.header.links.groups.title")}</div>
              </div>

              <div>
                <img
                  src="/images/contact_person.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">
                  {t("main.profileViewTabs.aboutMe.content.sidebarButtons.contacts")}
                </div>
              </div>

              <div>
                <img
                  src="/images/hashtag.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("main.bookmarks.tabs.hashtags")}</div>
              </div>

              <div>
                <img
                  src="/images/events.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("components.shared.events")}</div>
              </div>

              <div>
                <img
                  src="/images/statistics.svg"
                  className="mx-auto h-[55px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800"
                />
                <div className="text-center">{t("main.navLinks.createAd.tabs.statistics")}</div>
              </div>
            </div>
          </Panel>
          <Panel title="Themen und Regeln">
            <div className="flex flex-col gap-1">
              <div>{t("components.shared.subjects")}: A B C D</div>
              <div>{t("components.shared.rules")}: 1 2 3 4</div>
            </div>
          </Panel>
          <Panel title="Social Media">
            <div className="flex flex-row gap-1">facebook instagram twitter youtube whatsapp</div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsContainer;

import { MinusCircleIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import UserWidgetPicture from "@src/components/elements/UserWidgetPicture";

type Props = { user: User; mode?: string; key?: string };

const ContactMyFollowersItem = ({ user }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const unblock = useMutation([`unblock-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-my_followers"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex w-full flex-col gap-2 rounded-lg border border-gray-300 pb-2 text-sm">
      <div className="relative">
        <NavLink to={"/profile/" + user.id} className="flex w-full flex-col gap-1">
          <UserWidgetPicture user={user} showName={true} follow={false} verified={true} />
        </NavLink>
        <div className="absolute right-0 top-0 pt-2">
          <ContactContextMenu userId={user.id} type={user.type} />
        </div>
      </div>
      <div
        className="flex flex-row justify-between px-2 text-xs text-gray-600"
        style={{ marginTop: "-25px" }}
      >
        <NavLink to={"/profile/" + user.id + "/follows"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.followers.title")}</div>
            <div>{user.follower_count}</div>
          </div>
        </NavLink>
        <NavLink to={"/profile/" + user.id + "/following"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.following.title")}</div>
            <div>{user.following_count}</div>
          </div>
        </NavLink>
        <NavLink to={"/profile/" + user.id + "/likes"}>
          <div className="flex flex-col items-center gap-x-1">
            <div>{t("main.likes.title")}</div>
            <div>{user.like_count}</div>
          </div>
        </NavLink>
      </div>
      <div className="px-2">
        <Button
          onClick={() => {
            if (user.blocked) {
              unblock.mutate();
            } else {
              dispatch(showBlockUserModal(user.id));
            }
          }}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <MinusCircleIcon className="size-4" />
          {user.blocked ? "Entblocken" : "Blockieren"}
        </Button>
      </div>
    </div>
  );
};

export default ContactMyFollowersItem;

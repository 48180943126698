import Button from "@src/components/elements/input/Button";
import { useTranslation } from "react-i18next";

type Props = {
  showBackward: boolean;
  showForward: boolean;
  backward: () => void;
  forward: () => void;
  preview: () => void;
  submit: () => void;
};
const BottomProfileEditTabs = ({
  showBackward,
  showForward,
  backward,
  forward,
  preview,
  submit,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row flex-wrap justify-end gap-2 sm:flex-nowrap">
      {showBackward && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={backward}>
          {t("main.groupsInfoForm.buttons.back")}
        </Button>
      )}
      <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={submit}>
        {t("main.groupsInfoForm.buttons.save")}
      </Button>
      {showForward && (
        <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} onClick={forward}>
          {t("main.groupsInfoForm.buttons.further")}
        </Button>
      )}
    </div>
  );
};

export default BottomProfileEditTabs;

import { NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import PostWidget from "@src/components/elements/PostWidget";
import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import { useAppSelector } from "@src/state/hooks";
import { Post } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import tw from "twin.macro";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  tab?: string;
};

const HashtagContainer = ({ tab = "feed" }: Props) => {
  const { hashtag } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [lightboxSelection, setLightboxSelection] = useState("");
  const { t } = useTranslation();

  const posts = useInfiniteQuery<Response>(
    ["posts", hashtag],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        `/api/v1/hashtags/${hashtag}/posts?page=${pageParam}&user_id=${user?.id}`,
      );
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      posts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const { user } = useAppSelector(state => state.user);

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-2">
        <div className="truncate font-bold">#{hashtag}</div>
        <Input
          placeholder={t("main.feedTabs.placeholder")}
          name="search_keywords"
          onChange={e => setSearchQuery(e)}
          clearable
        />
        <TabController defaultTab={tab} isSubTab>
          <TabHeaders headerCSS={tw`mb-2`} center>
            <TabHeader id="feed" url={"/tags/" + hashtag + "/feed"}>
              <NewspaperIcon className="float-left mr-1.5 size-4" /> {t("main.navLinks.news")}
            </TabHeader>
            <TabHeader id="watch" url={"/tags/" + hashtag + "/watch"}>
              <VideoCameraIcon className="float-left mr-1.5 size-4" /> {t("main.navLinks.watch")}
            </TabHeader>
          </TabHeaders>
          <TabBodies>
            <TabBody id="watch">
              <div className="grid grid-cols-2 gap-1 md:grid-cols-3">
                {/* <Media image="/images/default_header_profile.png" type="image" /> */}
              </div>
            </TabBody>
            <TabBody id="feed">
              <>
                {posts.data?.pages.map(
                  (page, pageIndex) =>
                    page &&
                    page.data?.map((post: Post, index: number) => (
                      <PostWidget
                        postId={post.id}
                        post={post}
                        key={post.id}
                        text={post.text}
                        author={post.author}
                        repost={post.reposted}
                        created_at={post.created_at}
                        onSwitch={(id: string) => {
                          setLightboxSelection(id);
                        }}
                        lightboxSelection={lightboxSelection}
                        prev={
                          index == 0 && pageIndex > 0
                            ? posts.data.pages[pageIndex - 1].data[
                                posts.data.pages[pageIndex - 1].data.length - 1
                              ].id
                            : index > 0
                              ? posts.data.pages[pageIndex].data[index - 1].id
                              : ""
                        }
                        next={
                          pageIndex + 1 < posts.data.pages.length &&
                          index + 1 == posts.data.pages[pageIndex].data.length
                            ? posts.data.pages[pageIndex + 1].data[0].id
                            : index + 1 < posts.data.pages[pageIndex].data.length
                              ? posts.data.pages[pageIndex].data[index + 1].id
                              : ""
                        }
                      />
                    )),
                )}
              </>
            </TabBody>
          </TabBodies>
        </TabController>
      </div>
    </>
  );
};

export default HashtagContainer;

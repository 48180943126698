import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { FlagIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import { useAppSelector } from "@src/state/hooks";
import { CommentType } from "@src/utils/types/CommentType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  comment: CommentType;
};

const CommentsDropdown = ({ comment }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteComment = useMutation([`delete-${comment.id}-${user ? user.id : ""}`], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/comments/${comment.id}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      {/* {openEdit && (
        <EditComment
          onSuccess={() => setOpenEdit(false)}
          post={comment}
          onClose={() => setOpenEdit(false)}
        />
      )} */}
      <Menu as="div" className="text-left group-hover/comment:visible lg:invisible">
        <div>
          <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
            <span className="sr-only">{t("advertisement.addWidged.openOptions")}</span>
            <EllipsisVerticalIcon className="size-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {user && user.id === comment.author.id ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => setOpenEdit(true)}
                  >
                    {t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit")}
                    <PencilIcon className="size-4" />
                  </button>
                )}
              </Menu.Item>
            ) : null}
            {user && user.id === comment.author.id ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => deleteComment.mutate()}
                  >
                    {t("components.shared.remove")}
                    <TrashIcon className="size-4" />
                  </button>
                )}
              </Menu.Item>
            ) : null}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  {t("buttons.report")}
                  <FlagIcon className="size-4" />
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default CommentsDropdown;

import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import SharePopup from "@src/components/popup/SharePopup";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { Media } from "@src/utils/types/Media";

type ContentType = {
  title?: string;
  body?: string;
  media?: Array<Media>;
};

type Props = {
  contentType: string;
  label?: string;
  content?: ContentType;
};

const ShareButton = ({ contentType, label = "", content = {} }: Props) => {
  const popupModal = usePopupModal();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  return (
    <PostsActionBarItem
      onClick={() => {
        if (!user) {
          loginModal.open();
        } else {
          popupModal.open(<SharePopup onClose={popupModal.close} userId="" content={content} />);
        }
      }}
      label={label}
    >
      <ArrowPathRoundedSquareIcon />
    </PostsActionBarItem>
  );
};

export default ShareButton;

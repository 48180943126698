import { XMarkIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTus } from "use-tus";

type Props = {
  onChange: (media: {
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }) => void;
  onDelete: (id: string) => void;
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }>;
  addClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
  tooltip?: string;
};
const PostMedia = ({ onChange, media, onDelete, addClick, tooltip }: Props) => {
  const [uploadingCount, setUploadingCount] = useState(0);
  const { upload, setUpload, isSuccess, error, remove } = useTus();
  const { t } = useTranslation();

  useEffect(() => {
    if (upload) {
      upload.start();
    }
  }, [upload]);

  useEffect(() => {
    if (isSuccess) {
      remove();
    }
  }, [isSuccess]);

  const uploadMedia = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      const url = "/api/v1/media/upload";
      let data_url = "";
      let key = "";
      let id = "";
      let endpoint = "";
      let thumbnail = "";
      let tus_headers = {};
      if (file.type.startsWith("video")) {
        if (upload) {
          throw new Error("Already uploading");
        }
        const directUploadResponse = await api.get("/api/v1/video/upload/direct");

        endpoint = directUploadResponse.data.data.endpoint;
        tus_headers = directUploadResponse.data.data.tus_headers;
        key = directUploadResponse.data.data.key;
        id = directUploadResponse.data.data.id;
        thumbnail = directUploadResponse.data.data.thumbnail;
        const formData = new FormData();
        formData.append("file", file);
        if (!file) {
          return;
        }
        setUpload(file, {
          endpoint: endpoint,
          headers: tus_headers,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
        });
      } else {
        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const res = await api.post(url, formData, config);
        key = res.data.data.key;
        id = res.data.data.id;
        data_url = res.data.data.data_url;
      }

      if (
        !key ||
        !id ||
        !file.name ||
        (file.type.startsWith("video") && !thumbnail) ||
        (!file.type.startsWith("video") && !data_url) ||
        !file.type
      ) {
        throw new Error("Invalid response");
      }
      return {
        key,
        id,
        file_name: file.name,
        type: file.type.startsWith("video") ? "video" : "image",
        thumbnail,
        data_url,
      };
    },
    onSuccess: data => {
      setUploadingCount(count => count - 1);
      if (!data) {
        return;
      }
      onChange(data);
    },
    onError: () => {
      setUploadingCount(count => count - 1);
      alert("Failed");
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const addMedia = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  if (addClick?.setFunction && !addClick?.getFunction) {
    addClick.setFunction(() => {
      return addMedia;
    });
  }

  return (
    <>
      <div className="text-sm font-semibold">{t("main.feedTabs.feetCreate.media")}</div>
      {tooltip && <div className="text-sm">{tooltip}</div>}
      <div className="grid grid-cols-4 gap-3 rounded-xl lg:border lg:p-3">
        {media.map(data => (
          <div key={data.id} className="relative">
            <button
              onClick={() => {
                onDelete(data.id);
              }}
              className="absolute right-3 top-3"
            >
              <XMarkIcon className="size-6 rounded-full bg-white opacity-90" />
            </button>
            {data.type === "image" && (
              <img className="rounded-xl bg-gray-200" src={data.data_url} />
            )}
            {data.type === "video" && (
              <img className="rounded-xl bg-gray-200" src={data.thumbnail} />
            )}
          </div>
        ))}
        {[...Array(uploadingCount)].map((_, index) => (
          <div key={index} className="rounded-xl bg-gray-200">
            <svg className="mr-3 size-5 animate-spin" viewBox="0 0 24 24"></svg>
          </div>
        ))}
      </div>
      {!addClick && (
        <div className="ml-auto w-fit">
          <Button
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
            onClick={addMedia}
          >
            {t("main.feedTabs.buttons.addMedia")}
          </Button>
        </div>
      )}
      <input
        type="file"
        className="ml-auto"
        accept="image/*,video/*"
        onChange={e => {
          if (e.target.files && e.target.files.length + uploadingCount + media.length > 10) {
            alert("Max 10 Bilder");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount(count => count + 1);
            uploadMedia.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};

export default PostMedia;

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import Tags from "@src/components/elements/input/Tags";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import AdsMedia from "@src/pages/marketplace/form/AdsMedia";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { v4 as uuid } from "uuid";

function MarketplaceCreateContainer() {
  const navigate = useNavigate();
  const popupModal = usePopupModal();
  const queryClient = useQueryClient();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [barcode, setBarcode] = useState("");
  const [priceGross, setPriceGross] = useState(0);
  const [priceNet, setPriceNet] = useState(0);
  const [stock, setStock] = useState(0);
  const [tax, setTax] = useState(19);

  type ListItem = {
    label: string;
    id: string;
  };

  type CombinationItemType = {
    value: string;
    id?: string;
    changes?: string;
    key?: string;
  };

  type CombinationType = {
    list: Array<CombinationItemType>;
    checked?: boolean;
    id?: string;
  };

  type AttributeType = {
    checked?: boolean;
    key: string;
    name: string;
    type?: string;
    values?: Array<CombinationItemType>;
  };

  const categories: Array<ListItem> = [
    { label: "Antiquitäten, Kunst", id: "1" },
    { label: "Audio, Foto", id: "1" },
    { label: "Baby, Kleinkind", id: "1" },
    { label: "Bücher", id: "1" },
    { label: "Büro-, Schulbedarf", id: "1" },
    { label: "Computer", id: "1" },
    { label: "Fahrräder", id: "1" },
    { label: "Gewerbe, Industrie", id: "1" },
    { label: "Handy, Telefon, Fax", id: "1" },
    { label: "Haushalt", id: "1" },
    { label: "Heimwerker, Garten", id: "1" },
    { label: "Kleidung, Schmuck", id: "1" },
    { label: "Konsolen, Games", id: "1" },
    { label: "Möbel, Wohnen", id: "1" },
    { label: "Musikinstrumente", id: "1" },
    { label: "Musik, Film", id: "1" },
    { label: "Sammeln", id: "1" },
    { label: "Spielzeug, Modellbau", id: "1" },
    { label: "Sport, Camping", id: "1" },
    { label: "TV, Empfangstechnik", id: "1" },
    { label: "Sonstiges", id: "1" },
  ];

  const idTypeOptions = [
    { name: "ISBN", value: "isbn" },
    { name: "UPC", value: "upc" },
    { name: "GTIN", value: "gtin" },
    { name: "EAN", value: "ean" },
  ];

  const conditionOptions = [
    { name: "Neu", value: "new" },
    { name: "Gebraucht", value: "used" },
    { name: "Aufgebessert", value: "refurbished" },
  ];

  const currencyOptions = [
    { name: "€ EUR", value: "new" },
    { name: "$ USD", value: "dollar" },
    { name: "£ GBP", value: "pound" },
    { name: "¥ JPY", value: "yen" },
  ];

  const shippingServiceOptions = [
    { name: "Keine Angabe", value: "undefined" },
    { name: "DHL", value: "dhl" },
    { name: "DHL Express", value: "dhl express" },
    { name: "DPD", value: "dpd" },
    { name: "UPS", value: "ups" },
    { name: "GLS", value: "gls" },
    { name: "FedEx", value: "fedex" },
    { name: "Deutsche Post", value: "deutsche post" },
  ];
  const { t } = useTranslation();

  const publishProduct = useMutation(["add-product"], {
    mutationFn: async () => {
      await api.post("/api/v1/marketplace/products", {
        title,
        description,
        price_gross: priceGross,
        price_net: priceNet,
        stock,
        tax,
      });
      return true;
    },
    onSuccess: () => {
      setTitle("");
      setDescription("");
      setBarcode("");
      setPriceGross(0);
      setPriceNet(0);
      setStock(0);
      setTax(19);
      queryClient.invalidateQueries({
        queryKey: ["marketplace-shop_all"],
      });
      navigate("/marketplace/für_dich");
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Try again in a few minutes."
          title="Upload Product Error"
        />,
      );
    },
  });

  const [attributeInput, setAttributeInput] = useState("");
  const [variationsMinimized, setVariationsMinimized] = useState(true);
  const [attributes, setAttributes] = useState<Array<AttributeType>>([
    {
      key: "color",
      name: "Farbe",
      type: "string",
    },
    {
      key: "material",
      name: "Material",
      type: "string",
    },
    {
      key: "weight",
      name: "Gewicht",
      type: "string",
    },
    {
      key: "brand",
      name: "Marke",
      type: "string",
    },
    {
      key: "specifics",
      name: "Besonderes Merkmal",
      type: "string",
    },
    {
      key: "model",
      name: "Modellname",
      type: "string",
    },
    {
      key: "length",
      name: "Länge",
      type: "string",
    },
    {
      key: "form",
      name: "Formfaktor",
      type: "string",
    },
    {
      key: "voltage",
      name: "Spannung",
      type: "string",
    },
    {
      key: "dimensions",
      name: "Artikelmaße L x B x H",
      type: "string",
    },
    {
      key: "batterytype",
      name: "Akkuaufbau",
      type: "string",
    },
    {
      key: "power",
      name: "Wattzahl",
      type: "string",
    },
    {
      key: "style",
      name: "Stil",
      type: "string",
    },
    {
      key: "theme",
      name: "Thema",
      type: "string",
    },
    {
      key: "count",
      name: "Anzahl",
      type: "string",
    },
    {
      key: "size",
      name: "Größe",
      type: "string",
    },
    {
      key: "age",
      name: "Altersspektrum",
      type: "string",
    },
    {
      key: "cabletype",
      name: "Kabeltyp",
      type: "string",
    },
    {
      key: "compatible",
      name: "Kompatible Geräte",
      type: "string",
    },
    {
      key: "dataspeed",
      name: "Datenübertragungsrate",
      type: "string",
    },
    {
      key: "connector",
      name: "Steckverbindertyp",
      type: "string",
    },
    {
      key: "format",
      name: "Format",
      type: "string",
    },
    {
      key: "origin",
      name: "Ursprungsland",
      type: "string",
    },
    {
      key: "platform",
      name: "Plattform",
      type: "string",
    },
    {
      key: "package",
      name: "Paketinformationen",
      type: "string",
    },
    {
      key: "pattern",
      name: "Muster",
      type: "string",
    },
    {
      key: "iselectric",
      name: "Ist elektrisch",
      type: "boolean",
    },
    {
      key: "ampere",
      name: "Stromstärke",
      type: "string",
    },
  ]);

  const selectedAttributes = attributes.filter(item => {
    return item.checked;
  });

  const [attributeFilter, setAttributeFilter] = useState("");
  const [vat, setVat] = useState(1.19);
  const [netValue, setNetValue] = useState(0);
  const totalValue = vat * netValue;

  const [selectedCategory, setSelectedCategory] = useState<ListItem>(categories[0]);

  const [setHasVariations, setSetHasVariations] = useState(false);
  const [variationsInput, setVariationsInput] = useState<{
    [variant: string]: string;
  }>({});
  const [formData, setFormData] = useState<any>([]);

  const [images, setImages] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);

  const [videos, setVideos] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);

  const [documents, SetDocuments] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);

  function createVariantTree(array: Array<AttributeType>, index = 0) {
    let retArray: Array<AttributeType> = [];
    const listItem: Array<any> = [];
    if (array.length == 0 || !array[index].values) return [];
    (array[index].values ?? []).forEach((element: any) => {
      if (index < selectedAttributes.length - 1) {
        retArray = createVariantTree(array, index + 1);
        listItem.push({ item: element, children: retArray });
      } else {
        listItem.push({ item: element, children: [] });
      }
    });
    return listItem;
  }

  function createCombinations(
    array: Array<AttributeType>,
    stack: CombinationType = { list: [] },
  ): Array<CombinationType> {
    let totalStack: Array<CombinationType> = [];
    array.forEach((entry: any) => {
      const newStack: CombinationType = JSON.parse(JSON.stringify(stack));
      newStack.list.push(entry.item);
      newStack.id = uuid();
      if (entry.children && entry.children.length > 0)
        totalStack = [...createCombinations(entry.children, newStack), ...totalStack];
      else {
        totalStack.push(JSON.parse(JSON.stringify(newStack)));
        newStack.list.pop();
      }
    });
    return totalStack;
  }

  function checkCombinations(currentAttributes: Array<AttributeType>): Array<CombinationType> {
    const newCombinationsList = createCombinations(
      createVariantTree(
        currentAttributes.filter(item => {
          return item.checked;
        }),
      ),
    );
    if (newCombinationsList.length != combinationsList.length) {
      return newCombinationsList;
    } else {
      newCombinationsList.forEach((element, index) => {
        element.checked = combinationsList[index].checked;
        element.id = combinationsList[index].id;
      });

      return newCombinationsList;
    }
  }

  const [combinationsList, setCombinationsList] = useState<Array<CombinationType>>([]);

  const { user } = useAppSelector(state => state.user);
  if (!user) return <span>Loading...</span>;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Panel>
        <TabController defaultTab="product" isPersistent isSubTab>
          <TabHeaders headerCSS={tw`mb-2`}>
            <TabHeader id="product">{t("main.sell.created.productData")}</TabHeader>
            <TabHeader id="variants">{t("pages.marketplace.variations")}</TabHeader>
            <TabHeader id="details">{t("main.profileTabs.person.details")}</TabHeader>
            <TabHeader id="media">{t("main.feedTabs.feetCreate.media")}</TabHeader>
            <TabHeader id="rules">{t("components.shared.rules")}</TabHeader>
          </TabHeaders>
          <TabBodies>
            <TabBody id="product">
              <div className="grid grid-flow-row gap-2 text-sm">
                <h1 className="text-sm font-semibold">{t("main.sell.created.productData")}</h1>
                <Input
                  name="barcode"
                  value={barcode}
                  onChange={e => setBarcode(e)}
                  placeholder={t("main.sell.created.barcode")}
                  label={t("main.sell.created.barcode")}
                />
                <Input name="title" label={t("main.sell.created.productTitle")} />
                <textarea
                  name="description"
                  className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
                  placeholder={t("main.sell.created.productDescription")}
                />
                <Input name="category_search" label={t("pages.marketplace.searchCategorie")} />
                <div className="grid h-[200px] grid-flow-row overflow-y-scroll rounded-2xl border border-gray-300 scrollbar-thin">
                  {categories.map((category: ListItem) => {
                    return (
                      <button
                        key={category.id}
                        css={[
                          tw`h-[50px] text-left`,
                          selectedCategory.label === category.label
                            ? tw`bg-yellow-100`
                            : tw`hover:bg-gray-100`,
                        ]}
                        onClick={() => setSelectedCategory(category)}
                      >
                        <div className="flex w-full flex-row items-start justify-between px-4 pt-3">
                          <div className="grid grid-rows-2">
                            {category.label}
                            {false && <div className="text-xs text-gray-500"></div>}
                          </div>
                          <button className="rounded-lg border border-gray-200 px-1 text-xs">
                            {t("main.shop.buttons.select")}
                          </button>
                        </div>
                      </button>
                    );
                  })}
                </div>
                <div className="grid grid-flow-row items-end gap-2 lg:grid-cols-2">
                  <Input name="brand" label={t("pages.marketplace.brandName")} />
                  <Input name="manufacturer" label={t("pages.marketplace.fabricant")} />
                </div>
                <Tags name="keywords" label="Tags & Keywords" />
                <div className="flex flex-col gap-x-8 gap-y-1 lg:flex-row">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="limited"
                      name="limited"
                      value="0"
                      onChange={() => setTax(19)}
                      defaultChecked
                      className="focus:ring-transparent"
                    />
                    19%
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="limited"
                      name="limited"
                      value="1"
                      onChange={() => setTax(7)}
                      className="focus:ring-transparent"
                    />
                    7%
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="limited"
                      name="limited"
                      value="2"
                      onChange={() => setTax(0)}
                      className="focus:ring-transparent"
                    />
                    nicht ausweisbar
                  </div>
                </div>
                <div css={[tw`grid grid-flow-row lg:grid-cols-2 gap-2 items-center`]}>
                  <Input
                    name="net_value"
                    placeholder={t("pages.paymentAndOrder.price")}
                    label={t("pages.paymentAndOrder.price")}
                    onChange={e => {
                      setNetValue(Number(e));
                    }}
                  />
                  <Input
                    name="sell_value"
                    placeholder={t("pages.marketplace.sellvalue")}
                    label={t("pages.marketplace.sellvalue")}
                  />
                </div>
                <Input
                  name="quantity"
                  value={stock}
                  onChange={e => setStock(parseInt(e))}
                  placeholder={t("main.sell.created.quantityAvailable")}
                  label={t("main.sell.created.quantityAvailable")}
                />
                <Input
                  name="hscode"
                  placeholder={t("main.sell.created.hsCode")}
                  label={t("main.sell.created.hsCode")}
                />
                <div className="grid grid-flow-row items-end gap-2 lg:grid-cols-3">
                  <Select
                    items={shippingServiceOptions}
                    name="shippingservice"
                    label={t("main.sell.created.shippingServiceProvider")}
                  />
                  <Input
                    name="shippingcosts"
                    placeholder="0"
                    label={t("main.sell.created.shippingCosts")}
                  />
                  <Input
                    name="reference"
                    placeholder={t("main.sell.created.referenceNumber")}
                    label={t("main.sell.created.referenceNumber")}
                  />
                </div>
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-3">
                  <Input
                    name="deliverydate"
                    placeholder={t("main.sell.created.expectedDelivery")}
                    label={t("main.sell.created.expectedDelivery")}
                  />
                  <Input
                    name="trackingid"
                    placeholder={t("main.sell.created.trackingNumber")}
                    label={t("main.sell.created.trackingNumber")}
                  />
                  <Input
                    name="trackingurl"
                    placeholder={t("main.sell.created.trackingUrl")}
                    label={t("main.sell.created.trackingUrl")}
                  />
                </div>
                Gibt es Produktvarianten?
                <div className="flex flex-row items-center gap-2">
                  <input
                    type="radio"
                    name="has_variants_true"
                    value="true"
                    onChange={() => setSetHasVariations(true)}
                    checked={setHasVariations}
                    className="focus:ring-transparent"
                  />
                  <label htmlFor={"has_variants_true"}>{t("components.shared.yes")}</label>

                  <input
                    type="radio"
                    name="has_variants_false"
                    value="false"
                    onChange={() => setSetHasVariations(false)}
                    checked={!setHasVariations}
                    className="focus:ring-transparent"
                  />
                  <label htmlFor="has_variants_false">{t("components.shared.no")}</label>
                </div>
                <Checkbox
                  name="agb"
                  onChange={() => {
                    return null;
                  }}
                  checked={false}
                >
                  {t("main.sell.created.termsCondition")}
                </Checkbox>
              </div>
            </TabBody>
            <TabBody id="variants">
              <div className="grid grid-flow-row gap-2 text-sm">
                <div className="flex flex-row justify-between">
                  <div>{t("pages.marketplace.selectAttributes")}</div>
                  {selectedAttributes.length > 0 && (
                    <button
                      onClick={() => {
                        const newVariations = JSON.parse(JSON.stringify(attributes));
                        newVariations.forEach((variation: AttributeType) => {
                          variation.checked = false;
                        });
                        setAttributes(newVariations);
                      }}
                      className="flex items-center gap-1 text-gray-500 hover:text-gray-900"
                    >
                      {t("pages.marketplace.deleteSelection")} <XMarkIcon className="size-4" />
                    </button>
                  )}
                </div>
                <Input
                  name="attributes_filter"
                  clearable
                  autoComplete="off"
                  label={t("pages.marketplace.searchAttributes")}
                  onChange={e => {
                    setAttributeFilter(e);
                    setVariationsMinimized(false);
                  }}
                />
                <div className="flex flex-row items-center gap-2">
                  <Input
                    name=""
                    value={attributeInput}
                    label={t("pages.marketplace.ownAttribute")}
                    onChange={e => {
                      setAttributeInput(e);
                    }}
                  />
                  <PlusCircleIcon
                    className="mt-3 size-6 cursor-pointer"
                    onClick={() => {
                      const newVariations = JSON.parse(JSON.stringify(attributes));

                      const hasVariant = Object.keys(newVariations).some(item => {
                        return (
                          newVariations[item].name.toLowerCase() === attributeInput.toLowerCase()
                        );
                      });
                      if (!hasVariant)
                        newVariations[attributeInput] = {
                          name: attributeInput,
                          type: "string",
                          checked: true,
                        };
                      setAttributes(newVariations);
                    }}
                  />
                </div>
                <div
                  css={[
                    tw`flex flex-wrap gap-x-4 gap-y-2 px-1 max-h-[60px] overflow-y-scroll scrollbar-thin`,
                    variationsMinimized && tw`hidden`,
                  ]}
                >
                  {attributes
                    .filter(item => {
                      return attributeFilter.length > 0
                        ? item.name.toLowerCase().includes(attributeFilter.toLowerCase())
                        : true;
                    })
                    .map(variation => {
                      return (
                        <Checkbox
                          key={variation.key}
                          name="variations"
                          checked={variation.checked ?? false}
                          onChange={checked => {
                            const newAttributes = JSON.parse(JSON.stringify(attributes));
                            const i = newAttributes.findIndex((item: AttributeType) => {
                              return item.name === variation.name;
                            });
                            newAttributes[i].checked = checked;
                            setAttributes(newAttributes);
                          }}
                        >
                          {variation.name}
                        </Checkbox>
                      );
                    })}
                </div>
                <button
                  onClick={() => {
                    setVariationsMinimized(!variationsMinimized);
                  }}
                >
                  {variationsMinimized ? (
                    <ChevronDownIcon className="mx-auto size-6" />
                  ) : (
                    <ChevronUpIcon className="mx-auto size-6" />
                  )}
                </button>
                {selectedAttributes.map(variation => {
                  return (
                    <div key={variation.key}>
                      <div className="grid grid-cols-[140px,1fr] items-start gap-2">
                        {variation.type === "string" && (
                          <Input
                            label={variation.name}
                            value={variationsInput[variation.key]}
                            name=""
                            clearable
                            onChange={e => {
                              const newVariationsInput = JSON.parse(
                                JSON.stringify(variationsInput),
                              );
                              newVariationsInput[variation.key] = e;
                              setVariationsInput(newVariationsInput);
                            }}
                          />
                        )}
                        {variation.type === "boolean" && (
                          <div>
                            {variation.name}
                            <div className="flex items-center gap-4">
                              <Checkbox
                                checked={variationsInput[variation.key] === "true"}
                                name=""
                                onChange={() => {
                                  const newVariationsInput = JSON.parse(
                                    JSON.stringify(variationsInput),
                                  );
                                  newVariationsInput[variation.key] = "true";
                                  setVariationsInput(newVariationsInput);
                                }}
                              >
                                {t("components.shared.yes")}
                              </Checkbox>
                              <Checkbox
                                checked={variationsInput[variation.key] === "false"}
                                name=""
                                onChange={() => {
                                  const newVariationsInput = JSON.parse(
                                    JSON.stringify(variationsInput),
                                  );
                                  newVariationsInput[variation.key] = "false";
                                  setVariationsInput(newVariationsInput);
                                }}
                              >
                                {t("components.shared.no")}
                              </Checkbox>
                            </div>
                          </div>
                        )}
                        <div className="mt-3 flex flex-wrap gap-y-3">
                          {variation.values?.map((entry, valueIndex) => {
                            return (
                              <div key={entry.id}>
                                {variation.type === "string" && (
                                  <div className="ml-3 flex h-[26px] w-[100px] items-center justify-between gap-2 border border-gray-300 px-1">
                                    <input
                                      className="w-full"
                                      value={entry.changes ?? entry.value}
                                      defaultValue={entry.value}
                                      onKeyDown={e => {
                                        if (e.key === "Escape") {
                                          e.preventDefault();
                                          const newAttributes = JSON.parse(
                                            JSON.stringify(attributes),
                                          );
                                          const attributeIndex = newAttributes.findIndex(
                                            (item: AttributeType) => {
                                              return item.name === variation.name;
                                            },
                                          );
                                          newAttributes[attributeIndex].values[valueIndex].changes =
                                            newAttributes[attributeIndex].values[valueIndex].value;
                                          setAttributes(newAttributes);
                                          setCombinationsList(checkCombinations(newAttributes));
                                        }
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          const newAttributes = JSON.parse(
                                            JSON.stringify(attributes),
                                          );
                                          const attributeIndex = newAttributes.findIndex(
                                            (item: AttributeType) => {
                                              return item.name === variation.name;
                                            },
                                          );
                                          newAttributes[attributeIndex].values[valueIndex].value =
                                            newAttributes[attributeIndex].values[
                                              valueIndex
                                            ].changes;
                                          setAttributes(newAttributes);
                                          setCombinationsList(checkCombinations(newAttributes));
                                        }
                                      }}
                                      onChange={e => {
                                        const newAttributes = JSON.parse(
                                          JSON.stringify(attributes),
                                        );
                                        const i = newAttributes.findIndex((item: AttributeType) => {
                                          return item.name === variation.name;
                                        });
                                        newAttributes[i].values[valueIndex].changes =
                                          e.target.value;
                                        setAttributes(newAttributes);
                                      }}
                                    />
                                    <div className="flex">
                                      <button
                                        onClick={() => {
                                          const newAttributes = JSON.parse(
                                            JSON.stringify(attributes),
                                          );
                                          const i = newAttributes.findIndex(
                                            (item: AttributeType) => {
                                              return item.name === variation.name;
                                            },
                                          );
                                          newAttributes[i].values.splice(valueIndex, 1);
                                          setCombinationsList(checkCombinations(newAttributes));
                                          setAttributes(newAttributes);
                                        }}
                                      >
                                        <XMarkIcon className="size-4 hover:text-red-500" />
                                      </button>

                                      {!!(
                                        entry.changes &&
                                        entry.changes.localeCompare(entry.value) != 0
                                      ) && (
                                        <button
                                          onClick={() => {
                                            const newAttributes = JSON.parse(
                                              JSON.stringify(attributes),
                                            );
                                            const i = newAttributes.findIndex(
                                              (item: AttributeType) => {
                                                return item.name === variation.name;
                                              },
                                            );
                                            newAttributes[i].values[valueIndex].value =
                                              newAttributes[i].values[valueIndex].changes;
                                            setAttributes(newAttributes);
                                            setCombinationsList(checkCombinations(newAttributes));
                                          }}
                                        >
                                          <CheckIcon className="size-4 hover:text-green-500" />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {variation.type === "boolean" && (
                                  <div className="ml-3 flex h-[26px] w-[100px] items-center justify-between gap-2 border border-gray-300 px-1">
                                    <div className="w-full">
                                      {entry.value === "true" ? "Ja" : "Nein"}
                                    </div>
                                    <button
                                      onClick={() => {
                                        const newVariations = JSON.parse(
                                          JSON.stringify(attributes),
                                        );
                                        const i = newVariations.findIndex((item: AttributeType) => {
                                          return item.name === variation.name;
                                        });
                                        newVariations[i].values.splice(valueIndex, 1);
                                        setAttributes(newVariations);
                                      }}
                                    >
                                      <XMarkIcon className="size-4" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="w-fit">
                        <Button
                          onClick={() => {
                            const newAttributes = JSON.parse(JSON.stringify(attributes));
                            const i = newAttributes.findIndex((item: AttributeType) => {
                              return item.name === variation.name;
                            });
                            if (!newAttributes[i].values) newAttributes[i].values = [];
                            const hasVariant = newAttributes[i].values.some(
                              (item: { value: string; id?: string }) => {
                                return item.value === variationsInput[variation.key];
                              },
                            );
                            if (!hasVariant) {
                              newAttributes[i].values.push({
                                key: variation.key,
                                value: variationsInput[variation.key],
                                id: uuid(),
                              });
                              setCombinationsList(checkCombinations(newAttributes));
                            }
                            setAttributes(newAttributes);
                            setCombinationsList(checkCombinations(newAttributes));
                          }}
                          variant={Button.Variants.Transparent}
                        >
                          {t("pages.marketplace.addVariant")}
                        </Button>
                      </div>
                    </div>
                  );
                })}
                <hr />

                <div className="grid grid-flow-row overflow-x-scroll px-1 pb-1 scrollbar-thin">
                  <div className="grid w-fit grid-cols-[20px,auto,80px,80px,70px,100px,60px,60px,60px,40px] gap-x-2 gap-y-1 bg-gray-200 px-2 py-1">
                    <div></div>
                    <div className="grid grid-flow-col gap-x-2">
                      {selectedAttributes.map(variation => {
                        return (
                          <div key={variation.key} className="w-[60px] truncate">
                            {variation.name}
                          </div>
                        );
                      })}
                    </div>
                    <div>{t("pages.marketplace.characteristics")}</div>
                    <div>{t("pages.marketplace.productID")}</div>
                    <div>{t("components.assignments.condition.label")}</div>
                    <div>{t("pages.marketplace.currency")}</div>
                    <div>{t("pages.paymentAndOrder.price")}</div>
                    <div>{t("main.sell.created.quantityAvailable")}</div>
                    <div>{t("main.feedTabs.feetCreate.media")}</div>
                  </div>
                  {combinationsList.map((combination: CombinationType, index) => {
                    return (
                      <div
                        key={combination.id}
                        className="grid w-fit grid-cols-[20px,auto,80px,80px,70px,100px,60px,60px,40px] gap-x-2 gap-y-1 bg-gray-200 px-2 py-1"
                      >
                        <input
                          type="checkbox"
                          className="my-auto"
                          checked={combination.checked}
                          onChange={e => {
                            const newCombinationsList = JSON.parse(
                              JSON.stringify(combinationsList),
                            );
                            newCombinationsList[index].checked = e.target.checked;
                            setCombinationsList(newCombinationsList);
                          }}
                        />
                        <div className="grid grid-flow-col gap-x-2">
                          {combination.list.map((variation: CombinationItemType) => {
                            return (
                              <div key={variation.id}>
                                <input
                                  className="h-full w-[60px] overflow-hidden text-ellipsis px-0.5"
                                  value={variation.changes ?? variation.value}
                                  onBlur={e => {
                                    // Reset to default on leaving input
                                    e.target.value = variation.value;
                                    const newAttributes = JSON.parse(JSON.stringify(attributes));
                                    const attributeIndex = attributes.findIndex(element => {
                                      return element.key === variation.key;
                                    });
                                    const valueIndex =
                                      attributes[attributeIndex].values?.findIndex(element => {
                                        return element.id === variation.id;
                                      }) ?? -1;
                                    newAttributes[attributeIndex].values[valueIndex].changes =
                                      newAttributes[attributeIndex].values[valueIndex].value;
                                    setAttributes(newAttributes);
                                    createCombinations(createVariantTree(selectedAttributes));
                                  }}
                                  onKeyDown={e => {
                                    if (e.key === "Escape") {
                                      e.preventDefault();
                                      const newAttributes = JSON.parse(JSON.stringify(attributes));
                                      const attributeIndex = attributes.findIndex(element => {
                                        return element.key === variation.key;
                                      });
                                      const valueIndex =
                                        attributes[attributeIndex].values?.findIndex(element => {
                                          return element.id === variation.id;
                                        }) ?? -1;
                                      newAttributes[attributeIndex].values[valueIndex].changes =
                                        newAttributes[attributeIndex].values[valueIndex].value;
                                      setAttributes(newAttributes);
                                      setCombinationsList(checkCombinations(newAttributes));
                                    }
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      const newAttributes = JSON.parse(JSON.stringify(attributes));
                                      const attributeIndex = attributes.findIndex(element => {
                                        return element.key === variation.key;
                                      });
                                      const valueIndex =
                                        attributes[attributeIndex].values?.findIndex(element => {
                                          return element.id === variation.id;
                                        }) ?? -1;
                                      newAttributes[attributeIndex].values[valueIndex].value =
                                        newAttributes[attributeIndex].values[valueIndex].changes;
                                      setAttributes(newAttributes);
                                    }
                                  }}
                                  onChange={e => {
                                    const newAttributes = JSON.parse(JSON.stringify(attributes));
                                    const attributeIndex = attributes.findIndex(element => {
                                      return element.key === variation.key;
                                    });
                                    const valueIndex =
                                      attributes[attributeIndex].values?.findIndex(element => {
                                        return element.id === variation.id;
                                      }) ?? -1;
                                    newAttributes[attributeIndex].values[valueIndex].changes =
                                      e.target.value;
                                    setAttributes(newAttributes);
                                    setCombinationsList(checkCombinations(newAttributes));
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <input
                          className="w-full rounded-md border border-gray-300 px-1 shadow-sm outline-darkblue focus:border-none"
                          onChange={() => {
                            console.log("");
                          }}
                          placeholder=""
                        />
                        <input
                          className="w-full rounded-md border border-gray-300 px-1 shadow-sm outline-darkblue focus:border-none"
                          onChange={() => {
                            console.log("");
                          }}
                          placeholder=""
                        />
                        <select
                          className="block w-full rounded-md border border-gray-300 pl-1 pr-6 text-xs shadow-sm outline-darkblue focus:border-darkblue"
                          onChange={() => {
                            console.log("");
                          }}
                        >
                          {idTypeOptions.map(idType => {
                            return (
                              <option key={idType.value} value={idType.value}>
                                {idType.name}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          className="block w-full rounded-md border border-gray-300 pl-1 pr-6 text-xs shadow-sm outline-darkblue focus:border-darkblue"
                          onChange={() => {
                            console.log("");
                          }}
                        >
                          {conditionOptions.map(condition => {
                            return (
                              <option key={condition.value} value={condition.value}>
                                {condition.name}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          className="block w-full rounded-md border border-gray-300 pl-1 pr-6 text-xs shadow-sm outline-darkblue focus:border-darkblue"
                          onChange={() => {
                            console.log("");
                          }}
                        >
                          {currencyOptions.map(currency => {
                            return (
                              <option key={currency.value} value={currency.value}>
                                {currency.name}
                              </option>
                            );
                          })}
                        </select>
                        <input
                          className="w-full rounded-md border border-gray-300 px-1 shadow-sm outline-darkblue focus:border-none"
                          onChange={() => {
                            console.log("");
                          }}
                          placeholder=""
                        />
                        <input
                          className="w-full rounded-md border border-gray-300 px-1 shadow-sm outline-darkblue focus:border-none"
                          onChange={() => {
                            console.log("");
                          }}
                          placeholder=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabBody>
            <TabBody id="offer">
              <div></div>
            </TabBody>
            <TabBody id="details">
              <div className="grid grid-flow-row gap-2">
                <div>
                  <div className="mb-1 text-sm">{t("main.sell.created.productDescription")}</div>
                  <Input name="material" label={t("pages.marketplace.material")} />
                  <div className="text-sm">{t("components.assignments.color")}</div>
                  <Input name="color" label={t("components.assignments.color")} />
                  <Input name="color_coding" label={t("pages.marketplace.colorMapping")} />
                  <hr className="mb-2 mt-4" />
                  <div className="text-sm">{t("pages.marketplace.capacity")}</div>
                  <Input name="capacity" label={t("pages.marketplace.capacity")} />
                  <Input name="capacity_unit" label={t("pages.marketplace.colorMapping")} />
                  <hr className="mb-2 mt-4" />
                  <div className="text-sm">{t("pages.marketplace.dimension")}</div>
                  <Input name="dim_z" label={t("pages.marketplace.depth")} />
                  <Input
                    name="dim_z_unit"
                    label={`${t("pages.marketplace.measurement")} ${t("pages.marketplace.depth")}`}
                  />
                  <Input name="dim_x" label={t("pages.marketplace.width")} />
                  <Input
                    name="dim_x_unit"
                    label={`${t("pages.marketplace.measurement")} ${t("pages.marketplace.width")}`}
                  />
                  <Input name="dim_y" label={t("pages.marketplace.height")} />
                  <Input
                    name="dim_y_unit"
                    label={`${t("pages.marketplace.measurement")} ${t("pages.marketplace.height")}`}
                  />
                </div>
              </div>
            </TabBody>
            <TabBody id="media">
              <div className="grid grid-flow-row gap-2">
                <div>{t("pages.marketplace.videos")}</div>
                <AdsMedia
                  onChange={data => setVideos(value => [...value, data])}
                  media={videos}
                  onDelete={id => setVideos(data => data.filter(item => item.id !== id))}
                  label={t("pages.marketplace.only2Videos")}
                  accept="video/*"
                  showCount
                  filelimit={2}
                />

                <div>{t("pages.marketplace.images")}</div>
                <AdsMedia
                  onChange={data => setImages(value => [...value, data])}
                  media={images}
                  onDelete={id => setImages(data => data.filter(item => item.id !== id))}
                  label={t("pages.marketplace.only12Images")}
                  accept="image/*"
                  showCount
                  filelimit={12}
                />

                <div>{t("pages.marketplace.documents")}</div>
                <AdsMedia
                  onChange={data => SetDocuments(value => [...value, data])}
                  media={documents}
                  onDelete={id => SetDocuments(data => data.filter(item => item.id !== id))}
                  label={t("pages.marketplace.only8Documents")}
                  accept="text/*"
                  showCount
                  filelimit={8}
                />
              </div>
            </TabBody>
            <TabBody id="rules">
              <div className="grid grid-flow-row gap-2 text-sm">
                <Input name="origin_country" label={t("pages.marketplace.originCountry")} />
                <div className="flex flex-row items-center gap-2">
                  {t("pages.marketplace.batteries")}
                  <input
                    type="radio"
                    name="has_batteries_true"
                    value="true"
                    className="focus:ring-transparent"
                  />
                  <label htmlFor={"has_batteries_true"}>{t("components.shared.yes")}</label>
                  <input
                    type="radio"
                    name="has_batteries_false"
                    value="false"
                    className="focus:ring-transparent"
                  />
                  <label htmlFor="has_batteries_false">{t("components.shared.no")}</label>
                </div>
                <Input name="hazard_notes" label="Gefahrengutschriften" />
                <div className="flex flex-row items-center gap-2">
                  {t("pages.marketplace.fluids")}
                  <input
                    type="radio"
                    name="has_fluids_true"
                    value="true"
                    className="focus:ring-transparent"
                  />
                  <label htmlFor={"has_fluids_true"}>{t("components.shared.yes")}</label>
                  <input
                    type="radio"
                    name="has_fluids_false"
                    value="false"
                    className="focus:ring-transparent"
                  />
                  <label htmlFor="has_fluids_false">{t("components.shared.no")}</label>
                </div>
                {t("pages.marketplace.availabilityPeriod")}
                <Input
                  name="eu_replacement_time"
                  label={t("pages.marketplace.availabilityPeriod")}
                />
                <Input name="eu_replacement_time_unit" label={t("pages.marketplace.euUnit")} />
              </div>
            </TabBody>
          </TabBodies>
        </TabController>

        <div className="grid grid-flow-row gap-2 lg:grid-flow-col">
          <Button
            onClick={() => {
              return;
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("main.groupsInfoForm.buttons.back")}
          </Button>
          <Button
            onClick={() => {
              return;
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("buttons.duplicate")}
          </Button>
          <Button
            onClick={() => {
              return;
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("main.groupsInfoForm.buttons.preview")}
          </Button>
          <Button
            onClick={() => {
              return;
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("main.groupsInfoForm.buttons.save")}
          </Button>
          <Button
            onClick={() => {
              return;
            }}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            {t("buttons.publish")}
          </Button>
        </div>
      </Panel>
    </form>
  );
}

export default MarketplaceCreateContainer;

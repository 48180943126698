import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useAppSelector } from "@src/state/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangeEmailContainer = () => {
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  if (!user) {
    return <></>;
  }

  const hiddenMail = user.email?.slice(0, 5) + "X".repeat(Number(user.email?.length) - 5);

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.panel.profileWidgets.changeEmail")}{" "}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current email"
          label={t("main.panel.profileWidgets.currentEmail")}
          value={hiddenMail}
          placeholder={hiddenMail}
          type="text"
          onChange={e => setCurrentEmail(e)}
          disabled
        />
        <Input
          name="new email"
          label={t("main.panel.profileWidgets.newEmail")}
          value={newEmail}
          placeholder="**************"
          type="text"
          onChange={e => setNewEmail(e)}
        />
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button onClick={() => console.log("email changed")}>
            {t("main.panel.profileWidgets.changeEmail")}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ChangeEmailContainer;

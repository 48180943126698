import api from "@src/api/api";
import { User } from "@src/utils/user";

export declare type ExchangeTokenResponse = {
  jwt: string;
  user: User;
};

export default async function exchangeTemporaryAuthToken(
  oneTimeToken: string,
): Promise<ExchangeTokenResponse> {
  const body = {
    token: oneTimeToken,
  };
  const res = await api.post("/api/v1/auth/social/validate_access", body);

  return {
    jwt: res.data.jwt,
    user: res.data.user,
  };
}

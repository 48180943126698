import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Products from "@src/mocks/AllProducts.json";
import ProductComponent from "@src/pages/marketplace/components/Product";
import ProductRatingHistogram from "@src/pages/marketplace/components/ProductRatingHistogram";
import ProductReviewImage from "@src/pages/marketplace/components/ProductReviewImage";
import ProductReviews from "@src/pages/marketplace/components/ProductReviews";
import ProductsCarousel from "@src/pages/marketplace/components/ProductsCarousel";
import SimilarProduct from "@src/pages/marketplace/components/SimilarProduct";
import { Product } from "@src/utils/product";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import tw from "twin.macro";

const MarketplaceProductReviews = () => {
  const products = Products.allProducts;
  const { id } = useParams();
  const navigate = useNavigate();

  const product = useQuery<Product, Error>([`product-detail-${id}`], async () => {
    const res = await api.get(`/api/v1/marketplace/products/${id}`);
    return res.data;
  });

  if (!product.isSuccess) return <span>{t("main.shop.details.loading")}</span>;

  return (
    <div css={tw`xl:px-[180px]`}>
      <ArrowUturnLeftIcon
        className="ml-auto size-6 cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="flex w-full flex-col max-xl:px-4 md:flex-row">
        <div className="">
          <ProductRatingHistogram product_title={product.data.title} />
        </div>
        <div className="ml-5 flex flex-col">
          <div className="w-[750px]">
            {t("main.shop.details.similarProducts")}
            <ProductsCarousel spaceBetween={20} slidesPerView={2}>
              {products.map(product => (
                <div key={product.ID}>
                  <SwiperSlide>
                    <ProductReviewImage product={product} />
                  </SwiperSlide>
                </div>
              ))}
            </ProductsCarousel>
          </div>
          <div className="mb-4">
            <ProductReviews />
          </div>
        </div>
      </div>
      <div className="pt-2 max-xl:px-4">
        {t("main.shop.details.similarProducts")}
        <div className="w-full">
          <ProductsCarousel spaceBetween={20} slidesPerView={1}>
            {products.map(product => (
              <div key={product.ID}>
                <SwiperSlide className="w-full">
                  <SimilarProduct product={product} />
                </SwiperSlide>
              </div>
            ))}
          </ProductsCarousel>
        </div>
      </div>
      <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-4">
        {products.map(product => (
          <div key={product.ID} className="flex justify-center">
            <ProductComponent product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketplaceProductReviews;

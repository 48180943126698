import {
  ArrowDownTrayIcon,
  CheckIcon,
  FlagIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import api from "@src/api/api";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { showReportModal } from "@src/state/reportModal/actions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  messageId: string;
  isSender: boolean;
  onForward: () => void;
};
const MessageDropdown = ({ messageId, isSender, onForward }: Props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteMessage = useMutation(["delete-message"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/chats/undefined/messages/${messageId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["messages"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <ContextMenu
      items={[
        {
          label: t("pages.messenger.unread"),
          onClick: () => {
            return;
          },
          icon: CheckIcon,
        },
        {
          label: t("pages.messenger.download"),
          onClick: () => {
            return;
          },
          icon: ArrowDownTrayIcon,
        },
        {
          label: t("pages.messenger.forward"),
          onClick: () => onForward(),
          icon: ShareIcon,
        },
        {
          label: t("buttons.report"),
          onClick: () => dispatch(showReportModal(messageId, "message")),
          icon: FlagIcon,
        },
        isSender && {
          label: t("components.shared.remove"),
          onClick: () => deleteMessage.mutate(),
          icon: TrashIcon,
        },
      ]}
    />
  );
};

export default MessageDropdown;

import { ArrowsUpDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import useDebounce from "@src/utils/useDebounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AdvertisementContainer = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Panel mobileBorder={false}>
      <div className="flex w-fit gap-2">
        <Button
          variant={Button.Variants.Transparent}
          onClick={() => navigate("/werbung-erstellen")}
        >
          {t("main.header.links.groups.tabs.create")}
        </Button>
        <Button variant={Button.Variants.Transparent} onClick={() => console.debug("delete")}>
          <TrashIcon className="size-4" />
        </Button>
      </div>
      <Input
        name="search-users"
        placeholder={t("advertisement.searchByAddname")}
        onChange={e => setSearchQuery(e)}
        clearable
      />

      <div className="grid grid-flow-row">
        <div className="grid grid-cols-[30px,1fr,60px,90px,115px,60px] gap-3 bg-slate-100">
          <div></div>
          <div className="flex items-center gap-1">
            {t("advertisement.addName")} <ArrowsUpDownIcon className="size-4" />
          </div>
          <div className="flex items-center gap-1">
            {t("advertisement.status")} <ArrowsUpDownIcon className="size-4" />
          </div>
          <div className="flex items-center gap-1">
            {t("advertisement.expenses")} <ArrowsUpDownIcon className="size-4" />
          </div>
          <div className="flex items-center gap-1">
            {t("advertisement.impression")} <ArrowsUpDownIcon className="size-4" />
          </div>
          <div className="flex items-center gap-1">
            {t("advertisement.clicks")} <ArrowsUpDownIcon className="size-4" />
          </div>
        </div>

        <div className="grid grid-cols-[30px,1fr,60px,90px,115px,60px] gap-x-3 py-0.5 text-xs odd:bg-slate-50 even:bg-slate-200">
          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              className="size-4 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
            />
          </div>
          <div className="truncate"></div>
          <div className="truncate"></div>
          <div className="truncate"></div>
          <div className="truncate"></div>
          <div className="truncate"></div>
        </div>
        <ShowMoreButton onClick={() => console.debug("load more")} />
      </div>
    </Panel>
  );
};

export default AdvertisementContainer;

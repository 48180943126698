import useUser from "@src/api/user/useUser";
import getName from "@src/utils/getName";
import { useTranslation } from "react-i18next";

type Props = {
  userId: string;
};

const ProfileDetailsUser = ({ userId }: Props) => {
  const user = useUser(userId ?? "");
  const { t } = useTranslation();

  if (!user.isSuccess) return <></>;

  return (
    <>
      <div className="flex flex-wrap gap-1">
        {t("main.profileViewTabs.editProfile.form.userName")}
        <div className="font-semibold">{getName(user.data)}</div>
      </div>
    </>
  );
};

export default ProfileDetailsUser;

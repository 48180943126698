import { HeartIcon } from "@heroicons/react/24/outline";
import { JobType } from "@src/utils/types/JobType";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  jobList: JobType[];
};

const JobsHomepage = ({ jobList }: Props) => {
  const { t } = useTranslation();
  const jobPreview = 30;
  const [next, setNext] = useState(jobPreview);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setNext(prevNext => prevNext + jobPreview);
        }
      },
      { threshold: 1.0 },
    );

    if (currentSentinel) {
      observerRef.current.observe(currentSentinel);
    }

    return () => {
      if (observerRef.current && currentSentinel) {
        observerRef.current.unobserve(currentSentinel);
      }
    };
  }, []);

  const calculateDaysAgo = (date: string) => {
    const now = new Date();
    const past = new Date(date);

    if (isNaN(past.getTime())) {
      return "Invalid date";
    }

    const diffTime = Math.abs(now.getTime() - past.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return `${diffDays}`;
  };

  return (
    <>
      <div className="mb-5 flex flex-row">
        <img
          src={"/images/icons/briefcase.svg"}
          className="mb-1 mr-1 flex size-[35px]"
          alt="icon"
        />
        <div className="mt-1 flex flex-col leading-none">
          <div className="font-semibold tracking-tight text-blue-950 dark:text-white">
            {t("main.jobsTabs.jobRecommendationTitle")}
          </div>
          <div className="text-xs">{t("main.jobsTabs.jobRecommendationDescription")}</div>
        </div>
      </div>

      <div className="mb-10 grid gap-4 md:grid-cols-5">
        {jobList?.slice(0, next)?.map((job: JobType) => {
          let demoProfilePhoto;
          if (job.user.type === "company") {
            demoProfilePhoto = "/images/placeholder/company.png";
          } else if (job.user.type === "club") {
            demoProfilePhoto = "/images/placeholder/club.png";
          } else {
            demoProfilePhoto = "/images/placeholder/person.png";
          }
          return (
            <NavLink to={"/jobs/" + job.id} key={job.id}>
              <div className="rounded-lg border border-gray-300 bg-white p-2 shadow transition-transform duration-300 md:hover:scale-105 dark:border-gray-700 dark:bg-gray-800">
                <div className="mb-3 flex items-center justify-between">
                  <div className="mr-1 h-[50px] flex-1 overflow-hidden">
                    <div className="text-xs font-medium leading-tight text-gray-900">
                      {job.title}
                    </div>
                  </div>
                  <div className="flex size-[50px] shrink-0 items-center justify-center rounded-lg border border-gray-300">
                    {job.user.avatar ? (
                      <img
                        className="size-full rounded-lg object-cover"
                        src={job.user.avatar}
                        alt=""
                      />
                    ) : (
                      <img
                        className="size-full rounded-lg object-cover"
                        src={demoProfilePhoto}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="mb-2 flex flex-col">
                  <div className="m-0.5 flex flex-row">
                    <img
                      src={"/images/icons/building-office-2.svg"}
                      className="mr-1 flex size-[12px]"
                      alt="icon"
                    />
                    <span className="truncate text-mini text-gray-700 dark:text-gray-400">
                      {job.company}
                    </span>
                  </div>
                  <div className="m-0.5 flex flex-row">
                    <img
                      src={"/images/icons/map-pin.svg"}
                      className="mr-1 flex size-[12px]"
                      alt="icon"
                    />
                    <span className="text-mini text-gray-700 dark:text-gray-400">{job.state}</span>
                  </div>
                  <div className="m-0.5 flex flex-row">
                    <img
                      src={"/images/icons/home.svg"}
                      className="mr-1 flex size-[12px]"
                      alt="icon"
                    />
                    <span className="text-mini text-gray-700 dark:text-gray-400">
                      {t("main.jobsTabs.homeOffice")}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <span className="text-xs text-gray-500">
                    {t("main.jobsTabs.ago")} {calculateDaysAgo(job.created_at)}{" "}
                    {t("main.jobsTabs.days")}
                  </span>
                  <HeartIcon className="ml-auto mt-1 h-5 text-gray-500" />
                </div>
              </div>
            </NavLink>
          );
        })}
      </div>
      <div ref={sentinelRef} className="h-1 w-full"></div>
    </>
  );
};

export default JobsHomepage;

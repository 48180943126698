import api from "@src/api/api";
import PostWidget from "@src/components/elements/PostWidget";
import SuggestionsContainer from "@src/components/elements/sidebar/suggestions/SuggestionsContainer";
import { Post } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
  userId?: string;
};

const PostsContainer = ({ searchQuery, userId = "" }: Props) => {
  const [searchParams] = useSearchParams();
  const [lightboxSelection, setLightboxSelection] = useState("");
  const posts = useInfiniteQuery<Response>(
    ["posts", searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed?page=" +
          pageParam +
          "&q=" +
          searchQuery +
          "&featured_post_id=" +
          searchParams.get("featured") +
          "&user_id=" +
          userId,
      );
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      posts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (!posts.isSuccess) return <></>;

  return (
    <>
      {posts.data.pages.map(
        (page, pageIndex) =>
          page &&
          page.data.map((post: Post, index: number) => {
            return (
              <>
                {[6, 13, 20, 27].includes(index) && <SuggestionsContainer />}
                <div
                  className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
                  key={post.id}
                >
                  <PostWidget
                    postId={post.id}
                    post={post}
                    text={post.text}
                    author={post.author}
                    repost={post.reposted}
                    created_at={post.created_at}
                    onSwitch={(id: string) => {
                      setLightboxSelection(id);
                    }}
                    lightboxSelection={lightboxSelection}
                    prev={
                      index == 0 && pageIndex > 0
                        ? posts.data.pages[pageIndex - 1].data[
                            posts.data.pages[pageIndex - 1].data.length - 1
                          ].id
                        : index > 0
                          ? posts.data.pages[pageIndex].data[index - 1].id
                          : ""
                    }
                    next={
                      pageIndex + 1 < posts.data.pages.length &&
                      index + 1 == posts.data.pages[pageIndex].data.length
                        ? posts.data.pages[pageIndex + 1].data[0].id
                        : index + 1 < posts.data.pages[pageIndex].data.length
                          ? posts.data.pages[pageIndex].data[index + 1].id
                          : ""
                    }
                  />
                </div>
              </>
            );
          }),
      )}
    </>
  );
};

export default PostsContainer;

import {
  ArrowUturnRightIcon,
  BookmarkIcon,
  DocumentIcon,
  FlagIcon,
  MinusCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { showReportModal } from "@src/state/reportModal/actions";
import { JobType } from "@src/utils/types/JobType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  job: JobType;
  onDownloadStart?: () => void;
  onDownloadEnd?: () => void;
  onDownloadProgress?: (progress: number) => void;
};

const JobsDropDown = ({ job, ...props }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteJob = useMutation(["delete-job"], {
    mutationFn: async (jobId: string) => {
      const res = await api.delete(`/api/v1/jobs/${jobId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
  });

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/jobs/${job.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/jobs/${job.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const block = useMutation([`block-${job.user_id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${job.user_id}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblock = useMutation([`unblock-${job.user_id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${job.user_id}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      <ContextMenu
        showCircle
        items={[
          user &&
            user.id === job.user_id && {
              label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
              onClick: () => setOpenEdit(true),
              icon: PencilIcon,
            },
          user &&
            (user.id === job.user_id || user.admin) && {
              label: t("components.shared.remove"),
              onClick: () => deleteJob.mutate(job.id),
              icon: TrashIcon,
            },
          {
            label: t("buttons.report"),
            onClick: () => dispatch(showReportModal(job.id, "job")),
            icon: FlagIcon,
          },
          {
            label: t("buttons.block"),
            onClick: () => block.mutate(),
            icon: MinusCircleIcon,
          },
          {
            label: t("buttons.save"),
            onClick: () => (job.bookmarked ? remove.mutate() : add.mutate()),
            icon: job.bookmarked ? BookmarkIconSolid : BookmarkIcon,
          },

          user?.type === "person" && {
            label: t("buttons.apply"),
            onClick: () => {
              return null;
            },
            icon: DocumentIcon,
          },
          user?.type === "person" && {
            label: t("main.shop.buttons.contact"),
            onClick: () => {
              return null;
            },
            icon: ArrowUturnRightIcon,
          },
        ]}
      />
    </>
  );
};

export default JobsDropDown;

import { StatusBar, Style } from "@capacitor/status-bar";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  onClose: () => void;
  imageUrl: string;
  imageCSS?: TwStyle;
  backgroundCSS?: TwStyle;
};

const FullScreenImage = (props: Props) => {
  useEffect(() => {
    StatusBar.setStyle({ style: Style.Dark });
    return () => {
      StatusBar.setStyle({ style: Style.Light });
    };
  }, []);

  return (
    <div className="fixed left-0 top-0 z-[500] size-full" onClick={props.onClose}>
      <div css={[tw`absolute top-0 left-0 w-full h-full bg-black`, props.backgroundCSS]}></div>
      <button
        onClick={props.onClose}
        className="absolute right-5 top-5 mt-[env(safe-area-inset-top)]"
      >
        <XMarkIcon className="size-8 text-white" />
      </button>
      <div className="absolute left-0 top-[calc(50%+env(safe-area-inset-top))] w-full -translate-y-1/2">
        <div className="overflow-scrolling[touch] relative mt-3 flex snap-x snap-mandatory overflow-x-auto overflow-y-hidden scroll-smooth scrollbar-none">
          <div className="relative cursor-pointer">
            <div className="relative w-screen">
              <img
                height="100%"
                width="100%"
                css={[
                  tw`mx-auto max-h-[calc(calc(100dvh-120px)-env(safe-area-inset-top))] h-screen max-w-[90vw] object-contain`,
                  props.imageCSS,
                ]}
                src={props.imageUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenImage;

import api from "@src/api/api";
import usePaymentModal from "@src/state/modal/usePaymentModal";
import { setUser } from "@src/state/user/userSlice";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

async function me(): Promise<User> {
  const res = await api.get("/api/v1/me");
  return res.data.data;
}

const API_BASE_URL = import.meta.env.VITE_API_URL || "";

export const useMe = (token?: string) => {
  const dispatch = useDispatch();
  const paymentModal = usePaymentModal();

  const me = useQuery(["me", "user"], async () => {
    const headers: HeadersInit = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};

    const res = await api.get(`${API_BASE_URL}/api/v1/me`, { headers });

    return res.data.data;
  });

  if (token) {
    if (me.isSuccess && me.data) {
      dispatch(setUser(me.data));
      if (!me.data.payment_active && paymentModal.isOpen) paymentModal.open();
      else if (me.data.payment_active) paymentModal.close();
    } else {
      dispatch(setUser(null));
    }
  }

  return me;
};

export default me;

import api from "@src/api/api";

export const checkUsername = async (username: string) => {
  try {
    const response = await api.get(
      `/api/v1/users/username/available?username=${encodeURIComponent(username)}`,
    );
    return response.data.available;
  } catch (error) {
    console.error("Failed to check username availability", error);
    throw error;
  }
};

export default checkUsername;

import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = { key?: string };

type Response = {
  data: any[];
};

const AssignmentsSuggestions = ({ key }: Props) => {
  const { t } = useTranslation();
  const assignments = useQuery<Response, Error>(["assignments"], async () => {
    const { data } = await api.get("/api/v1/assignments");
    return data;
  });

  if (!assignments.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularAdds")} mobileBorder={false} key={key}>
        <div className="no-scrollbar flex gap-4 overflow-x-auto">
          {assignments.data.data.map(item => (
            <SuggestionsCenterItem
              key={item.id}
              name={item.title}
              link={"/inserate/" + item.id}
              picture={item.user.avatar || `/images/placeholder/${item.user.type}.png`}
              imageStyle={tw`rounded-xl w-[100px] h-[150px] object-cover`}
              nameStyle={tw`w-[100px]`}
            />
          ))}
          <NavLink to="/inserate/">
            <div className="flex h-[150px] w-[100px] flex-col justify-center text-center">
              {t("buttons.showMore")}
            </div>
          </NavLink>
        </div>
      </Panel>
    </div>
  );
};
export default AssignmentsSuggestions;

import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import { Media } from "@src/utils/types/Media";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTus } from "use-tus";

type Props = {
  onChange: (media: Media) => void;
  onDelete: (id: string) => void;
  media: Array<Media>;
  addClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
};

const PostMediaWatch = ({ onChange, media, onDelete, addClick }: Props) => {
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadstatus, setUploadstatus] = useState<any>({});
  const { upload, setUpload, isSuccess, error, remove } = useTus();
  const { t } = useTranslation();
  const errorTranslation = {
    copyright: {
      title: t("pages.postMedia.copyrightTitle"),
      explanation: t("pages.postMedia.copyrightText"),
    },
    interrupted: {
      title: t("pages.postMedia.interruptedTitle"),
      explanation: t("pages.postMedia.interruptedText"),
    },
    generic: {
      title: t("pages.postMedia.genericTitle"),
      explanation: "",
    },
    novideo: {
      title: t("pages.postMedia.noVideoTitle"),
      explanation: t("pages.postMedia.noVideoText"),
    },
  };

  useEffect(() => {
    if (upload) {
      console.log("upload is starting", upload);
      upload.start();
    }
  }, [upload]); // This will run every time `upload` changes

  const uploadVideo = useMutation(["upload"], {
    mutationFn: async (file: File) => {
      let key = "";
      let id = "";
      let endpoint = "";
      let thumbnail = "";
      let tus_headers = {};
      if (file.type.startsWith("video")) {
        const directUploadResponse = await api.get("/api/v1/video/upload/direct");

        endpoint = directUploadResponse.data.data.endpoint;
        tus_headers = directUploadResponse.data.data.tus_headers;
        key = directUploadResponse.data.data.key;
        id = directUploadResponse.data.data.id;
        thumbnail = directUploadResponse.data.data.thumbnail;
        const formData = new FormData();
        formData.append("file", file);
        if (!file) {
          return;
        }

        setUpload(file, {
          endpoint: endpoint,
          headers: tus_headers,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
        });
      }
      setUploadingCount(count => count - 1);
      return {
        key,
        id,
        file_name: file.name,
        type: file.type.startsWith("video") ? "video" : "image",
        thumbnail,
      };
    },
    onSuccess: data => {
      if (!data) {
        return;
      }
      setUploadstatus({ errors: [] });
      onChange({
        id: data.id,
        key: data.key,
        file_name: data.file_name,
        type: data.type,
        thumbnail: data.thumbnail,
        data_url: "",
      });
    },
    onError: () => {
      setUploadstatus({
        errors: [
          {
            debug: "",
            type: "generic",
          },
        ],
      });
      setUploadingCount(count => count - 1);
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const addMedia = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  if (addClick?.setFunction && !addClick?.getFunction) {
    addClick.setFunction(() => {
      return addMedia;
    });
  }

  return (
    <>
      <div className="text-sm font-semibold">Video</div>
      <div className="no-scrollbar flex gap-3 overflow-x-auto rounded-xl lg:border lg:p-3">
        {media.map(data => {
          return (
            <div key={data.id} className="relative aspect-[9/16] h-[80px] lg:h-[100px]">
              <img
                className="aspect-[9/16] h-[80px] rounded-xl bg-gray-200 object-cover lg:h-[100px]"
                src={data.thumbnail}
              />
            </div>
          );
        })}
        {[...Array(uploadingCount)].map((_, index) => (
          <div key={index} className="aspect-[9/16] h-[80px] rounded-xl bg-gray-200 lg:h-[100px]">
            <svg className="mr-3 size-5 animate-spin" viewBox="0 0 24 24"></svg>
          </div>
        ))}
      </div>
      {!addClick && (
        <div className="ml-auto w-fit">
          <Button
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
            onClick={() => {
              fileInputRef.current && fileInputRef.current.click();
            }}
          >
            {t("main.watchTabs.create.buttons.addVideo")}
          </Button>
        </div>
      )}
      <input
        type="file"
        accept="video/*"
        onChange={e => {
          if (e.target.files && e.target.files.length + uploadingCount + media.length > 10) {
            alert("Max 10 Videos");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount(count => count + 1);
            uploadVideo.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
      {uploadstatus.errors && uploadstatus.errors.length === 0 && (
        <div className="items-top grid grid-cols-[25px,auto] border px-3 py-1 align-top text-sm">
          {/* <CheckIcon className="w-5 h-5 text-green-600" />
          <div className="flex flex-wrap">
            <div>Der Upload war erfolgreich und fehlerfrei.</div>
          </div> */}
          <CheckIcon className="size-5 text-green-600" />
          <div className="flex flex-wrap">
            <div>{t("pages.postMedia.noCopyrightIssues")}</div>
          </div>
        </div>
      )}
      {uploadstatus.errors && uploadstatus.errors.length != 0 && (
        <div className="items-top grid grid-cols-[25px,auto] border px-3 py-1 align-top text-sm">
          {uploadstatus.errors.map(
            (error: { type: "copyright" | "interrupted" | "novideo" | "generic" }) => {
              return (
                <>
                  <XMarkIcon className="size-5 text-red-600" />
                  {(error.type === "copyright" ||
                    error.type === "interrupted" ||
                    error.type === "generic" ||
                    error.type === "novideo") && (
                    <div className="flex flex-col">{errorTranslation[error.type].title}</div>
                  )}
                </>
              );
            },
          )}
        </div>
      )}
    </>
  );
};

export default PostMediaWatch;

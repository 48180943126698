import { User } from "@src/utils/user";
import React from "react";

type Props = { user: User; minimized?: boolean };

const CompanyProfileContainer = ({ user, minimized = true }: Props) => {
  const InfoField: React.FC<{
    icon: string;
    label: string;
    value?: string;
  }> = ({ icon, label, value }) => (
    <div className="grid grid-cols-[20px,1fr] items-center">
      <img src={icon} className="size-[15px]" />
      <div className="font-semibold">{label}:</div>
      <div />
      <div>{value}</div>
    </div>
  );
  const fields = [
    {
      icon: "/images/icons/user.svg",
      label: "Unternehmensname",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Rechsform",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Handelsregister-Nummer",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Umsatzsteuer-Id",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Jahresumsatz",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Zahl der Mitarbeiter",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Tätigkeitsfeld",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Keywords",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "Impressum",
      value: "Test data",
    },
    {
      icon: "/images/icons/user.svg",
      label: "AGB",
      value: "Test data",
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {fields.map(field => (
        <>
          <InfoField icon={field.icon} label={field.label} value={field.value} />
        </>
      ))}
    </div>
  );
};
export default CompanyProfileContainer;

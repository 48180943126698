import api from "@src/api/api";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { ContactsType } from "@src/utils/types/ContactsType";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

const ActivateContainer = () => {
  const [searchParams] = useSearchParams();

  const activate = useQuery<ContactsType, Error>(
    ["activate"],
    async () => {
      const { data } = await api.get("/api/v1/activate?code=" + searchParams.get("code"));
      return data;
    },
    { retry: false },
  );

  return (
    <AuthWrapper>
      {activate.isSuccess && "Success"}
      {activate.isError && "Error"}
      {activate.isLoading && "Loading..."}
    </AuthWrapper>
  );
};

export default ActivateContainer;

import api from "@src/api/api";
import HashtagItem from "@src/components/elements/hashtags/HashtagItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Hashtag = {
  total_views: number;
  times_used: number;
  first_user_id: string;

  total_like_count: number;
  hashtag: string;

  media_id?: string;
  media_key?: string;
  media_type?: string;
  media_filename?: string;
  media_thumbnail?: string;
  media_data_url?: string;

  author_name: string;
  author_id: string;
  author_avatar: string;
  author_type: string;
};

type Response = {
  data: Array<Hashtag>;
  current_page: number;
  total_pages: number;
};

type Props = {
  filter: string;
};

const HashtagList = ({ filter }: Props) => {
  const watch = filter === "watch";
  const hashtags = useInfiniteQuery<Response>(
    ["hashtags", watch],
    async ({ pageParam = 0 }) => {
      const res = await api.get("/api/v1/hashtags?page=" + pageParam + "&watch=" + watch);
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (hashtags.isFetchingNextPage || !hashtags.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      hashtags.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (!hashtags.isSuccess) return <></>;

  const getImageSrc = (hashtag: Hashtag) => {
    if (hashtag.media_type !== "video" && hashtag.media_key && hashtag.media_filename) {
      return hashtag.media_data_url;
    }
    if (hashtag.media_type === "video" && hashtag.media_thumbnail) {
      return hashtag.media_thumbnail.replace("?height=184", "?height=600");
    }
    return undefined;
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {hashtags.data.pages.map(
          page =>
            page.data &&
            page.data.map((hashtag: Hashtag) => {
              return (
                <HashtagItem
                  tab={filter}
                  key={hashtag.hashtag}
                  tag={hashtag.hashtag}
                  type={hashtag.media_type}
                  viewCount={hashtag.total_views}
                  likeCount={hashtag.total_like_count}
                  image={getImageSrc(hashtag)}
                  authorName={hashtag.author_name}
                  authorAvatar={hashtag.author_avatar}
                />
              );
            }),
        )}
      </div>
    </>
  );
};

export default HashtagList;

import {
  ArrowDownOnSquareIcon,
  ArrowLeftIcon,
  FilmIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import {
  LockClosedIcon,
  PhoneIcon,
  PhotoIcon,
  SpeakerXMarkIcon,
  StarIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import api from "@src/api/api";
import UserWidget from "@src/components/elements/UserWidget";
import FormTextarea from "@src/components/elements/form/FormTextarea";
import InfoButton from "@src/pages/messenger/group/InfoButton";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import isMobile from "@src/utils/isMobile";
import { ChatType } from "@src/utils/types/ChatType";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  userId: string;
  onClose?: () => void;
};

const UserInfo = ({ userId, onClose }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.user);
  const [description, setDescription] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const chat = useQuery<ChatType, Error>(["chat-" + userId], async () => {
    const { data } = await api.get("/api/v1/chats/" + userId);
    return data;
  });

  const getChatPartner = (members: Array<{ user: User; archived: boolean }>, self: User) => {
    for (const member of members) {
      if (self.id !== member.user.id) {
        return member;
      }
    }
    return members[0];
  };

  if (!chat.isSuccess) return <></>;
  if (!user) return <></>;
  const partner = getChatPartner(chat.data.members, user);

  return (
    <div
      css={[
        tw`flex flex-col overflow-auto h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] lg:h-[calc(100dvh-82px)] relative`,
        isMobile
          ? tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`
          : tw`border border-gray-300 rounded-2xl relative px-3`,
      ]}
      className="no-scrollbar"
    >
      <div className="px-0.5 pt-3">
        <div className="grid grid-flow-row gap-3 text-sm">
          <button
            className="absolute left-0 flex items-center justify-center px-2"
            onClick={onClose}
          >
            <ArrowLeftIcon className="size-8 rounded-full hover:bg-gray-300" />
          </button>
          <div className="flex flex-row items-center gap-4 text-center text-xs">
            <div className="flex w-full cursor-pointer flex-col items-center justify-center gap-2">
              <UserWidget user={partner.user} follow={false} online={true} />
              <div className="my-auto line-clamp-1 break-all text-sm font-bold text-gray-600">
                {getName(partner.user)}
              </div>
            </div>
          </div>
          <div className="grid grid-flow-col gap-2 text-xs">
            <button className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1">
              <PhoneIcon className="size-4" />
              {t("pages.messenger.audio")}
            </button>
            <button className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1">
              <VideoCameraIcon className="size-4" />
              {t("buttons.video")}
            </button>
            <button
              className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1"
              onClick={() => {
                onClose && onClose();
              }}
            >
              <MagnifyingGlassIcon className="size-4 stroke-[3px]" />
              {t("pages.messenger.search")}
            </button>
          </div>

          {!showDescription && (
            <button
              className="rounded-xl border border-gray-300"
              onClick={() => {
                setShowDescription(true);
              }}
            >
              {t("pages.messenger.addDescription")}
            </button>
          )}
          {showDescription && (
            <FormTextarea
              onChange={e => setDescription(e)}
              value={description}
              name="description"
            />
          )}
          <div className="grid grid-flow-row">
            <InfoButton
              label={t("pages.messenger.media")}
              icon={<PhotoIcon className="size-6 rounded-md bg-white p-px text-blue-700" />}
            />
            <InfoButton
              label={t("pages.messenger.starred")}
              icon={<StarIcon className="size-6 rounded-md bg-yellow-500 text-white" />}
            />
          </div>
          <div className="grid grid-flow-row">
            <InfoButton
              label={t("pages.messenger.archivedChats")}
              icon={<SpeakerXMarkIcon className="size-6 rounded-md bg-gray-600 p-px text-white" />}
            />
            <InfoButton
              label={t("pages.messenger.backgroundSound")}
              showArrow
              icon={<FilmIcon className="size-6 rounded-md bg-red-500 p-px text-white" />}
            />
            <InfoButton
              label={t("pages.messenger.save")}
              value="Standard"
              icon={
                <ArrowDownOnSquareIcon className="size-6 rounded-md bg-yellow-500 p-px text-white" />
              }
              showArrow={false}
            />
          </div>
          <div className="grid grid-flow-row">
            <InfoButton
              label={t("pages.messenger.encrypted")}
              subLabel={t("pages.messenger.encryptedSub")}
              showArrow
              icon={<LockClosedIcon className="size-6 rounded-md bg-blue-700 text-white" />}
            />
            <InfoButton
              label={t("pages.messenger.selfextinguishing")}
              showArrow
              value="Aus"
              icon={<FilmIcon className="size-6 rounded-md bg-blue-700 p-px text-white" />}
            />
            <InfoButton
              label={t("pages.messenger.chatBlock")}
              showArrow
              icon={<NoSymbolIcon className="size-6 rounded-md bg-gray-600 p-px text-white" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

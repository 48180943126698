import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import getEmployeeInformation from "@src/utils/getEmployeeInformation";
import { User } from "@src/utils/user";
import { useTranslation } from "react-i18next";
type Props = { user: User; minimized?: boolean };
const EmployeeContainer = ({ user, minimized = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <Panel>
        <div>
          <div className="flex flex-col text-sm">
            <UserWidget user={user} showName={true} follow={false} verified={true} />

            {!minimized && (
              <div className="flex w-full flex-col gap-1">
                <div className="flex flex-col justify-between text-xs">
                  <div className="flex flex-row gap-x-1">
                    <div className="truncate"></div>
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      dangerouslySetInnerHTML={getEmployeeInformation(user, {
                        department: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      dangerouslySetInnerHTML={getEmployeeInformation(user, {
                        jobPosition: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      dangerouslySetInnerHTML={getEmployeeInformation(user, {
                        website: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      dangerouslySetInnerHTML={getEmployeeInformation(user, {
                        phoneNumber: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div>{user.email}</div>
                  </div>
                </div>
              </div>
            )}
            {minimized && (
              <div>
                <div className="flex flex-row gap-x-1">
                  <div
                    dangerouslySetInnerHTML={getEmployeeInformation(user, {
                      department: true,
                    })}
                  />
                </div>
                <div className="flex flex-row gap-x-1">
                  <div
                    dangerouslySetInnerHTML={getEmployeeInformation(user, {
                      jobPosition: true,
                    })}
                  />
                </div>
                <div className="flex flex-row gap-x-1">
                  <div
                    dangerouslySetInnerHTML={getEmployeeInformation(user, {
                      website: true,
                    })}
                  />
                </div>
                <div className="flex flex-row gap-x-1">
                  <div
                    dangerouslySetInnerHTML={getEmployeeInformation(user, {
                      phoneNumber: true,
                    })}
                  />
                </div>
                <div className="flex flex-row gap-x-1">
                  <div
                    dangerouslySetInnerHTML={getEmployeeInformation(user, {
                      email: true,
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default EmployeeContainer;

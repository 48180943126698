import { MinusCircleIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import { useMe } from "@src/api/auth/me";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import Button from "@src/components/elements/input/Button";
import ContactsFollowButton from "@src/pages/contacts/buttons/ContactsFollowButton";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import getName from "@src/utils/getName";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { TwStyle } from "twin.macro";

type Props = {
  user: User;
  follow?: boolean;
  sideName?: boolean;
  online?: boolean;
  size?: number;
  verified?: boolean;
  key?: string;
  nameStyle?: TwStyle;
};

const UserDetailsWidget = ({ user, verified = false }: Props) => {
  const me = useMe();
  const self = me.isSuccess && me.data.id === user.id;
  const placeholder = `/images/placeholder/${user.type}.png`;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const unblock = useMutation([`unblock-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-my_followers"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div className="flex flex-col gap-1 rounded-xl border bg-white p-2 text-black">
      <div className="flex flex-row px-1 text-base font-semibold text-gray-900 dark:text-white">
        <div>
          <img
            className="size-12 rounded-full"
            src={user?.avatar || placeholder}
            alt="Neil image"
          />
        </div>
        <div className="ml-auto w-24">
          {user.blocked ? (
            <Button
              onClick={() => {
                if (user.blocked) {
                  unblock.mutate();
                } else {
                  dispatch(showBlockUserModal(user.id));
                }
              }}
              size={Button.Sizes.Small}
              variant={Button.Variants.Transparent}
            >
              <MinusCircleIcon className="size-4 shrink-0" />

              {user.blocked ? t("buttons.unblock") : t("buttons.block")}
            </Button>
          ) : (
            !self && <ContactsFollowButton user={user} followerId={user.id} />
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="truncate">
          <NavLink to={`/profile/${user.id}`} className="group">
            <div className="flex items-center">
              <div className="ms-2 min-w-0 flex-1">
                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                  {(getName(user) || "").length > 30
                    ? `${(getName(user) || "").substring(0, 30)}...`
                    : getName(user)}
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                  @
                  {(user.normalised_account_name || "").length > 20
                    ? `${(user.normalised_account_name || "").substring(0, 20)}...`
                    : user.normalised_account_name}
                  {verified && <VerifyBadge type={user.type} />}
                </p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-4 p-2 text-xs">
        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/likes"}>{t("main.likes.title")}</NavLink>
          </div>
          {user.like_count}
        </div>

        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/follows"}>{t("main.followers.title")}</NavLink>
          </div>
          {user.follower_count}
        </div>

        <div className="grid grid-rows-2 text-center">
          <div className="font-semibold">
            <NavLink to={"/profile/" + user.id + "/following"}>{t("main.following.title")}</NavLink>
          </div>
          {user.following_count}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsWidget;

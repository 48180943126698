import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import FormInput from "@src/components/elements/form/FormInput";
import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import BottomJobCreateTab from "@src/components/elements/jobs/form/BottomJobCreateTab";
import JobMedia from "@src/components/elements/jobs/form/shared/JobMedia";
import WorkTimeSelector from "@src/components/elements/jobs/form/shared/elements/WorkTimeSelector";
import driverLicenseOptions from "@src/components/elements/jobs/shared/options/driverLicenseOptions";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import SalutationSelect from "@src/components/elements/shared/inputs/SalutationSelect";
import Tab from "@src/components/menu/Tab";
import TabFilter from "@src/components/menu/TabFilter";
import JobsDetails from "@src/pages/jobs/JobsDetails";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import { JobType } from "@src/utils/types/JobType";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const CreateJob = () => {
  const [createFilter, setCreateFilter] = useState<string>("basics");
  const { user } = useAppSelector(state => state.user);

  const [form, setForm] = useState<JobType>({
    id: "",
    user_id: user?.id ?? "",
    user: user ?? {
      id: "",
      admin: false,
      username: "",
      type: "company",
      follower_count: 0,
      following_count: 0,
      job_count: 0,
      like_count: 0,
      liked: false,
      watch_count: 0,
      post_count: 0,
      normalised_account_name: "",
      website: "",
    },
    old_id: 0,
    occupation: "",
    zip_code: "",
    city: "",
    state: "",
    country: "",
    start_date: "",
    weekly_hours: 0,
    limitation: null,
    limitation_date: "",
    disability: false,
    description: "",
    title: "",
    social_security_required: false,
    working_place: "",
    radius: "",
    compensation: "",
    earlystart: "",
    standard_wage: false,
    standard_wage_details: "",
    type: "",
    education_note: "",
    skills: "",
    latestart: "",
    language: "",
    limitation_data: "",
    personal_strengths: "",
    work_experience: "",
    leadership: "",
    power_of_attorney: "",
    leadership_experience: false,
    budget_responsibility: false,
    staff_responsibility: false,
    montage: false,
    drivers_license: "",
    note: "",
    company: "",
    contact_user_id: null,
    contact_user: null,
    application_type: "",
    created_at: "",
    media: null,
    bookmarked: false,
    travel: false,
  });
  const { t } = useTranslation();
  const popupModal = usePopupModal();
  const [notesBody, setNotesBody] = useState<string>("");
  const [educationNoteBody, setEducationNoteBody] = useState<string>("");
  const [messageBody, setMessageBody] = useState<string>("");

  const createTabs = [
    { name: t("main.navLinks.jobsPublished.jobInfo"), key: "basics" },
    { name: t("main.navLinks.jobsPublished.requirement"), key: "requirements" },
    { name: t("main.navLinks.jobsPublished.contactDetails"), key: "contact" },
  ];
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);
  const [addMediaClick, setAddMediaClick] = useState<() => void>();
  const [progress, setProgress] = useState(0);

  const create = useMutation(["create-job"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/jobs/", form);
      return res.data;
    },
    onSuccess: data => {
      setForm((prev: any) => ({
        ...prev,
        id: data.id,
      }));
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.OK}
          message={t("components.createJob.alertmsg")}
          title={t("components.createJob.alerttitle")}
        />,
      );
    },
    onError: (error: any) => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          variant={Alert.MessageVariants.Error}
          message={error?.message}
          title={t("components.createJob.alerttitle")}
        />,
      );
    },
  });

  const onChange = (name: string, value: unknown) => {
    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const contactPerson = [
    { name: "Max Müller", value: "1" },
    { name: "Jon Doe", value: "2" },
    { name: "Arya Willis", value: "3" },
    { name: "Bridget Osborne", value: "4" },
    { name: "Raya Tran", value: "5" },
  ];

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        console.log(e);
      }}
    >
      <TabFilter
        value={createFilter}
        options={createTabs}
        onChange={value => setCreateFilter(value)}
        subTab={true}
        centered
      />

      <Panel>
        <Tab isActive={createFilter === "basics"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <PostUserSelect />
            <FormInput
              value={form.title}
              name="title"
              placeholder={t("main.navLinks.jobsPublished.jobofferTitle")}
              onChange={onChange}
            />
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <FormInput
                value={form.occupation}
                name="occupation"
                placeholder={t("main.navLinks.jobsPublished.occupation")}
                onChange={onChange}
              />
              <Checkbox
                name="social_security_required"
                onChange={e => onChange("social_security_required", e)}
                checked={form.social_security_required || false}
              >
                {t("main.navLinks.jobsPublished.jobSocialSecurityRequired")}
              </Checkbox>
            </div>
            <div className="grid grid-cols-[auto,1fr] items-center gap-2 lg:grid-cols-2">
              <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1">
                <input
                  type="radio"
                  id="limited"
                  name="limited"
                  className="focus:ring-transparent"
                  onChange={e => onChange("limitation", true)}
                />
                {t("main.navLinks.jobsPublished.limited")}
                <input
                  type="radio"
                  id="limited"
                  name="limited"
                  className="focus:ring-transparent"
                  onChange={e => onChange("limitation", false)}
                />
                {t("main.navLinks.jobsPublished.unlimited")}
              </div>
              <div css={[!form["limitation"] && tw`hidden`]}>
                <Input
                  type="date"
                  name="limitation_data"
                  placeholder={t("main.navLinks.jobsPublished.limitationDate")}
                  label={t("main.navLinks.jobsPublished.limitationDate")}
                  value={form.limitation_data || ""}
                  onChange={e => onChange("limitation_data", e)}
                />
              </div>
            </div>
            <div className="grid grid-flow-row items-end gap-2 lg:grid-cols-2">
              <WorkTimeSelector value={form.type} onChange={onChange} />
              <Input
                name="hours"
                placeholder={t("main.navLinks.jobsPublished.weeklyHours")}
                value={form.weekly_hours || ""}
                onChange={e => onChange("weekly_hours", e)}
              />
            </div>
            <div className="grid grid-flow-row items-end gap-2 lg:grid-cols-2">
              <Input
                type="date"
                name="earlystart"
                placeholder={t("main.navLinks.jobsPublished.jobStartDate")}
                label={t("main.navLinks.jobsPublished.jobStartDate")}
                value={form.earlystart || ""}
                onChange={e => onChange("earlystart", e)}
              />
              <Input
                type="date"
                name="latestart"
                placeholder={t("main.navLinks.jobsPublished.latestart")}
                label={t("main.navLinks.jobsPublished.latestart")}
                value={form.latestart || ""}
                onChange={e => onChange("latestart", e)}
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <div />
              <Checkbox
                name="tariffbound"
                onChange={e => onChange("standard_wage", e)}
                checked={form.standard_wage || false}
              >
                {t("main.navLinks.jobsPublished.wageAgreementBound")}
              </Checkbox>
            </div>
            <div className="grid grid-flow-row gap-2 lg:grid-cols-2">
              <Input
                name="compensation"
                placeholder={t("main.navLinks.jobsPublished.compensation")}
                value={form.compensation || ""}
                onChange={e => onChange("compensation", e)}
              />
              <Input
                name="Tariffcontract"
                placeholder={t("main.navLinks.jobsPublished.tarifcontract")}
                value={form.standard_wage_details || ""}
                onChange={e => onChange("standard_wage_details", e)}
              />
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.jobdescription")}</div>
            <div className="grid grid-flow-row gap-1">
              <TextareaAutosize
                value={messageBody}
                minRows={4}
                maxRows={800}
                onChange={e => setMessageBody(e.target.value)}
                css={[tw`block w-full rounded-xl bg-gray-100 border-0 text-sm break-all`]}
              />
            </div>
          </div>
          <JobMedia
            onChange={data => setMedia(value => [...value, data])}
            media={media}
            onDelete={id => setMedia(data => data.filter(item => item.id !== id))}
            addClick={{
              getFunction: addMediaClick,
              setFunction: setAddMediaClick,
            }}
          />
        </Tab>

        <Tab isActive={createFilter === "requirements"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <div className="grid grid-flow-row gap-1">
              <Input
                name="education"
                placeholder={t("main.navLinks.jobsPublished.education")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    education: e,
                  }))
                }
              />
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.trainingComments")}</div>
            <div className="grid grid-flow-row gap-1">
              <TextareaAutosize
                name="education_note"
                value={educationNoteBody}
                minRows={4}
                maxRows={800}
                onChange={e => setEducationNoteBody(e.target.value)}
                css={[tw`block w-full rounded-xl bg-gray-100 border-0 text-sm break-all`]}
              />
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.skills")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="skills"
                placeholder={t("main.navLinks.jobsPublished.proficiencySkills")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    skills: e,
                  }))
                }
              />
              <Input
                name="language"
                placeholder={t("main.navLinks.jobsPublished.languages")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    language: e,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="strenghts"
                placeholder={t("main.navLinks.jobsPublished.personalStrengths")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    personal_strengths: e,
                  }))
                }
              />
              <Input
                name="experience"
                placeholder={t("main.navLinks.jobsPublished.workExperience")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    work_experience: e,
                  }))
                }
              />
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.leadershipSkill")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="leadskills"
                placeholder={t("main.navLinks.jobsPublished.leadskills")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    leadership: e,
                  }))
                }
              />
              <Input
                name="authorization"
                placeholder={t("main.navLinks.jobsPublished.authority")}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-2">
              <Checkbox
                name="teamlead"
                onChange={e => onChange("teamlead", e)}
                checked={form.leadership_experience || false}
              >
                {t("main.navLinks.jobsPublished.leadershipExperience")}
              </Checkbox>
              <Checkbox
                name="budget"
                onChange={e => onChange("budget", e)}
                checked={form.budget_responsibility || false}
              >
                {t("main.navLinks.jobsPublished.budgetResponsibility")}
              </Checkbox>
            </div>
            <div className="grid grid-cols-2 items-center gap-2">
              <Checkbox
                name="humanresources"
                onChange={e => onChange("humanresources", e)}
                checked={form.staff_responsibility || false}
              >
                {t("main.navLinks.jobsPublished.personalResponsibility")}
              </Checkbox>
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.mobility")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Checkbox
                name="travel"
                onChange={e => onChange("travel", e)}
                checked={form.travel || false}
              >
                {t("main.navLinks.jobsPublished.travelReady")}
              </Checkbox>
              <Select
                items={driverLicenseOptions}
                name="driverslicense"
                label={t("main.navLinks.jobsPublished.driversLicense")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    drivers_license: e.target.value,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="location"
                placeholder={t("main.navLinks.jobsPublished.workplace")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    working_place: e,
                  }))
                }
              />
              <Input
                name="range"
                placeholder="Umkreis"
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    radius: e,
                  }))
                }
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2">
              <Input
                name="country"
                placeholder={t("main.navLinks.jobsPublished.country")}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    country: e,
                  }))
                }
              />
            </div>
          </div>
        </Tab>

        <Tab isActive={createFilter === "contact"}>
          <div className="grid grid-flow-row gap-2 text-sm">
            <div className="-mb-2">{t("main.navLinks.jobsPublished.contactPerson")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Select
                name="employee"
                label={t("main.navLinks.jobsPublished.employee")}
                items={contactPerson}
                onChange={e =>
                  setForm((prev: any) => ({
                    ...prev,
                    contact_person_id: e.target.value,
                  }))
                }
              />
            </div>
            <div className="-mb-2">{t("main.navLinks.jobsPublished.alternatively")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <SalutationSelect value={form.contact_user + ""} onChange={onChange} />
              <Input name="contact-title" placeholder="Titel" />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="contact-surname"
                placeholder={t("main.profileViewTabs.editProfile.form.firstName")}
              />
              <Input
                name="contact-lastname"
                placeholder={t("main.profileViewTabs.editProfile.form.lastName")}
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="contact-position"
                placeholder={t("main.profileViewTabs.editProfile.form.position")}
              />
              <Input
                name="contact-department"
                placeholder={t("main.profileViewTabs.editProfile.form.department")}
              />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="contact-phone"
                placeholder={t("main.profileViewTabs.editProfile.form.phone")}
              />
              <Input
                name="contact-email"
                placeholder={t("main.profileViewTabs.editProfile.form.email")}
              />
            </div>
            <div className="-mb-2">{t("components.createJob.introlocation")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input name="meeting-postalcode" placeholder={t("components.createJob.zip")} />
              <Input name="meeting-city" placeholder={t("components.createJob.location")} />
            </div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input
                name="meeting-street"
                placeholder={t("main.profileViewTabs.editProfile.form.street")}
              />
              <Input
                name="meeting-streetnumber"
                placeholder={t("components.createJob.housenumber")}
              />
            </div>
            <div className="-mb-2">{t("components.createJob.info")}</div>
            <div className="grid grid-flow-row items-center gap-2">
              <Input name="meeting-type" placeholder={t("components.createJob.applicationType")} />
            </div>
            <div className="-mb-2">{t("components.createJob.applicationPeriod")}</div>
            <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
              <Input name="meeting-startdate" placeholder={t("components.createJob.from")} />
              <Input name="meeting-enddate" placeholder={t("components.createJob.till")} />
            </div>
            <div className="-mb-2">{t("components.createJob.hints")}</div>
            <div className="grid grid-flow-row items-center gap-2">
              <TextareaAutosize
                name="notes"
                value={notesBody}
                minRows={4}
                maxRows={800}
                onChange={e => setNotesBody(e.target.value)}
                css={[tw`block w-full rounded-xl bg-gray-100 border-0 text-sm break-all`]}
              />
            </div>
          </div>
        </Tab>

        <BottomJobCreateTab
          showForward={createFilter !== "contact"}
          showBackward={createFilter !== "basics"}
          showSubmitButton={createFilter !== "requirements" && createFilter !== "basics"}
          backward={() => {
            if (createFilter === "contact") setCreateFilter("requirements");
            if (createFilter === "requirements") setCreateFilter("basics");
          }}
          forward={() => {
            if (createFilter === "basics") setCreateFilter("requirements");
            if (createFilter === "requirements") setCreateFilter("contact");
          }}
          preview={() =>
            popupModal.open(
              <PopupWrapper onClose={popupModal.close}>
                <JobsDetails preloadedJob={form} />
              </PopupWrapper>,
            )
          }
          submit={create.mutate}
        />
      </Panel>
    </form>
  );
};

export default CreateJob;

import {
  ArrowPathRoundedSquareIcon,
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  MusicalNoteIcon,
  PlayIcon as PlayIconFull,
  PlusIcon,
} from "@heroicons/react/24/solid";
import api from "@src/api/api";
import ProfileAvatar from "@src/components/elements/shared/ProfileAvatar";
import TextView from "@src/components/elements/textedit/TextView";
import CommentsPopup from "@src/components/popup/CommentsPopup";
import SharePopup from "@src/components/popup/SharePopup";
import WatchDropdown from "@src/pages/watch/WatchDropdown";
import WatchBookmarkButton from "@src/pages/watch/actions/WatchBookmarkButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import WatchType from "@src/utils/types/watch/WatchType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  data: WatchType;
  active: boolean;
  preload: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
};

const WatchMobileItem = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  queryKey = ["watch", "posts"],
}: Props) => {
  const queryClient = useQueryClient();
  const [viewed, setViewed] = useState(false);

  const navigate = useNavigate();
  const [onCanPlay, setOnCanPlay] = useState(false);
  const [preloadPersist, setPreloadPersist] = useState(preload);
  useEffect(() => {
    if (!preloadPersist && preload) {
      console.log("Preload persist");
      setPreloadPersist(preload);
    }
  }, [preload]);
  const [playing, setPlaying] = useState(autoplay);
  const [preventPause, setPreventPause] = useState(false);
  const [timeRatio, setTimeRatio] = useState(0);
  const { user: meUser } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const location = useLocation();
  const like = useMutation([`like-${data.post_id}`], {
    mutationFn: async () => {
      if (!meUser) {
        loginModal.open();
        return;
      }
      const liked = data.liked;

      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: WatchType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.post_id !== data.post_id) continue;

            if (data.liked) {
              post.like_count -= 1;
            } else {
              post.like_count += 1;
            }
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }
      const res = await api.get(`/api/v1/posts/${data.post_id}/${liked ? "unlike" : "like"}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watch", "posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (active && !viewed) {
      setViewed(true);
      axios.get(`/api/v1/posts/${data.post_id}/view`);
    }
  }, [active, data.post_id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const thumbnailRef = useRef<HTMLImageElement>(null);
  const { user } = useAppSelector(state => state.user);
  const [likeEffect, setLikeEffect] = useState(false);

  useEffect(() => {
    if (videoRef.current === null) return;
    const video = videoRef.current;
    const src =
      data.media_thumbnail.replace("/thumbnails/thumbnail.jpg", "") + "/manifest/video.m3u8";

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (active) {
          video.play().catch(e => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = src;
      video.addEventListener("loadedmetadata", function () {
        if (active) {
          video.play().catch(e => console.log(e)); // Autoplay based on 'active'
        } else {
          video.pause(); // Pause based on 'active'
        }
      });
    }
  }, [active, data.media_key, videoRef]);

  return (
    <div
      css={[
        tw`bg-neutral-900 h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] w-screen relative snap-center`,
      ]}
    >
      <div className="max-h-full max-w-full cursor-pointer overflow-hidden">
        <video
          poster={data.media_thumbnail}
          ref={videoRef}
          className="mx-auto h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))]"
          css={[
            videoRef.current &&
              videoRef.current?.videoWidth / videoRef.current?.videoHeight < 0.57 &&
              tw`object-cover`,
          ]}
          loop={true}
          muted={muted}
          playsInline
          preload={"auto"}
          onCanPlayThrough={() => setOnCanPlay(true)}
          onPlaying={() => setPlaying(true)}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onTimeUpdate={() => {
            if (!videoRef.current) return;
            setTimeRatio((videoRef.current?.currentTime / videoRef.current?.duration) * 100);
          }}
        />
        {!onCanPlay && (
          <div className="absolute left-0 top-0 size-full">
            <img
              src={data.media_thumbnail}
              className="mx-auto h-[calc(calc(100dvh-50px)-env(safe-area-inset-bottom))] object-cover"
            />
          </div>
        )}
        <div
          className="absolute left-0 top-0 size-full"
          onClick={e => {
            if (!videoRef.current || preventPause) return;
            videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
            e.stopPropagation();
          }}
        ></div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.post_id}
          userId={data.author_id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: "https://www.tradefoox.com/watch/" + data.post_id,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.post_id}
        />
      )}
      <div className="absolute bottom-[20px] z-10 flex w-full flex-row justify-between rounded-xl px-6 pb-4 text-gray-200">
        <button
          className="pointer-events-auto hidden duration-200"
          onClick={() => {
            if (!videoRef.current) return;
            videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
          }}
        >
          {playing ? <PauseIcon className="size-6 " /> : <PlayIcon className="size-6 " />}
        </button>
        <button
          className="pointer-events-auto absolute bottom-0 right-6 duration-200"
          onClick={e => {
            setMuted && setMuted(!muted);
            e.stopPropagation();
          }}
        ></button>
      </div>
      {/* Sidebar */}
      <div className="absolute bottom-2 right-3 z-10 w-[50px] rounded-xl py-1 text-xs text-gray-200">
        <div className="flex flex-col items-center gap-3">
          <button
            className="pointer-events-auto flex flex-col items-center"
            onClick={() => like.mutate()}
          >
            {!data.liked && (
              <HeartIcon
                className="size-8"
                onClick={() => {
                  setLikeEffect(true);
                }}
                onAnimationEnd={() => {
                  setLikeEffect(false);
                }}
              />
            )}
            {data.liked && (
              <HeartIcon className={`size-8 text-red-600 ${likeEffect && "animate-ping-once"}`} />
            )}
            <div>{data.like_count}</div>
          </button>
          <div className="pointer-events-auto flex flex-col items-center">
            <button
              className="flex flex-col items-center gap-0"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <ChatBubbleOvalLeftIcon
                className="size-8"
                onClick={() => {
                  setCommentsPopupOpen(!commentsPopupOpen);
                }}
              />
            </button>
            <div>{data.comment_count}</div>
          </div>
          <div className="pointer-events-auto flex flex-col items-center">
            <WatchBookmarkButton postId={data.post_id} bookmarked={data.bookmarked} />
            <div>{data.bookmark_count}</div>
          </div>
          <div
            className="pointer-events-auto flex flex-col items-center"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PlayIconFull className="size-8" />
            <div>{data.view_count}</div>
          </div>
          <button
            className="pointer-events-auto relative flex cursor-pointer flex-col items-center"
            onClick={e => {
              if (user) {
                setSharePopupOpen(true);
              } else {
                loginModal.open();
              }
              e.stopPropagation();
            }}
          >
            <ArrowPathRoundedSquareIcon className="size-8" />
            <div>0</div>
            <button
              className="absolute left-0 top-0 size-full lg:hidden"
              onClick={() => {
                setSharePopupMobile(true);
              }}
            />
          </button>

          <div className="pointer-events-auto text-black">
            {
              <WatchDropdown
                isRepost={data.is_reposted}
                postId={data.post_id}
                videoUrl={data.media_url}
                authorId={data.author_id}
                bookmarked={data.bookmarked}
              />
            }
          </div>
          <button
            className="pointer-events-auto mr-12 mt-24 flex items-center gap-2.5 rounded-xl border bg-gray-200 px-2.5 py-1 text-sm text-black"
            onClick={() => {
              if (!user) {
                loginModal.open();
                return;
              }
              navigate("/watch/erstellen");
            }}
          >
            <PlusIcon className="size-4 text-black" /> Create
          </button>
        </div>
      </div>
      <div className="absolute bottom-2 left-0 block w-full">
        <div className="flex w-[calc(100%-70px)] flex-row gap-4 rounded-xl px-2 py-1 text-sm text-white">
          <div className="flex flex-col items-center">
            <NavLink
              to={"/profile/" + data.author_id + "/watch"}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
              className="pointer-events-auto"
            >
              <div className="pointer-events-none">
                <ProfileAvatar
                  user={{
                    id: data.author_id,
                    name: data.author_name,
                    type: data.author_type,
                    avatar: data.author_avatar,
                  }}
                  nameStyle={tw`hidden`}
                />
              </div>
            </NavLink>
            <button
              onClick={e => {
                setMuted && setMuted(!muted);
                e.stopPropagation();
              }}
              className="rounded-lg bg-white bg-opacity-30 px-3 text-black max-md:text-white"
            >
              {muted ? (
                <SpeakerXMarkIcon className="size-6" />
              ) : (
                <SpeakerWaveIcon className="size-6" />
              )}
            </button>
          </div>
          <div className="flex w-full flex-col">
            <NavLink
              to={`/watch/c/${data.author_id}`}
              className="text-ellipsis text-clip hover:font-semibold"
            >
              @{data.author_name}
            </NavLink>
            <div className="text-ellipsis text-clip leading-4">
              <TextView value={data.post_text} />
            </div>
            <div className="flex flex-row">
              <MusicalNoteIcon className="size-5 shrink-0" />
              <div className="w-full overflow-hidden">
                <div className="animate-marquee w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchMobileItem;

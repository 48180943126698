import { DocumentTextIcon, EyeIcon, PlayIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

type Props = {
  title?: string;
  image?: string;
  type?: string;
  tag?: string;
  viewCount?: number;
  likeCount?: number;
  id: string;
  key?: string;
};

const CompactPost = ({ type, image, tag, viewCount, likeCount, id, title }: Props) => {
  return (
    <NavLink
      to={"/news/für_dich?featured=" + id}
      className="group relative aspect-[4/5] lg:rounded-lg"
    >
      <img
        src={image}
        className="mx-auto size-full object-cover object-center lg:rounded-lg"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "/placeholder.png";
        }}
      />
      {type === "video" ? (
        <PlayIcon className="absolute right-1 top-1 size-5" />
      ) : (
        <DocumentTextIcon className="absolute right-1 top-1 size-5" />
      )}
      <div className="invisible absolute top-0 size-full bg-black/30 group-hover:visible">
        <div className="absolute top-1/2 w-full -translate-y-1/2 px-2">
          {likeCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 font-semibold text-white">
              <HeartIcon className="size-5" />
              <div>{likeCount}</div>
            </div>
          )}
          {viewCount != undefined && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <EyeIcon className="flip size-5 -scale-x-100 text-white" />
              <div>{viewCount}</div>
            </div>
          )}
          {title && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div className="line-clamp-1 text-center">{title}</div>
            </div>
          )}
          {tag && (
            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <div>#{tag}</div>
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};

export default CompactPost;

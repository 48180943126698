import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import BirthdaysList from "@src/pages/contacts/contacts/BirthdaysList";
import BlockedList from "@src/pages/contacts/contacts/BlockedList";
import ContactMyContactsContainer from "@src/pages/contacts/contacts/ContactMyContactsContainer";
import ContactRequestsContainer from "@src/pages/contacts/contacts/requests/ContactRequestsContainer";
import StatisticsItem from "@src/pages/contacts/contacts/statistics/StatisticsItem";
import ContactForYouContainer from "@src/pages/contacts/for_you/ContactForYouContainer";
import ContactTrendingForYouContainer from "@src/pages/contacts/for_you/ContactTrendingForYouContainer";
import ContactIFollowContainer from "@src/pages/contacts/i_follow/ContactIFollowContainer";
import ContactMyFollowersContainer from "@src/pages/contacts/my_followers/ContactMyFollowersContainer";
import { useAppSelector } from "@src/state/hooks";
import useDebounce from "@src/utils/useDebounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

type Props = {
  tab?: string;
  subTab?: string;
};

const AssignmentsContainer = ({ tab = "", subTab = "" }: Props) => {
  const { t, i18n } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { user } = useAppSelector(state => state.user);

  const getTabLabel = (key: string) => {
    switch (key) {
      default:
      case "toYouAll":
        if (i18n.exists("main.contacts.tabsAlt.toYou")) return t("main.contacts.tabs.toYou");
        return t("main.contacts.tabs.toYou");

      case "requested":
        if (i18n.exists("main.contacts.tabsAlt.requested"))
          return t("main.contacts.tabs.requested");
        return t("main.contacts.tabs.requested");

      case "statistics":
        if (i18n.exists("main.contacts.tabsAlt.statistics"))
          return t("main.contacts.tabs.statistics");
        return t("main.contacts.tabs.statistics");

      case "trending":
        if (i18n.exists("main.contacts.tabsAlt.trending")) return t("main.contacts.tabs.trending");
        return t("main.contacts.tabs.trending");

      case "pending":
        if (i18n.exists("main.contacts.tabsAlt.pending")) return t("main.contacts.tabs.pending");
        return t("main.contacts.tabs.pending");

      case "following":
        if (i18n.exists("main.contacts.tabsAlt.following"))
          return t("main.contacts.tabs.following");
        return t("main.contacts.tabs.following");

      case "followers":
        if (i18n.exists("main.contacts.tabsAlt.followers"))
          return t("main.contacts.tabs.followers");
        return t("main.contacts.tabs.followers");

      case "myContacts":
        if (i18n.exists("main.contacts.tabsAlt.myContacts"))
          return t("main.contacts.tabs.myContacts");
        return t("main.contacts.tabs.myContacts");

      case "birthdays":
        if (i18n.exists("main.contacts.tabsAlt.birthdays"))
          return t("main.contacts.tabs.birthdays");
        return t("main.contacts.tabs.birthdays");

      case "blocked":
        if (i18n.exists("main.contacts.tabsAlt.blocked")) return t("main.contacts.tabs.blocked");
        return t("main.contacts.tabs.blocked");

      case "all":
        if (i18n.exists("main.contacts.tabsAlt.all")) return t("main.contacts.tabs.all");
        return t("main.contacts.tabs.all");

      case "person":
        if (i18n.exists("main.contacts.tabsAlt.person")) return t("main.contacts.tabs.person");
        return t("main.contacts.tabs.person");

      case "company":
        if (i18n.exists("main.contacts.tabsAlt.company")) return t("main.contacts.tabs.company");
        return t("main.contacts.tabs.company");

      case "club":
        if (i18n.exists("main.contacts.tabsAlt.club")) return t("main.contacts.tabs.club");
        return t("main.contacts.tabs.club");
    }
  };

  return (
    <>
      <BackButton stepback />
      <div className="flex flex-col gap-2">
        <TabController defaultTab={tab}>
          <TabHeaders>
            <TabHeader id="myContacts" url="/kontakte/meine_kontakte/übersicht">
              {t("main.contacts.tabs.myContacts")}
            </TabHeader>
            <TabHeader id="toYou" url="/kontakte/für_dich">
              {t("main.contacts.tabs.toYou")}
            </TabHeader>
            <TabHeader id="followers" url="/kontakte/follower">
              {t("main.contacts.tabs.followers")}
            </TabHeader>
            <TabHeader id="following" url="/kontakte/following">
              {t("main.contacts.tabs.following")}
            </TabHeader>
            <TabHeader id="trending" url="/kontakte/trending">
              {t("main.contacts.tabs.trending")}
            </TabHeader>
          </TabHeaders>

          <Input
            name="search-contacts"
            placeholder={t("components.shared.search", {
              tab: getTabLabel(subTab || tab),
            })}
            onChange={e => setSearchQuery(e)}
            clearable
          />

          <TabBodies>
            <TabBody id="toYou">
              <ContactForYouContainer tab={subTab} searchQuery={debouncedSearchQuery} />
            </TabBody>
            <TabBody id="trending">
              <ContactTrendingForYouContainer searchQuery={debouncedSearchQuery} />
            </TabBody>
            <TabBody id="following">
              <ContactIFollowContainer searchQuery={debouncedSearchQuery} />
            </TabBody>
            <TabBody id="followers">
              <ContactMyFollowersContainer searchQuery={debouncedSearchQuery} />
            </TabBody>
            <TabBody id="myContacts">
              <TabController defaultTab={subTab} isSubTab={true}>
                <TabHeaders>
                  <TabHeader
                    id="allMyContacts"
                    url="/kontakte/meine_kontakte/übersicht"
                    isHidden={user?.type === "company" || user?.type === "club"}
                  >
                    {t("main.contacts.tabs.overview")}
                  </TabHeader>

                  <TabHeader
                    id="requested"
                    url="/kontakte/meine_kontakte/angefragt"
                    isHidden={user?.type === "company" || user?.type === "club"}
                  >
                    {t("main.contacts.tabs.requested")}
                  </TabHeader>
                  <TabHeader
                    id="pending"
                    url="/kontakte/meine_kontakte/ausstehend"
                    isHidden={user?.type === "company" || user?.type === "club"}
                  >
                    {t("main.contacts.tabs.pending")}
                  </TabHeader>
                  <TabHeader id="blocked" url="/kontakte/meine_kontakte/blocked">
                    {t("main.contacts.tabs.blocked")}
                  </TabHeader>
                  <TabHeader
                    id="birthdays"
                    url="/kontakte/meine_kontakte/geburtstage"
                    isHidden={user?.type === "company" || user?.type === "club"}
                  >
                    {t("main.contacts.tabs.birthdays")}
                  </TabHeader>
                  <TabHeader id="statistics" url="/kontakte/meine_kontakte/statistik">
                    {t("main.contacts.tabs.statistics")}
                  </TabHeader>
                </TabHeaders>
                <TabBodies>
                  <TabBody id="allMyContacts">
                    <ContactMyContactsContainer searchQuery={debouncedSearchQuery} />
                  </TabBody>
                  <TabBody id="requested">
                    <ContactRequestsContainer type="sent" searchQuery={debouncedSearchQuery} />
                  </TabBody>
                  <TabBody id="pending">
                    <ContactRequestsContainer type="received" searchQuery={debouncedSearchQuery} />
                  </TabBody>
                  <TabBody id="blocked">
                    <BlockedList searchQuery={debouncedSearchQuery} />
                  </TabBody>
                  <TabBody id="birthdays">
                    <BirthdaysList searchQuery={debouncedSearchQuery} />
                  </TabBody>
                  <TabBody id="statistics">
                    <StatisticsItem />
                  </TabBody>
                </TabBodies>
              </TabController>
            </TabBody>
          </TabBodies>
        </TabController>
        <Outlet />
      </div>
    </>
  );
};

export default AssignmentsContainer;

import WatchThumbnailList from "@src/components/elements/posts/watch/WatchThumbnailList";

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Watch = ({ searchQuery, showPlaceholder }: Props) => {
  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <WatchThumbnailList search={searchQuery} fineGrid />
    </>
  );
};

export default Watch;

import {
  ArrowUpOnSquareIcon,
  ArrowUturnRightIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  HeartIcon,
  MinusCircleIcon,
  PencilIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartIconSolid,
  MinusCircleIcon as MinusCircleIconSolid,
} from "@heroicons/react/24/solid";
import api from "@src/api/api";
import ChatButton from "@src/components/elements/form/buttons/ChatButton";
import LikeButton from "@src/components/elements/form/buttons/LikeButton";
import ShareButton from "@src/components/elements/form/buttons/ShareButton";
import ViewButton from "@src/components/elements/form/buttons/ViewButton";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import SharePopup from "@src/components/popup/SharePopup";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { showReportModal } from "@src/state/reportModal/actions";
import { Media } from "@src/utils/types/Media";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

type printButtonType = {
  printUrl?: string;
};

type likeButtonType = {
  likeUrl: string;
  unlikeUrl: string;
  liked: boolean;
  like_count: number;
};

type chatButtonType = {
  chatUrl: string;
  backlink?: string;
};

type viewButtonType = {
  view_count: number;
};

type connectButtonType = {
  isVisible: boolean;
};

type shareButtonType = {
  title?: string;
  body?: string;
  media?: Array<Media>;
};

type Props = {
  likeButton: likeButtonType;
  viewButton: viewButtonType;
  chatButton: chatButtonType;
  connectButton?: connectButtonType;
  printButton?: printButtonType;
  shareButton?: shareButtonType;
  editButton?: () => void;
  blocked?: boolean;
  itemId: string;
  itemType: string;
  spread?: boolean;
};

const ButtonsBar = ({
  likeButton,
  viewButton,
  chatButton,
  connectButton,
  shareButton,
  printButton,
  itemId,
  itemType,
  blocked,
  editButton,
  spread,
}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const unlike = useMutation(["unlike"], {
    mutationFn: async () => {
      const res = await api.get(likeButton.unlikeUrl);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const like = useMutation(["like"], {
    mutationFn: async () => {
      const res = await api.get(likeButton.likeUrl);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createChat = useMutation(["create-chat"], {
    mutationFn: async () => {
      const res = await api.get(chatButton.chatUrl);
      return res.data;
    },
    onSuccess: data => {
      navigate("/messenger", {
        state: { chatId: data.data.id, backlink: chatButton.backlink },
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connect = useMutation([`contacts-request-${itemId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${itemId}/connect`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + itemId],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblock = useMutation([`unblock-${itemId}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${itemId}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      ["user"].forEach(key => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div
      css={[
        tw`flex flex-wrap gap-2 text-xs items-end max-lg:justify-between`,
        spread && tw`justify-between`,
      ]}
    >
      <ChatButton onClick={() => checkAuth() && createChat.mutate()} />
      <ShareButton contentType="" content={shareButton} />
      <LikeButton
        liked={likeButton.liked}
        likeCount={likeButton.like_count}
        unlike={() => checkAuth() && unlike.mutate()}
        like={() => checkAuth() && like.mutate()}
      />
      <ViewButton viewCount={viewButton.view_count} />
      <div className="relative mr-[18px]">
        <ContextMenu
          icon={<EllipsisHorizontalIcon className="size-5 opacity-100" aria-hidden="true" />}
          items={[
            !!(connectButton && connectButton.isVisible) && {
              label: t("buttons.connect"),
              onClick: () => checkAuth() && connect.mutate(),
              icon: ArrowUturnRightIcon,
            },
            {
              label: t("main.shop.buttons.contact"),
              onClick: () => checkAuth() && createChat.mutate(),
              icon: ChatBubbleOvalLeftIcon,
            },
            user?.id === itemId && {
              label: t("buttons.edit"),
              onClick: () => !!(checkAuth() && editButton) && editButton(),
              icon: PencilIcon,
            },
            {
              label: t("buttons.share"),
              onClick: () =>
                checkAuth() &&
                popupModal.open(
                  <SharePopup onClose={popupModal.close} userId="" content={shareButton ?? {}} />,
                ),
              icon: ArrowUpOnSquareIcon,
            },
            {
              label: t("buttons.like"),
              onClick: () => checkAuth() && (likeButton.liked ? unlike.mutate() : like.mutate()),
              icon: likeButton.liked ? HeartIconSolid : HeartIcon,
            },
            printButton && {
              label: t("buttons.print"),
              onClick: () => {
                return;
              },
              icon: PrinterIcon,
            },
            {
              label: t("buttons.report"),
              onClick: () => dispatch(showReportModal(itemId, itemType)),
              icon: FlagIcon,
            },
            user?.id !== itemId && {
              label: blocked ? t("buttons.unblock") : t("buttons.block"),
              onClick: () => (blocked ? unblock.mutate() : dispatch(showBlockUserModal(itemId))),
              icon: blocked ? MinusCircleIconSolid : MinusCircleIcon,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ButtonsBar;

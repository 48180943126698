import { useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface MarketplaceBannerProps {
  bannerImages: string[];
}

const ArrowButton = styled.div`
  &.swiper-button-next::after,
  &.swiper-button-prev::after {
    color: gray;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 40px;
    }
  }
`;

const MarketplaceBanner = ({ bannerImages }: MarketplaceBannerProps) => {
  const [imageLoaded, setImageLoaded] = useState<boolean[]>(
    new Array(bannerImages.length).fill(false),
  );

  return (
    <div className="overflow-y-hidden rounded-t-lg">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        autoplay={{ delay: 4000 }}
        onInit={swiper => {
          swiper.navigation.nextEl.classList.add("swiper-button-next");
          swiper.navigation.prevEl.classList.add("swiper-button-prev");
        }}
      >
        {bannerImages.map((img, index) => (
          // eslint-disable-next-line react/jsx-key
          <SwiperSlide className="relative h-2/3 w-full">
            {" "}
            {/* Adjusted height here */}
            <div className="relative size-full">
              <img
                className="size-full rounded-t-lg object-cover"
                src={img}
                alt={`Slide ${index}`}
              />
            </div>
          </SwiperSlide>
        ))}
        <div className="hidden md:block">
          <ArrowButton className="swiper-button-next"></ArrowButton>
          <ArrowButton className="swiper-button-prev"></ArrowButton>
        </div>
      </Swiper>
    </div>
  );
};

export default MarketplaceBanner;

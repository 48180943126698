import Input from "@src/components/elements/input/Input";
import SoundsThumbnailList from "@src/components/elements/posts/sounds/SoundsThumbnailList";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { Media } from "@src/utils/types/Media";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  dark?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  media?: Array<Media>;
};

const SoundsContainer = ({ ...props }: Props) => {
  const [open, setOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  const tabs = [
    { label: t("main.watchTabs.toYou"), key: "forYou" },
    { label: t("main.watchTabs.create.saved"), key: "bookmarked" },
    { label: t("main.watchTabs.create.more"), key: "more" },
    { label: t("main.watchTabs.create.yourSounds"), key: "yourSounds" },
  ];
  const [tab, setTab] = useState("forYou");

  return (
    <ModalWrapper
      open={open}
      onCancel={() => {
        props.onClose && props.onClose();
        setOpen(false);
      }}
      hideActions
      title="Sounds"
    >
      <div className="flex flex-col gap-2">
        <Input
          placeholder={t("main.soundsTabs.placeholder")}
          name="sounds_keywords"
          onChange={e => setSearchQuery(e)}
          clearable
        />
        <div className="flex flex-row gap-4 overflow-x-auto text-sm scrollbar-none">
          {tabs.map(item => {
            return (
              <button
                key={item.key}
                onClick={() => {
                  setTab(item.key);
                }}
                css={[
                  tab === item.key ? tw`text-gray-900 border-b border-gray-900` : tw`text-gray-500`,
                ]}
              >
                {item.label}
              </button>
            );
          })}
        </div>
        {tab === "forYou" && (
          <div className="h-[50vh] overflow-y-scroll scrollbar-thin">
            <SoundsThumbnailList search={searchQuery} small list />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default SoundsContainer;

import {
  ArrowDownOnSquareIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import LinkPreview from "@src/components/elements/LinkPreview";
import UserWidget from "@src/components/elements/UserWidget";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import MessageDropdown from "@src/pages/messenger/chat/dropdown/MessageDropdown";
import getName from "@src/utils/getName";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";
import { Media } from "@src/utils/types/Media";
import { User } from "@src/utils/user";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import tw from "twin.macro";

type Props = {
  author: User;
  message: {
    id: string;
    text: string;
    created_at: string;
    files?: Array<string>;
    read: boolean;
    link_preview?: LinkPreviewType;
    media?: Array<Media>;
  };
  isSender: boolean;
  onForward: () => void;
  key?: string;
  showImage?: boolean;
};

const getFileName = (path: string) => {
  const split = path.split("/");
  return split[split.length - 1];
};

const getFileType = (filename: string) => {
  switch (filename.split(".")[1]) {
    case "png":
    case "jpg":
    case "jpeg":
      return "image";
    case "mp4":
    case "webm":
      return "video";
    default:
      return "file";
  }
};

function MessengerBubble({ author, message, isSender, onForward, showImage }: Props) {
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const body = useRef<any>(null);
  const filename = getFileName(message.text);
  const isMedia = message.text.startsWith("chat/media/");
  const [selectedMedia, setSelectedMedia] = useState(0);

  useEffect(() => {
    body.current && body.current.clientHeight > 120 && setLargeText(true);
  }, [body]);
  return (
    <div css={[tw`flex items-end`, isSender ? tw`justify-end` : tw`justify-start`]}>
      <div
        css={[
          tw`rounded-lg py-2 max-w-[90%] break-words relative`,
          isSender ? tw`bg-blue-600 text-white self-end` : tw`bg-gray-100 text-gray-700 self-start`,
          isMedia && tw`w-[90%]`,
        ]}
      >
        <div className="flex flex-row items-center justify-between gap-2 pl-4 pr-2">
          {showImage && <UserWidget user={author} size={35} follow={false} online={false} />}
          <div className="truncate font-semibold">{getName(author)}</div>
          <div className="ml-auto">
            <MessageDropdown messageId={message.id} isSender={isSender} onForward={onForward} />
          </div>
        </div>
        <div className="px-4">
          {message.link_preview && <LinkPreview link={message.link_preview} small={true} />}
          {!isMedia && (
            <div
              css={[
                tw`overflow-hidden text-sm leading-4`,
                !showMore && largeText && tw`line-clamp-5`,
              ]}
              ref={body}
            >
              {message.text}
            </div>
          )}
          {message.media && message.media[0] && (
            <div className="relative mt-2 max-h-[700px] overflow-hidden rounded-2xl border border-gray-400 text-black">
              {message.media[selectedMedia].type === "other" && (
                <a href={message.media[selectedMedia].data_url} download={filename}>
                  <div className="h-36 w-96 rounded-2xl bg-gray-300">
                    <ArrowDownOnSquareIcon className="absolute left-1/2 top-1/2 size-8 -translate-x-1/2 -translate-y-1/2 text-gray-400" />
                    <span className="leading-1 absolute bottom-6 left-1/2 w-4/5 -translate-x-1/2 truncate text-center text-xs">
                      {message.media[selectedMedia].file_name}
                    </span>
                  </div>
                </a>
              )}
              {message.media[selectedMedia].type === "image" && (
                <img
                  src={message.media[selectedMedia].data_url}
                  alt={message.media[selectedMedia].file_name}
                  className="size-full object-contain"
                  width="100%"
                  height="100%"
                />
              )}

              {message.media[selectedMedia].type === "video" && (
                <ReactPlayer
                  width="100%"
                  controls={true}
                  height="100%"
                  url={message.media[selectedMedia].data_url + "/playlist.m3u8"}
                />
              )}

              {selectedMedia !== 0 && (
                <div
                  className="absolute left-0 top-0 h-full w-1/6 cursor-pointer"
                  onClick={() => setSelectedMedia(count => count - 1)}
                >
                  <div className="absolute left-0 top-0 z-0 size-full opacity-0 hover:bg-white hover:opacity-50">
                    <ChevronLeftIcon className="z-50 mx-auto h-full w-[35px]" />
                  </div>
                </div>
              )}
              {selectedMedia < message.media.length - 1 && (
                <div
                  className="absolute right-0 top-0 h-full w-1/6 cursor-pointer"
                  onClick={() => setSelectedMedia(count => count + 1)}
                >
                  <div className="absolute left-0 top-0 z-0 size-full opacity-0 hover:bg-white hover:opacity-50">
                    <ChevronRightIcon className="z-50 mx-auto h-full w-[35px]" />
                  </div>
                </div>
              )}
              <div className="absolute bottom-0 mx-auto my-2 flex w-full justify-center gap-2">
                {message.media.map((media, index) => (
                  <div
                    css={[
                      tw`rounded-full w-[10px] h-[10px] bg-black cursor-pointer`,
                      index === selectedMedia && tw`border-2 border-black bg-white`,
                    ]}
                    onClick={() => setSelectedMedia(index)}
                    key={index}
                  ></div>
                ))}
              </div>
            </div>
          )}
          {largeText && (
            <ShowMoreButton
              onClick={() => setShowMore(value => !value)}
              active={showMore}
              blue={isSender}
            />
          )}
          <div className="mt-1 flex justify-end gap-1">
            <div css={[tw`text-xssm`]}>{message.created_at}</div>
            <CheckIcon className="h-3" />
            {message.read && <CheckIcon className="-ml-2.5 h-3" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessengerBubble;

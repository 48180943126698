import Button from "@src/components/elements/input/Button";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function CreateFormButton() {
  const { t } = useTranslation();
  const createForms: { [index: string]: { url: string; label?: string } } = {
    inserate: { url: "/create" },
    jobs: { url: "/jobs/jobs_erstellen" },
    news: { url: "/news/create", label: t("components.posts.post") },
    watch: { url: "/watch/create", label: t("components.posts.post") },
    kontakte: {
      url: "/kontakte/news/create",
      label: t("components.posts.post"),
    },
    marketplace: { url: "/marketplace/create" },
    profile: { url: "/news/create", label: t("components.posts.post") },
    gruppen: { url: "/gruppen/erstellen" },
  };
  const path = location.pathname.split("/")[1];
  const createPath = createForms[path]?.url ?? "";
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  return (
    <>
      {createForms[path] && (
        <NavLink
          to={createPath}
          state={{ tab: "create" }}
          className="flex size-full -translate-y-1 justify-center"
          onClick={e => {
            if (!user) {
              e.preventDefault();
              loginModal.open();
            }
          }}
        >
          <Button variant={Button.Variants.Transparent}>
            {createForms[path].label ?? t("main.navLinks.createAd.tabs.create")}
          </Button>
        </NavLink>
      )}
    </>
  );
}

export default CreateFormButton;

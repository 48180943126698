import { User } from "@src/utils/user";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
type Props = { user: User; formType?: "basic" | "additional" | "offers" };

const Container = styled.div``;

const Text = styled.div`
  font-size: 0.875rem; // Textgröße anpassen
  hyphens: auto; // Aktivieren der automatischen Silbentrennung
  word-break: break-word; // Worttrennung, falls erforderlich
  min-width: 0; // Verhindert Überlauf
  margin-bottom: 2px;
`;
const ToggleButton = styled.button`
  ${tw`px-1 py-1 text-xs border border-gray-300 text-black rounded hover:bg-gray-300 focus:outline-none transition-all`}
`;
const UserInfoField: React.FC<{ label: string; value: string | undefined }> = ({
  label,
  value,
}) => (
  <Container>
    <Text>
      {label}: &nbsp;{value}
    </Text>
  </Container>
);

const Title = styled.h1`
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
`;

const Line = styled.hr`
  margin-bottom: 20px;
`;

const UserDataEmployeeForm: React.FC<Props> = ({ user, formType = "basic" }: Props) => {
  const { t } = useTranslation();
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const [showFullTextFields, setShowFullTextFields] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleShowFullText = (field: string) => {
    setShowFullTextFields(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const MIN_LENGTH_FOR_SHOW_MORE = 35; // Zum Beispiel 100 Zeichen

  const fields = [
    {
      label: t("main.profileViewTabs.editProfile.form.companyName"),
      value: user.company?.name,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.legalForm"),
      value: user.company?.legal_type,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.companyRegistration"),
      value: user.company?.company_registration_number,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.vatID"),
      value: user.company?.tax_id,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.annualsales"),
      value: user.company?.annual_sales,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.employees"),
      value: user.company?.employees.toString(),
    },
    {
      label: t("main.profileViewTabs.editProfile.form.topics"),
      value: user.company?.topic,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.moreInformation.keywords"),
      value: user.company?.keywords,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.moreInformation.imprint"),
      value: user.company?.imprint,
    },
    {
      label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
      value: user.company?.terms,
    },
  ];

  return (
    <div className="flex flex-col gap-6" style={{ minWidth: "300px" }}>
      {formType === "basic" && (
        <div css={[tw`grid grid-flow-row`]} style={{ minWidth: "max-content" }}>
          <Title>{t("main.profileViewTabs.aboutMe.content.general")}</Title>
          <Line />
          {fields.map((field, index) => {
            const isLongText =
              field.label ===
                t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition") ||
              field.label === t("main.profileViewTabs.editProfile.form.moreInformation.imprint");
            const textLengthExceedsLimit =
              field.value && field.value.length > MIN_LENGTH_FOR_SHOW_MORE;
            const showFullText = showFullTextFields[field.label] || false;

            return (
              field.value && (
                <div key={index}>
                  <UserInfoField
                    label={field.label}
                    value={
                      isLongText && !showFullText
                        ? field.value.slice(0, 35) + (textLengthExceedsLimit ? "..." : "")
                        : field.value
                    }
                  />
                  {isLongText && textLengthExceedsLimit && (
                    <ToggleButton onClick={() => toggleShowFullText(field.label)}>
                      {showFullText ? t("buttons.showLess") : t("buttons.showMore")}
                    </ToggleButton>
                  )}
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserDataEmployeeForm;

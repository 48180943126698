import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AccountDeactivationPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const action = useMutation(["deactivate"], {
    mutationFn: async () => {
      const res = await api.get("/api/v1/deactivate");
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      navigate("/logout");
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Panel title={t("pages.accountDeactivation.title")} mobileBorder={false}>
      <div className="confirmation-container text-sm">
        <p className="mb-2">{t("pages.accountDeactivation.text1")}</p>
        <ul className="mb-2 list-inside">
          <li>
            <strong>{t("pages.accountDeactivation.item1")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item11")}</li>
          <li>
            <strong>{t("pages.accountDeactivation.item2")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item22")}</li>
          <li>
            <strong>{t("pages.accountDeactivation.item3")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item33")}</li>
        </ul>
        <p className="mb-2">{t("pages.accountDeactivation.text2")}</p>
        <Button variant={Button.Variants.Red} onClick={() => action.mutate()}>
          {t("pages.accountDeactivation.deactivate")}
        </Button>
      </div>
    </Panel>
  );
};

export default AccountDeactivationPage;

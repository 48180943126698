import api from "@src/api/api";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface StatisticsData {
  followerDates: number[];
  followingDates: number[];
}

const StatisticsItem = () => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/api/v1/users/statistics/follow");
        if (response.data && response.status === 200) {
          setStatistics(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching user statistics", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const processStatistics = (timestamps: number[]): { [key: string]: number } => {
    const counts: { [key: string]: number } = {};

    timestamps.forEach(timestamp => {
      const dateStr = formatDate(timestamp);
      if (!counts[dateStr]) {
        counts[dateStr] = 0;
      }
      counts[dateStr]++;
    });

    return counts;
  };

  const accumulateCounts = (counts: { [key: string]: number }): { [key: string]: number } => {
    const dates = Object.keys(counts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    const accumulated: { [key: string]: number } = {};
    let total = 0;

    dates.forEach(date => {
      total += counts[date];
      accumulated[date] = total;
    });

    return accumulated;
  };

  if (!statistics) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center rounded-lg bg-gray-200 p-10 shadow-md">
          <h2 className="mb-2 text-lg font-semibold text-gray-800">
            {t("main.contacts.statistics.title")}
          </h2>
          <p className="text-center text-gray-600">{t("main.contacts.statistics.subText")}</p>
        </div>
      </div>
    );
  }

  const followerCounts = processStatistics(statistics.followerDates);
  const followingCounts = processStatistics(statistics.followingDates);

  const accumulatedFollowers = accumulateCounts(followerCounts);
  const accumulatedFollowing = accumulateCounts(followingCounts);

  const labels = Array.from(
    new Set([...Object.keys(accumulatedFollowers), ...Object.keys(accumulatedFollowing)]),
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const today = new Date().toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (!labels.includes(today)) {
    labels.push(today);
  }

  const getDailyTotalCounts = (
    accumulated: { [key: string]: number },
    labels: string[],
  ): number[] => {
    const dailyTotalCounts: number[] = [];
    let totalCount = 0;

    labels.forEach(label => {
      totalCount = accumulated[label] || totalCount;
      dailyTotalCounts.push(totalCount);
    });

    return dailyTotalCounts;
  };

  const dailyTotalFollowers = getDailyTotalCounts(accumulatedFollowers, labels);
  const dailyTotalFollowing = getDailyTotalCounts(accumulatedFollowing, labels);

  const data = {
    labels,
    datasets: [
      {
        label: t("main.followers.title"),
        data: dailyTotalFollowers,
        borderColor: "#0891B2",
        backgroundColor: "rgba(8, 145, 178, 0.5)",
        fill: true,
      },
      {
        label: t("main.following.title"),
        data: dailyTotalFollowing,
        borderColor: "rgb(128, 128, 128)",
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        fill: true,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return <div>{<Line data={data} options={options} />}</div>;
};

export default StatisticsItem;

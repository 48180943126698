import { User } from "@sentry/react";
import { AppContext } from "@src/App";
import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import MyProfileWidget from "@src/components/elements/MyProfileWidget";
import UserDetailsWidget from "@src/components/elements/UserDetailsWidget";
import WatchThumbnailList from "@src/components/elements/posts/watch/WatchThumbnailList";
import AppLink from "@src/components/elements/shared/AppLink";
import AppQRCode from "@src/components/elements/shared/QRCode";
import SideBarUserElement from "@src/components/elements/sidebar/SideBarUserElement";
import Suggestions from "@src/components/elements/sidebar/suggestions/Suggestions";
import Footer from "@src/components/elements/sidebar/widgets/Footer";
import Trends from "@src/components/elements/sidebar/widgets/Trends";
import CreateFormButton from "@src/components/nav/CreateFormButton";
import NavBar from "@src/components/nav/NavBar";
import NavBarBottom from "@src/components/nav/NavBarBottom";
import News from "@src/pages/bookmarks/tabcontent/News";
import { useAppSelector } from "@src/state/hooks";
import usePopupModal from "@src/state/modal/usePopupModal";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import isMobile from "@src/utils/isMobile";
import { useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import tw from "twin.macro";

const sidebarConfig = (t: TFunction) => ({
  left: [
    {
      key: "person_suggestions",
      title: t("main.userSuggestions.title"),
      link: "/kontakte/für_dich/personen",
    },
    {
      key: "company_suggestions",
      title: t("main.companySuggestions.title"),
      link: "/kontakte/für_dich/firmen",
    },
    {
      key: "group_suggestions",
      title: t("main.groupSuggestions.title"),
      link: "/gruppen/für_dich",
    },
    {
      key: "birthdays",
      title: t("main.birthdays.title"),
      link: "/kontakte/meine_kontakte/geburtstage",
      login: true,
    },
    { key: "managed_pages", title: "Verwaltete Seiten", link: "", login: true },
  ],
  right: [
    {
      key: "new_users",
      title: t("main.new.title"),
      link: "/kontakte/für_dich/alle",
    },
    {
      key: "visitors",
      title: t("main.visitors.title"),
      link: "/kontakte/meine_kontakte/statistik/besucher",
      login: true,
    },
    {
      key: "my_followers",
      title: t("main.myFollowers.title"),
      link: "/kontakte/follower",
      login: true,
    },
    {
      key: "my_friends",
      title: t("main.myFriends.title"),
      link: "/kontakte/meine_kontakte/übersicht",
      login: true,
    },
    {
      key: "last_visited",
      title: t("main.lastSeen.title"),
      link: "",
      login: true,
    },
    {
      key: "remember",
      title: t("main.bookmarks.title"),
      link: "/bookmarks",
      login: true,
    },
  ],
});

type Props = {
  topNavHidden?: boolean;
  darkHeader?: boolean;
  darkFooter?: boolean;
  borderless?: boolean;
  fullscreen?: boolean;
};

const MainWrapper = ({ ...props }: Props) => {
  const appContext = useContext(AppContext);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const sidebar = useQuery<any>(["sidebar"], async () => {
    const res = await api.get("/api/v1/sidebar");
    return res.data.data;
  });
  const { user } = useAppSelector(state => state.user);
  const popupModal = usePopupModal();
  const userBubbleModal = useUserBubbleModal();

  const { t } = useTranslation();

  const sidebarConfig = (t: TFunction, user: User | null) => {
    if (user !== null && user !== undefined) {
      return {
        left: [
          {
            key: "person_suggestions",
            title: t("main.userSuggestions.title"),
            link: "/kontakte/für_dich/personen",
          },
          {
            key: "company_suggestions",
            title: t("main.companySuggestions.title"),
            link: "/kontakte/für_dich/firmen",
          },
          {
            key: "group_suggestions",
            title: t("main.groupSuggestions.title"),
            link: "/gruppen/für_dich",
          },
          user.type === "person" && {
            key: "birthdays",
            title: t("main.birthdays.title"),
            link: "/kontakte/meine_kontakte/geburtstage",
            login: true,
            showBirthday: true,
          },
          {
            key: "managed_pages",
            title: t("main.managedPages.title"),
            link: "",
            login: true,
          },
        ].filter(Boolean) as {
          key: string;
          title: string;
          link: string;
          login?: boolean;
          showBirthday?: boolean;
        }[],
        right: [
          {
            key: "new_users",
            title: t("main.new.title"),
            link: "/kontakte/für_dich/alle",
          },
          {
            key: "visitors",
            title: t("main.visitors.title"),
            link: "/kontakte/meine_kontakte/statistik/besucher",
            login: true,
          },
          {
            key: "my_followers",
            title: t("main.myFollowers.title"),
            link: "/kontakte/follower",
            login: true,
          },
          user.type === "person" && {
            key: "my_friends",
            title: t("main.myFriends.title"),
            link: "/kontakte/meine_kontakte/übersicht",
            login: true,
          },
          {
            key: "last_visited",
            title: t("main.lastSeen.title"),
            link: "",
            login: true,
          },
        ].filter(Boolean) as {
          key: string;
          title: string;
          link: string;
          login?: boolean;
          showBirthday?: boolean;
        }[],
      };
    } else {
      return {
        left: [],
        right: [],
      };
    }
  };

  function scrollMain({ e, delta = 0 }: { e: React.UIEvent<HTMLElement>; delta?: number }) {
    if (!e.currentTarget) return;
    if (delta === 0) return;
    setIsScrolledDown(delta > 0);
    userBubbleModal.close();
  }

  return (
    <div
      css={[
        tw`grid grid-rows-[50px,calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom))),40px] xl:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen ||
          appContext?.topNavHidden ||
          props?.topNavHidden ||
          (isMobile && isScrolledDown) ||
          props.fullscreen) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <div
        css={[
          tw`opacity-100 ease-in-out duration-500`,
          (appContext?.fullscreen ||
            appContext?.topNavHidden ||
            props?.topNavHidden ||
            props.fullscreen) &&
            tw`max-md:hidden`,
          isMobile &&
            isScrolledDown &&
            tw`absolute z-30 w-screen duration-500 opacity-0 ease-in-out pointer-events-none`,
        ]}
      >
        <NavBar />
      </div>

      <div className="relative">
        {!appContext?.sidebarHidden && (
          <aside className="absolute left-1/2 top-0 max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] -translate-x-[calc(50%+475px)] overflow-y-scroll scrollbar-none max-xl:hidden xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]">
            <div className="mx-auto hidden w-[300px] grid-flow-row auto-rows-max gap-4 p-4 lg:grid">
              <Panel title={t("main.panel.profile")}>
                <MyProfileWidget />
              </Panel>
              <CreateFormButton />
              <Suggestions />
              {sidebarConfig(t, user).left.map(item => (
                <>
                  {(user || !item.login) && (
                    <SideBarUserElement
                      title={item.title}
                      key={item.key}
                      users={sidebar.data && sidebar.data[item.key]}
                      link={item.link}
                      showBirthday={item.showBirthday}
                    />
                  )}
                </>
              ))}
            </div>
          </aside>
        )}

        <main
          css={[
            tw`flex justify-center w-full`,
            props.fullscreen || appContext?.fullscreen || (isMobile && isScrolledDown)
              ? tw`h-screen max-h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] h-full scrollbar-thin overflow-y-scroll`
              : tw`max-h-[calc(calc(100dvh-40px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] h-full scrollbar-thin overflow-y-scroll`,
          ]}
          onScroll={e => {
            scrollMain({ e: e });
          }}
          onWheel={e => {
            scrollMain({ e: e, delta: e.deltaY });
          }}
        >
          <div
            css={[
              tw`grid grid-flow-row auto-rows-max grid-cols-1 gap-3 w-full md:max-w-[600px] xl:w-[600px]`,
              props.borderless ? tw`p-0` : tw`md:py-4 max-md:p-4`,
            ]}
          >
            <Outlet />
          </div>
        </main>

        {!appContext?.sidebarHidden && (
          <aside className="absolute right-1/2 top-0 max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] translate-x-[calc(50%+475px-8px)] overflow-y-scroll scrollbar-none max-xl:hidden xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]">
            <div className="mx-auto hidden w-[300px] grid-flow-row auto-rows-max gap-4 p-4 xl:grid">
              <Trends filter={""} />
              {sidebarConfig(t, user).right.map(item => (
                <>
                  {(user || !item.login) && (
                    <SideBarUserElement
                      title={item.title}
                      key={item.key}
                      users={sidebar.data && sidebar.data[item.key]}
                      link={item.link}
                    />
                  )}
                </>
              ))}

              <Panel title={t("main.bookmarks.title")}>
                <div className="lg:grid-cols-30 grid grid-cols-1 gap-2">
                  <News limit={6} columnLimit={3} />
                  <NavLink
                    to={"bookmarks/feed"}
                    className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
                  >
                    {t("buttons.showMore")}
                  </NavLink>

                  <WatchThumbnailList bookmarked={true} fineGrid={true} limit={6} columnLimit={3} />
                  <NavLink
                    to={"bookmarks/watch"}
                    className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
                  >
                    {t("buttons.showMore")}
                  </NavLink>
                </div>
              </Panel>
              <AppQRCode />
              <AppLink />
              <Footer />
            </div>
          </aside>
        )}
      </div>
      <div
        css={[
          tw`opacity-100 ease-in-out duration-500`,
          (appContext?.fullscreen || appContext?.bottomNavHidden || props.fullscreen) &&
            tw`max-md:hidden`,
          isMobile &&
            isScrolledDown &&
            tw`absolute bottom-0 z-30 w-screen duration-500 opacity-0 ease-in-out pointer-events-none`,
        ]}
      >
        <NavBarBottom dark={props.darkFooter} />
      </div>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
      {userBubbleModal.userBubble.isOpen && userBubbleModal.userBubble.user != undefined && (
        <div
          className="pointer-events-none fixed z-50 mt-[45px] w-[300px] pt-[5px]"
          style={{
            left:
              userBubbleModal.userBubble.position.x + (userBubbleModal.userBubble?.offset?.x ?? 0),
            top:
              userBubbleModal.userBubble.position.y + (userBubbleModal.userBubble?.offset?.y ?? 0),
          }}
          onMouseLeave={() => {
            userBubbleModal.close();
          }}
        >
          <div
            className="pointer-events-auto h-fit rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
            onClick={e => {
              e.stopPropagation();
            }}
            onMouseEnter={() => {
              userBubbleModal.setFocused();
            }}
            onMouseLeave={() => {
              userBubbleModal.close(true);
            }}
          >
            <UserDetailsWidget user={userBubbleModal.userBubble.user} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainWrapper;

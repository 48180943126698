import api from "@src/api/api";
import CompactPost from "@src/pages/news/posts/CompactPost";
import { Post } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Response = {
  data: Array<Post>;
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const News = ({ searchQuery, showPlaceholder }: Props) => {
  const [searchParams] = useSearchParams();

  const posts = useInfiniteQuery<Response>(
    ["posts", searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed?page=" +
          pageParam +
          "&q=" +
          searchQuery +
          "&featured_post_id=" +
          searchParams.get("featured") +
          "&user_id=" +
          "",
      );
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      posts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {posts.data?.pages.map(
          page =>
            page &&
            page.data.map(post => {
              let src = "/placeholder.png";
              if (post.reposted) {
                if (post.reposted.link_preview?.image) src = `${post.reposted.link_preview?.image}`;
                if (post.reposted.media.length) {
                  if (post.reposted.media[0].type === "image")
                    src = `${post.reposted.media[0].data_url}`;
                  if (post.reposted.media[0].type === "video")
                    src = `${post.reposted.media[0].data_url}/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop`;
                }
              } else {
                if (post.link_preview?.image) src = `${post.link_preview?.image}`;
                if (post.media.length) {
                  if (post.media[0].type === "image") src = `${post.media[0].data_url}`;
                  if (post.media[0].type === "video")
                    src = `${post.media[0].data_url}/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop`;
                }
              }

              return (
                <>
                  <CompactPost
                    id={post.id}
                    type={post.watch ? "video" : "text"}
                    viewCount={post.view_count}
                    likeCount={post.like_count}
                    image={src}
                  />
                </>
              );
            }),
        )}
      </div>
    </>
  );
};

export default News;

import api from "@src/api/api";
import Input from "@src/components/elements/input/Input";
import CompactPost from "@src/pages/news/posts/CompactPost";
import { Post } from "@src/utils/post";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type Props = {
  userId?: string;
};

const ProfileDetailsPosts = ({ userId = "" }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchParams] = useSearchParams();

  type Response = {
    data: Array<Post>;
    current_page: number;
    total_pages: number;
  };

  const posts = useInfiniteQuery<Response>(
    ["posts", searchQuery, searchParams.get("featured")],
    async ({ pageParam = 0 }) => {
      const res = await api.get(
        "/api/v1/feed?page=" +
          pageParam +
          "&q=" +
          searchQuery +
          "&featured_post_id=" +
          searchParams.get("featured") +
          "&user_id=" +
          userId,
      );
      return res.data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = useCallback(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (posts.isFetchingNextPage || !posts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      posts.fetchNextPage();
    }
  }, [posts]);

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const { t } = useTranslation();

  return (
    <>
      <Input
        placeholder={t("components.shared.searchFeed")}
        name="feed_keywords"
        clearable
        onChange={e => setSearchQuery(e)}
      />

      <div className="grid grid-flow-row gap-2">
        <div className="grid grid-cols-2 gap-3 lg:grid-cols-5">
          {posts.data?.pages.map(
            page =>
              page &&
              page.data.map(post => {
                let src = "/placeholder.png";
                let mediaType = "";
                const tmpPost = post.is_reposted ? post.reposted : post;
                if (tmpPost) {
                  if (tmpPost.watch) mediaType = "video";
                  if (tmpPost.link_preview?.image) {
                    src = tmpPost.link_preview.image;
                    if (tmpPost.media.length > 0) {
                      mediaType = tmpPost.media[0].type;
                    }
                  } else {
                    if (tmpPost.media.length > 0) {
                      mediaType = tmpPost.media[0].type;
                      src = post.media[0].data_url;
                      if (mediaType != "video") src += "";
                    }
                  }
                  if (mediaType === "video") {
                    src += "/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop";
                  }

                  return (
                    <CompactPost
                      key={post.id}
                      id={post.id}
                      type={mediaType}
                      viewCount={post.view_count}
                      likeCount={post.like_count}
                      image={src}
                    />
                  );
                }
                return null;
              }),
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsPosts;

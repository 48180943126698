import { AppContext } from "@src/App";
import usePopupModal from "@src/state/modal/usePopupModal";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import tw from "twin.macro";

const MobileMainWrapper = () => {
  const appContext = useContext(AppContext);
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const sidebarConfig = {
    left: [
      {
        key: "person_suggestions",
        title: t("main.userSuggestions.title"),
        link: "/kontakte/für_dich/personen",
      },
      {
        key: "company_suggestions",
        title: t("main.companySuggestions.title"),
        link: "/kontakte/für_dich/firmen",
      },
      {
        key: "group_suggestions",
        title: t("main.groupSuggestions.title"),
        link: "/gruppen/für_dich",
      },
      {
        key: "birthdays",
        title: t("main.birthdays.title"),
        link: "/kontakte/meine_kontakte/geburtstage",
        login: true,
      },
      {
        key: "managed_pages",
        title: t("main.managedPages.title"),
        link: "",
        login: true,
      },
    ],
    right: [
      {
        key: "new_users",
        title: t("main.new.title"),
        link: "/kontakte/für_dich/alle",
      },
      {
        key: "visitors",
        title: t("main.visitors.title"),
        link: "/kontakte/meine_kontakte/statistik/besucher",
        login: true,
      },
      {
        key: "my_followers",
        title: t("main.myFollowers.title"),
        link: "/kontakte/follower",
        login: true,
      },
      {
        key: "my_friends",
        title: t("main.myFriends.title"),
        link: "/kontakte/meine_kontakte/übersicht",
        login: true,
      },
      {
        key: "last_visited",
        title: t("main.lastSeen.title"),
        link: "",
        login: true,
      },
      {
        key: "remember",
        title: t("main.navLinks.watchLists"),
        link: "/bookmarks",
        login: true,
      },
    ],
  };

  return (
    <div
      css={[
        tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen || appContext?.topNavHidden) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <main className="overflow-x-hidden overflow-y-scroll scrollbar-none">
        <Outlet />
      </main>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
    </div>
  );
};

export default MobileMainWrapper;

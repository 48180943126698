import exchangeTemporaryAuthToken from "@src/api/auth/temporary_auth_token";
import Panel from "@src/components/box/Panel";
import { setToken, setUser } from "@src/state/user/userSlice";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const SocialAuthSuccessContainer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    const oneTimeToken = searchParams.get("token");
    const isNewUser = searchParams.get("isNewUser") === "true";

    const handleAuthSuccess = async () => {
      if (!oneTimeToken) return;

      try {
        const { jwt: token, user } = await exchangeTemporaryAuthToken(oneTimeToken);
        dispatch(setToken(token));
        dispatch(setUser(user));
        queryClient.invalidateQueries({ queryKey: ["me"] });

        const targetPath = isNewUser ? "/verwaltung/profile" : `/${user.start_page || "news"}`;
        navigate(targetPath);
      } catch (error) {
        console.error("Error while exchanging token:", error);
        navigate("/login");
      }
    };

    handleAuthSuccess();
  }, [dispatch, navigate, queryClient, searchParams]);

  return (
    <Panel mobileBorder={false}>
      <div className="text-1xl">{t("main.signIn.authenticationProcessing")}</div>
    </Panel>
  );
};

export default SocialAuthSuccessContainer;

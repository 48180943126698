import api from "@src/api/api";
import Input from "@src/components/elements/input/Input";
import JobsListItem from "@src/pages/jobs/JobsListItem";
import { JobType } from "@src/utils/types/JobType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Response = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

const ProfileDetailsJobs = ({ userId = "" }: { userId?: string }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslation();
  const jobs = useInfiniteQuery<Response>(
    ["jobs", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/jobs?page=${pageParam}&q=${searchQuery}&user_id=${userId}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (jobs.isFetchingNextPage || !jobs.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      jobs.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <Input
        placeholder={t("main.jobsTabs.placeholder")}
        name="jobs_keywords"
        onChange={e => setSearchQuery(e)}
        clearable
      />
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-5">
        {jobs.data &&
          jobs.data.pages.map(
            page =>
              page.data &&
              page.data.map((job: JobType) => (
                <JobsListItem key={job.id} job={job} showButtons={false} />
              )),
          )}
      </div>
    </>
  );
};

export default ProfileDetailsJobs;

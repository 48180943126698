import {
  ArrowUturnLeftIcon,
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import api from "@src/api/api";
import ButtonsBar from "@src/components/elements/form/ButtonsBar";
import Button from "@src/components/elements/input/Button";
import StarsRating from "@src/components/elements/input/StarsRating";
import Products from "@src/mocks/AllProducts.json";
import ProductComponent from "@src/pages/marketplace/components/Product";
import ProductRatingHistogram from "@src/pages/marketplace/components/ProductRatingHistogram";
import ProductReviews from "@src/pages/marketplace/components/ProductReviews";
import ProductsCarousel from "@src/pages/marketplace/components/ProductsCarousel";
import SimilarProduct from "@src/pages/marketplace/components/SimilarProduct";
import ImageGrid from "@src/pages/marketplace/shop/item/ImageGrid";
import { MediaData } from "@src/utils/mediaData";
import { Product } from "@src/utils/product";
import { Media } from "@src/utils/types/Media";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import tw from "twin.macro";

const MarketplaceDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [textMinimized, setTextMinimized] = useState(true);
  const navigate = useNavigate();
  const products = Products.allProducts;

  const product = useQuery<Product, Error>([`product-detail-${id}`], async () => {
    const res = await api.get(`/api/v1/marketplace/products/${id}`);
    return res.data;
  });

  const media: Array<Media> = MediaData;

  if (!product.isSuccess) return <span>{t("main.shop.details.loading")}</span>;

  return (
    <div className="xl:px-[180px]">
      {/* Title */}
      <div className="flex flex-row items-start justify-between max-xl:px-4">
        <div className="text-xl">{product.data.title}</div>
        <ArrowUturnLeftIcon
          className="size-6 cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>

      {/* Stats */}
      <div className="mb-4 flex w-full flex-row justify-between border-b pb-2 max-xl:px-4">
        <div className="flex flex-col gap-1">
          <div className="inline-flex items-center gap-2 whitespace-nowrap">
            <StarsRating rating={5} />
            <div className="text-xs text-gray-600">
              {t("main.shop.details.starsRating", { count: 100 })}
            </div>
          </div>
          <div className="inline-flex w-full gap-2 text-xs">
            <div className="inline-flex items-center gap-0.5">
              <EyeIcon className="size-3 text-darkblue" />
              <div className="text-gray-500">{"355".padStart(6, "0")}</div>
            </div>
            <div className="inline-flex items-center gap-0.5">
              <BookmarkIcon className="size-3 text-darkblue" />
              <div className="text-gray-500">{"81".padStart(6, "0")}</div>
            </div>
            <div className="inline-flex items-center gap-0.5">
              <HeartIcon className="size-3 text-darkred" />
              <div className="text-gray-500">{"107".toString().padStart(6, "0")}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 divide-y divide-black text-darkblue">
        <div className="relative flex h-fit flex-col gap-12 xl:flex-row">
          {/* Left side */}
          <div className="left-0 top-0 flex h-fit shrink-0 flex-col gap-2 xl:sticky xl:w-2/5">
            {/* Ad image */}
            <ImageGrid
              images={media}
              likeButton={() => {
                return;
              }}
              shareButton={() => {
                return;
              }}
            />
            <div className="max-xl:px-4">
              <ButtonsBar
                spread
                printButton={{
                  printUrl: "",
                }}
                likeButton={{
                  likeUrl: "/api/v1/marketplace/products/" + id + "/like",
                  unlikeUrl: "/api/v1/marketplace/products/" + id + "/unlike",
                  liked: false,
                  like_count: 0,
                }}
                viewButton={{ view_count: 0 }}
                chatButton={{
                  chatUrl: "/api/v1/chats/create/" + "",
                }}
                shareButton={{
                  title: "Profil",
                  body: "https://www.tradefoox.com/marketplace/" + id,
                }}
                itemId={id ?? ""}
                itemType="job"
              />
            </div>
          </div>
          {/* Right side */}
          <div className="flex w-full flex-col justify-between gap-2 text-sm max-xl:px-4 xl:flex-row">
            <div className="flex flex-col">
              <div className="font-semibold">{t("main.navLinks.jobsPublished.description")}:</div>
              <div css={[tw`text-sm`, textMinimized && tw`line-clamp-5`]}>
                {product.data.description}
              </div>
              <button
                className="mt-1 flex w-full flex-row items-center justify-center gap-2 rounded-full border border-gray-500 text-sm"
                onClick={() => setTextMinimized(!textMinimized)}
              >
                {textMinimized ? t("buttons.keepReading") : t("buttons.less")}
                {textMinimized ? (
                  <ChevronDownIcon className="size-4" />
                ) : (
                  <ChevronUpIcon className="size-4" />
                )}
              </button>
            </div>
            <div className="flex flex-col gap-8 text-gray-800 xl:w-[250px]">
              {/* Ad basic info */}
              <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                <div className="flex flex-col">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 text-lg font-bold">
                    {(parseFloat(`${product.data.price_gross}`) / 100).toFixed(2)} €
                  </div>
                  <div className="text-sm text-gray-800">
                    <b className="float-left mr-1 whitespace-nowrap">
                      {t("main.shop.price.netPrice")}
                    </b>
                    {(parseFloat(`${product.data.price_net}`) / 100).toFixed(2)} €
                  </div>
                  <div className="text-sm text-gray-800">
                    <b className="float-left mr-1 whitespace-nowrap">
                      {t("main.shop.price.grossPrice")}
                    </b>
                    {(parseFloat(`${product.data.price_gross}`) / 100).toFixed(2)} €
                  </div>
                  <div className="text-sm text-gray-800">
                    <b className="float-left mr-1 whitespace-nowrap">
                      {t("pages.marketplace.shipment")}
                    </b>{" "}
                    {t("pages.marketplace.free")}
                  </div>
                  <div className="text-sm text-gray-800">
                    <b className="float-left mr-1 whitespace-nowrap">
                      {t("pages.marketplace.amount")}
                    </b>{" "}
                    {10 > 0 && (
                      <>
                        <select className="rounded-xl py-0 text-left text-xs">
                          {Array.from(Array(10 + 1).keys())
                            .slice(1)
                            .map(index => (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                    {10 <= 0 && (
                      <div className="font-semibold">{t("main.shop.details.notAvailable")}</div>
                    )}
                  </div>

                  {false && (
                    <div className="text-sm text-gray-800">
                      <b className="float-left mr-1 whitespace-nowrap">Ab 18</b>
                    </div>
                  )}
                  <div className="mt-2 inline-flex gap-2 text-sm text-gray-800">
                    <Button variant={Button.Variants.Transparent} fitWidth>
                      {t("main.shop.buttons.addToCart")}
                    </Button>
                    <Button fitWidth>{t("main.shop.buttons.buy")}</Button>
                  </div>
                </div>
              </div>

              <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                <div className="flex flex-col">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 font-bold">
                    {t("main.shop.details.sellerAndShipping")}
                  </div>
                  <div className="text-sm text-gray-800">
                    <NavLink to="/profile/">
                      {t("main.profileViewTabs.editProfile.form.userName")}
                    </NavLink>
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex h-fit flex-col rounded-2xl border border-gray-200 bg-gray-100 px-2 py-1 text-sm">
                <div className="flex flex-col">
                  <div className="mb-2 whitespace-nowrap border-b border-gray-300 pb-2 font-bold">
                    {t("main.shop.details.actions")}
                  </div>
                  <div className="mx-auto flex w-fit flex-col items-center gap-2">
                    <Button variant={Button.Variants.Transparent}>
                      {t("main.shop.buttons.sellAtTradefoox")}
                    </Button>
                    <Button variant={Button.Variants.Transparent}>
                      {t("main.shop.buttons.recommendArticle")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 pt-2 max-xl:px-4">
          <div className="inline-flex gap-1 text-sm font-bold">
            {t("main.shop.details.seller")}:
            <NavLink to="/profile/">{t("main.profileViewTabs.editProfile.form.userName")}</NavLink>
          </div>
          <div className="text-xs text-gray-800">
            <div className="float-left mr-1 whitespace-nowrap">
              {t("main.shop.details.addedSince")}:
            </div>
            00.00.0000
          </div>
          <div className="break-all text-sm">{product.data.id}</div>
        </div>
        <div className="flex flex-col gap-2 pt-2 max-xl:px-4">
          <div className="flex flex-col gap-8 xl:flex-row">
            <div className="inline-flex gap-8 text-sm">
              <ProductRatingHistogram product_title={product.data.title} />
            </div>
            <div className="w-full max-xl:px-4">
              <ProductReviews />
              <div className="text-right text-sm font-semibold text-lightblue hover:underline">
                <NavLink to={"/marketplace/product_reviews/" + id}>
                  {t("main.navLinks.jobsPublished.seeMoreReviews")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-2 max-xl:px-4">
          <div className="inline-flex gap-1">
            {t("main.shop.details.moreProductsFrom")}
            <div className="text-darkred">
              <NavLink to="/profile/">
                <a className="whitespace-nowrap">
                  {t("main.profileViewTabs.editProfile.form.userName")}
                </a>
              </NavLink>
            </div>
          </div>
          <div className="mt-2 flex flex-row justify-between gap-4">
            <ProductsCarousel spaceBetween={20} slidesPerView={1}>
              {products.map(product => (
                <>
                  <SwiperSlide>
                    <SimilarProduct product={product} />
                  </SwiperSlide>
                </>
              ))}
            </ProductsCarousel>
          </div>
        </div>
        <div className="pt-2 max-xl:px-4">
          {t("main.shop.details.similarProducts")}
          <div className="mt-2 flex flex-row justify-between gap-4">
            <ProductsCarousel spaceBetween={20} slidesPerView={1}>
              {products.map(product => (
                <>
                  <SwiperSlide>
                    <SimilarProduct product={product} />
                  </SwiperSlide>
                </>
              ))}
            </ProductsCarousel>
          </div>
        </div>
      </div>
      <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-4">
        {products.map(product => (
          <div key={product.ID} className="flex justify-center">
            <ProductComponent product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketplaceDetails;

import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { TwStyle } from "twin.macro";

type Props = {
  postId: string;
  bookmarked: boolean;
  bookmarkCount: number;
  color?: TwStyle;
  textColor?: TwStyle;
};

const PostBookmarkButton = ({ postId, bookmarked, bookmarkCount, color, textColor }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${postId}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  return (
    <PostsActionBarItem
      onClick={() => {
        if (user) {
          if (bookmarked) {
            remove.mutate();
          } else {
            add.mutate();
          }
        } else {
          loginModal.open();
        }
      }}
      label={t("pages.post.savePost")}
      color={color ?? (bookmarked ? tw`bg-slate-300` : tw`group-hover:bg-slate-300`)}
      textColor={
        textColor ??
        tw`text-gray-600 transition-all ease-out duration-200 group-hover:text-slate-700`
      }
      count={bookmarkCount}
    >
      {bookmarked ? (
        <BookmarkIconSolid className={`${actionEffect && "animate-ping-once"}`} />
      ) : (
        <BookmarkIcon
          onClick={() => {
            setActionEffect(true);
          }}
          onAnimationEnd={() => {
            setActionEffect(false);
          }}
        />
      )}
    </PostsActionBarItem>
  );
};

export default PostBookmarkButton;

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  fontStyle?: TwStyle;
};

const Footer = ({ fontStyle }: Props) => {
  const { t } = useTranslation();
  const footerMenu = [
    {
      category: "User",
      links: [
        {
          href: "info",
          hovertext: t("components.hovertext.editProfileData"),
          title: "Info",
        },
        {
          href: "help",
          hovertext: t("components.hovertext.helparea"),
          title: "Hilfebereich",
        },
        {
          href: "marketing",
          hovertext: t("components.hovertext.marketing"),
          title: "Marketing",
        },
      ],
    },
    {
      category: "Website",
      links: [
        {
          href: "impressum",
          hovertext: t("main.signIn.imprint"),
          title: "Impressum",
        },
        {
          href: "datenschutz",
          hovertext: t("main.signIn.dataProtection"),
          title: "Datenschutz",
        },
        {
          href: "agb",
          hovertext: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
          title: "AGB",
        },
      ],
    },
    {
      category: "Marketing",
      links: [
        {
          href: "business",
          hovertext: t("components.hovertext.business"),
          title: "Business-Service",
        },
        {
          href: "glossar",
          hovertext: t("components.hovertext.glossary"),
          title: "Glossar",
        },
      ],
    },
    {
      category: "App",
      links: [
        {
          href: "mehr",
          hovertext: t("components.hovertext.more"),
          title: "Mehr",
        },
      ],
    },
  ];

  return (
    <div className="text-sm text-gray-900">
      <div className="mb-2 flex flex-wrap justify-center gap-y-2 overflow-hidden">
        {footerMenu.map((footerLinks, index) => {
          return (
            <div key={index}>
              {footerLinks.links.map((links, index) => {
                return (
                  <NavLink to={links.href} key={index}>
                    <a
                      title={links.hovertext}
                      css={[tw`font-medium hover:bg-gray-200 px-1`, fontStyle]}
                    >
                      {links.title}
                    </a>
                  </NavLink>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="relative mx-auto flex h-[30px] w-[150px] flex-row">
        <img src="/logo.gif" alt="TRADEFOOX" />
        <div className="absolute -right-4 text-mini text-darkblue">© 2022</div>
      </div>
    </div>
  );
};

export default Footer;

import { CheckIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import UserWidgetPicture from "@src/components/elements/UserWidgetPicture";
import Button from "@src/components/elements/input/Button";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {
  user: User;
  type: "sent" | "received";
  key?: string;
};

const ContactRequestsItem = ({ user, type }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const accept = useMutation([`contacts-request-accept-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/connect`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + type],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const reject = useMutation([`contacts-request-reject-${user.id}`], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/contacts/requests/${user.id}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests-" + type],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div>
      <div className="flex w-full flex-col gap-2 rounded-lg border border-gray-300 pb-2 text-sm">
        <div className="relative">
          <UserWidgetPicture user={user} showName={true} follow={false} verified={true} />
          <div className="absolute right-0 top-0 pt-2">
            <ContactContextMenu userId={user.id} type={user.type} />
          </div>
        </div>

        <NavLink to={"/profile/" + user.id} className="flex w-full flex-col gap-1">
          <div
            className="flex flex-row justify-between px-2 text-xs text-gray-600"
            style={{ marginTop: "-25px" }}
          >
            <NavLink to={"/profile/" + user.id + "/likes"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.likes.title")}</div>
                <div>{user.like_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/follows"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.followers.title")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/following"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.following.title")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
          </div>
        </NavLink>
        <div className="px-2">
          {type === "sent" ? (
            <Button
              onClick={() => accept.mutate()}
              size={Button.Sizes.Small}
              variant={Button.Variants.Secondary}
            >
              <div className="inline-flex items-center gap-1">
                <CheckIcon className="size-3 border-2 border-green-700 stroke-[4px] text-green-400" />
                {t("main.header.links.groups.tabs.pending")}
              </div>
            </Button>
          ) : (
            <div className="flex flex-row justify-evenly">
              <button onClick={() => reject.mutate()}>
                <XCircleIcon className="size-7 text-red-700" />
              </button>
              <button onClick={() => accept.mutate()}>
                <CheckCircleIcon className="size-7 text-green-700" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactRequestsItem;

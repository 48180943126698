import api from "@src/api/api";
import ContactIFollowItem from "@src/pages/contacts/i_follow/ContactIFollowItem";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
};

const ContactIFollowContainer = ({ searchQuery }: Props) => {
  const contacts = useInfiniteQuery<Response>(
    ["contacts-i_follow", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/i_follow?page=${pageParam}&q=${searchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (contacts.isFetchingNextPage || !contacts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      contacts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {contacts.data &&
          contacts.data.pages.map(page =>
            page.data.map((item: User) => <ContactIFollowItem key={item.id} user={item} />),
          )}
      </div>
    </>
  );
};

export default ContactIFollowContainer;

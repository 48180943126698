import { NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import HashtagList from "@src/components/elements/hashtags/HashtagList";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  tab?: string;
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Hashtags = ({ tab = "feed", searchQuery, showPlaceholder }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <TabController defaultTab={tab} isSubTab>
        <TabHeaders headerCSS={tw`h-[35px]`} center>
          <TabHeader url="/entdecken/hashtags/feed" id="feed">
            <NewspaperIcon className="size-4" /> {t("main.navLinks.news")}
          </TabHeader>
          <TabHeader url="/entdecken/hashtags/watch" id="watch">
            <VideoCameraIcon className="size-4" /> {t("main.navLinks.watch")}
          </TabHeader>
        </TabHeaders>
        <TabBodies>
          <TabBody id="feed">
            <HashtagList filter={tab} />
          </TabBody>
          <TabBody id="watch">
            <HashtagList filter={tab} />
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default Hashtags;

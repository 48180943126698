import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import api from "@src/api/api";
import JobCard from "@src/pages/jobs//components/JobCard";
import JobCarousel from "@src/pages/jobs//components/JobCarousel";
import JobReviews from "@src/pages/jobs/components/JobReviews";
import JobStarReviewBox from "@src/pages/jobs/components/JobStarReviewBox";
import { JobType } from "@src/utils/types/JobType";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import tw from "twin.macro";

type Props = {
  preloadedJob?: JobType;
};

type Response = {
  data: JobType[];
};
const JobReviewsContainer = ({ preloadedJob }: Props) => {
  const navigate = useNavigate();
  const isPreview = preloadedJob !== undefined;
  const { id } = useParams();
  const jobs = useQuery<Response, Error>(["jobs"], async () => {
    const { data } = await api.get("/api/v1/jobs");
    return data;
  });
  const jobQuery = useQuery<JobType, Error>(
    [`job-${id}`],
    async () => {
      if (id === undefined) return;
      const res = await api.get(`/api/v1/jobs/${id}`);
      return res.data.data;
    },
    { enabled: !isPreview, initialData: preloadedJob },
  );

  if (!jobs.isSuccess) return <></>;

  // Determine which job object to use based on whether it's a preview or fetched via query
  const job = isPreview ? preloadedJob : jobQuery.data;

  if (!job) return <div>Loading...</div>; // Handle loading state if job data is not available yet

  return (
    <div css={!isPreview && tw`xl:px-[180px]`}>
      <div className="flex w-full flex-col max-xl:px-4 md:flex-row">
        <div className="my-6">
          <JobStarReviewBox />
        </div>
        <div className="ml-5 flex flex-col">
          <div className="mb-4">
            <div className="my-6 flex flex-row items-center">
              <NavLink to={"/profile/" + job.user.id + "/jobs"}>
                <div className="mr-2 size-12">
                  <img className="size-full rounded-lg object-cover" src={job.user.avatar} alt="" />
                </div>
              </NavLink>
              <NavLink to={`/jobs/${job.id}`}>
                <div className="text-sm font-semibold">{job.title}</div>
              </NavLink>
              {!isPreview && (
                <ArrowUturnLeftIcon
                  className="ml-auto size-6 cursor-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              )}
            </div>
            <JobReviews />
          </div>
        </div>
      </div>
      <div className="pt-2 max-xl:px-4">
        {t("main.navLinks.jobsPublished.similarJobs")}
        <div className="w-full">
          <JobCarousel spaceBetween={20} slidesPerView={5} mousewheel={true}>
            {jobs.data &&
              jobs.data.data.map(job => (
                <div key={job.id}>
                  <SwiperSlide className="w-full p-2">
                    <JobCard job={job} />
                  </SwiperSlide>
                </div>
              ))}
          </JobCarousel>
        </div>
      </div>
    </div>
  );
};

export default JobReviewsContainer;

import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  FlagIcon,
  MinusCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import api from "@src/api/api";
import EditPost from "@src/components/elements/posts/form/EditPost";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { useAppSelector } from "@src/state/hooks";
import { showReportModal } from "@src/state/reportModal/actions";
import { Post } from "@src/utils/post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

type Props = {
  post: Post;
  onDownloadStart?: () => void;
  onDownloadEnd?: () => void;
  onDownloadProgress?: (progress: number) => void;
};

const PostsDropdown = ({ post, ...props }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deletePost = useMutation(["delete-post"], {
    mutationFn: async (postId: string) => {
      const res = await api.delete(`/api/v1/posts/${postId}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const add = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/posts/${post.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const remove = useMutation(["bookmark"], {
    mutationFn: async () => {
      const res = await api.delete(`/api/v1/posts/${post.id}/bookmark`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const downloadAllFiles = async () => {
    props.onDownloadStart && props.onDownloadStart();
    props.onDownloadProgress && props.onDownloadProgress(0);

    // Determine the media source
    const mediaSource = post.reposted ? post.reposted.media : post.media;
    const zip = new JSZip();

    if (mediaSource.length === 0) {
      props.onDownloadEnd && props.onDownloadEnd();
      return;
    }

    if (mediaSource.length === 1) {
      const media = mediaSource[0];
      let url = media.data_url;
      if (media.type === "video") {
        url += "/play_480p.mp4";
      }
      await axios({
        method: "get",
        url: url,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          // Calculate the percentage of the current file
          const percentage = Math.floor((progressEvent.loaded * 100) / (progressEvent.total || 0));

          // Report progress
          props.onDownloadProgress && props.onDownloadProgress(Math.round(percentage));
        },
      }).then(response => {
        // Error checking, if server doesn't give a successful response
        if (response.status !== 200) {
          throw new Error(`Error while fetching ${media.id}: ${response.statusText}`);
        }
        saveAs(response.data, media.file_name);
        props.onDownloadEnd && props.onDownloadEnd();
      });
      return;
    }

    // Fetch all files one by one and add to ZIP
    for (const media of mediaSource) {
      let totalPercentage = 0;
      let url = media.data_url;
      if (media.type === "video") {
        url += "/play_480p.mp4";
      }
      await axios({
        method: "get",
        url: url,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          // Calculate the percentage of the current file
          const percentage = Math.floor((progressEvent.loaded * 100) / (progressEvent.total || 0));

          // Calculate the total progress
          totalPercentage =
            (mediaSource.indexOf(media) / mediaSource.length +
              percentage / (100 * mediaSource.length)) *
            100;

          // Report progress
          props.onDownloadProgress && props.onDownloadProgress(Math.round(totalPercentage));
        },
      }).then(response => {
        // Error checking, if server doesn't give a successful response
        if (response.status !== 200) {
          throw new Error(`Error while fetching ${media.id}: ${response.statusText}`);
        }
        const blob = new Blob([response.data], { type: response.data.type });
        zip.file(media.file_name, blob, { binary: true });
      });
    }

    // Generate ZIP file and trigger download
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, `${post.id}.zip`);
    });
    props.onDownloadEnd && props.onDownloadEnd();
  };

  const block = useMutation([`block-${post.author.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${post.author.id}/block`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblock = useMutation([`unblock-${post.author.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${post.author.id}/unblock`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <>
      {openEdit && (
        <EditPost
          onSuccess={() => setOpenEdit(false)}
          post={post}
          onClose={() => setOpenEdit(false)}
        />
      )}
      <ContextMenu
        items={[
          (post.media[0] ||
            (post.reposted != null && post.reposted.media != null && post.reposted.media[0])) && {
            label: t("pages.post.download"),
            onClick: () => downloadAllFiles(),
            icon: ArrowDownTrayIcon,
          },
          user &&
            user.id === post.author.id &&
            !post.reposted && {
              label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
              onClick: () => setOpenEdit(true),
              icon: PencilIcon,
            },
          user &&
            (user.id === post.author.id || user.admin) && {
              label: t("components.shared.remove"),
              onClick: () => deletePost.mutate(post.id),
              icon: TrashIcon,
            },
          {
            label: t("buttons.report"),
            onClick: () => dispatch(showReportModal(post.id, "post")),
            icon: FlagIcon,
          },
          user &&
            user.id !== post.author.id && {
              label: t("buttons.block"),
              onClick: () => block.mutate(),
              icon: MinusCircleIcon,
            },
          {
            label: t("buttons.save"),
            onClick: () => (post.bookmarked ? remove.mutate() : add.mutate()),
            icon: post.bookmarked ? BookmarkIconSolid : BookmarkIcon,
          },
        ]}
      />
    </>
  );
};

export default PostsDropdown;

import api from "@src/api/api";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { hideReportModal } from "@src/state/reportModal/actions";
import { RootState } from "@src/state/store";
import wait from "@src/utils/wait";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

const ReportModal = () => {
  const dispatch = useDispatch();
  const reportPopupState = useSelector((state: RootState) => state.reportModal);
  const [text, setText] = React.useState("");

  const report = useMutation(["report"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/report", {
        item_id: reportPopupState.itemId,
        title: reportPopupState.title,
        type: reportPopupState.type,
        description: text,
      });
      await wait(2000);
      return res.data;
    },
    onSuccess: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: () => {
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
  });
  const handleClose = () => {
    setText("");
    dispatch(hideReportModal());
    setTimeout(report.reset, 500);
  };

  return (
    <ModalWrapper
      open={reportPopupState.isVisible}
      onCancel={handleClose}
      onConfirm={report.mutate}
      title={reportPopupState.title ?? "Beitrag Melden"}
      loading={report.isLoading}
      success={report.isSuccess}
      failed={report.isError}
    >
      <TextareaAutosize
        value={text}
        minRows={3}
        onChange={e => setText(e.target.value)}
        css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
        placeholder=""
        maxRows={10}
      />
    </ModalWrapper>
  );
};

export default ReportModal;

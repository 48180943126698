import api from "@src/api/api";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import ContactForYouItem from "@src/pages/contacts/for_you/ContactForYouItem";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  tab?: string;
  searchQuery?: string;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};

const Profiles = ({ tab = "", searchQuery }: Props) => {
  const { t } = useTranslation();
  const contacts = useInfiniteQuery<Response>(
    ["contacts-for_you", searchQuery, tab],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/contacts/for_you?page=${pageParam}&q=${searchQuery}&type=${tab}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (contacts.isFetchingNextPage || !contacts.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      contacts.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const tabContent = (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
      {contacts.data &&
        contacts.data.pages.map(page =>
          page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
        )}
    </div>
  );

  return (
    <>
      <TabController defaultTab={tab} isSubTab>
        <TabHeaders headerCSS={tw`h-[35px]`} center>
          <TabHeader url="/entdecken/profile/alle" id="">
            {t("components.shared.all")}
          </TabHeader>
          <TabHeader url="/entdecken/profile/personen" id="person">
            {t("main.contacts.tabs.person")}
          </TabHeader>
          <TabHeader url="/entdecken/profile/firmen" id="company">
            {t("main.contacts.tabs.company")}
          </TabHeader>
          <TabHeader url="/entdecken/profile/vereine" id="club">
            {t("main.contacts.tabs.club")}
          </TabHeader>
          <TabHeader url="/entdecken/profile/gruppen" id="group">
            {t("main.header.links.groups.title")}
          </TabHeader>
        </TabHeaders>
        <TabBodies>
          <TabBody id="">{tabContent}</TabBody>
          <TabBody id="person">{tabContent}</TabBody>
          <TabBody id="company">{tabContent}</TabBody>
          <TabBody id="club">{tabContent}</TabBody>
          <TabBody id="group">{tabContent}</TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default Profiles;

import api from "@src/api/api";
import ButtonPanel from "@src/components/box/ButtonPanel";
import Input from "@src/components/elements/input/Input";
import ContactForYouItem from "@src/pages/contacts/for_you/ContactForYouItem";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  type: string;
  userId: string;
  onClose: () => void;
};

type Response = {
  data: User[];
  current_page: number;
  total_pages: number;
};
const UserRelationList = (props: Props) => {
  const { userId } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { t } = useTranslation();
  const list = useInfiniteQuery<Response>(
    [props.type, userId, debouncedSearchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/users/${userId}/${props.type}?page=${pageParam}&q=${debouncedSearchQuery}`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    },
  );

  const headlines: { [index: string]: string } = {
    following: t("main.following.title"),
    follows: t("main.marketplace.tabs.follower"),
  };

  const handleScroll = () => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (list.isFetchingNextPage || !list.hasNextPage) return;

    if (
      Math.abs(scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight) <
      1000
    ) {
      list.fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollElement = document.getElementsByTagName("main")[0];

    if (scrollElement === undefined) return;

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <ButtonPanel onClose={props.onClose} title={headlines[props.type]}>
      <Input
        name="search-contacts"
        placeholder={t("main.feedTabs.placeholder")}
        onChange={e => setSearchQuery(e)}
        clearable
      />
      <div className="mt-2 max-h-[80vh]">
        <div className="h-7" />
        <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
          {list.data &&
            list.data.pages.map(page =>
              page.data.map((item: User) => <ContactForYouItem key={item.id} user={item} />),
            )}
        </div>
      </div>
    </ButtonPanel>
  );
};

export default UserRelationList;

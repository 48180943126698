import { styled } from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

interface ProductsCarouselProps {
  children: React.ReactNode;
  spaceBetween?: number;
  slidesPerView?: number;
  loop?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  mousewheel?: boolean;
}

const ArrowButton = styled.div`
  &.swiper-button-next::after,
  &.swiper-button-prev::after {
    color: gray;
    font-size: 15px;
  }
  @media (max-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 15px;
    }
  }
  @media (min-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 20px;
    }
  }
`;

const ProductsCarousel: React.FC<ProductsCarouselProps> = ({
  children,
  spaceBetween = 20,
  slidesPerView = 1,
  loop = true,
  navigation = true,
  pagination = false,
  autoplay = false,
  mousewheel = true,
}: ProductsCarouselProps) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay, Mousewheel]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      loop={loop}
      navigation={
        navigation && {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }
      }
      pagination={pagination && { clickable: true }}
      autoplay={autoplay && { delay: 3000 }}
      mousewheel={mousewheel && { releaseOnEdges: true }}
      breakpoints={{
        768: {
          slidesPerView: 5,
        },
      }}
      onInit={swiper => {
        if (navigation) {
          swiper.navigation.nextEl.classList.add("swiper-button-next");
          swiper.navigation.prevEl.classList.add("swiper-button-prev");
        }
      }}
    >
      {children}
      {navigation && (
        <div className="hidden md:block">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <ArrowButton className="swiper-button-next"></ArrowButton>
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <ArrowButton className="swiper-button-prev"></ArrowButton>
        </div>
      )}
    </Swiper>
  );
};

export default ProductsCarousel;

import api from "@src/api/api";
import Panel from "@src/components/box/Panel";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { JobType } from "@src/utils/types/JobType";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = { key?: string };

type Response = {
  data: JobType[];
};

const JobsSuggestions = ({ key }: Props) => {
  const { t } = useTranslation();
  const jobs = useQuery<Response, Error>(["jobs"], async () => {
    const { data } = await api.get("/api/v1/jobs");
    return data;
  });

  if (!jobs.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularJobs")} mobileBorder={false} key={key}>
        <div className="no-scrollbar flex gap-4 overflow-x-auto">
          {jobs.data.data.map((item: JobType) => {
            return (
              <SuggestionsCenterItem
                key={item.id}
                name={item.title}
                link={"/jobs/" + item.id}
                picture={item.user.avatar || `/images/placeholder/${item.user.type}.png`}
                imageStyle={tw`rounded-xl w-[100px] h-[150px] object-cover`}
                nameStyle={tw`w-[100px]`}
              />
            );
          })}
        </div>
      </Panel>
    </div>
  );
};
export default JobsSuggestions;

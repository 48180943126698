import api from "@src/api/api";
import Button from "@src/components/elements/input/Button";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import UserWidgetPicture from "@src/components/elements/UserWidgetPicture";
type Props = { user: User; mode?: string; key?: string };

const ContactIFollowItem = ({ user }: Props) => {
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const { user: userData } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const follow = useMutation([`follow-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/follow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-i_follow"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unfollow = useMutation([`unfollow-${user.id}`], {
    mutationFn: async () => {
      const res = await api.get(`/api/v1/users/${user.id}/unfollow`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts-i_follow"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <div>
      <div>
        <div className="flex w-full flex-col gap-2 rounded-lg border border-gray-300 pb-2 text-sm">
          <div className="relative">
            <NavLink to={"/profile/" + user.id} className="flex w-full flex-col gap-1">
              <UserWidgetPicture user={user} showName={true} follow={false} verified={true} />
            </NavLink>
            <div className="absolute right-0 top-0 pt-2">
              <ContactContextMenu userId={user.id} type={user.type} />
            </div>
          </div>

          <div
            className="flex flex-row justify-between px-2 text-xs text-gray-600"
            style={{ marginTop: "-25px" }}
          >
            <NavLink to={"/profile/" + user.id + "/follows"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.followers.title")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/following"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.following.title")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/likes"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.likes.title")}</div>
                <div>{user.like_count}</div>
              </div>
            </NavLink>
          </div>
          {user.followed ? (
            <div className="px-2">
              <Button
                onClick={() => (userData ? unfollow.mutate() : loginModal.open())}
                size={Button.Sizes.Small}
                variant={Button.Variants.Transparent}
              >
                <img src="/follow.svg" className="mx-auto h-5 w-4" />
              </Button>
            </div>
          ) : (
            <div className=" px-2">
              <Button
                onClick={() => (userData ? follow.mutate() : loginModal.open())}
                size={Button.Sizes.Small}
                variant={Button.Variants.Transparent}
              >
                {t("main.contacts.tabs.buttonsConsequences")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactIFollowItem;
